<template>
  <div ref="data-chart-line" class="data-chart-line" />
</template>

<script>
import mixinsChart from '../../mixins/charts.js'
export default {
  name: 'DataChartLine',
  mixins: [mixinsChart],
  data() {
    return {
      formChartEmpty: {}
    }
  },
  computed: {
    chartDOM() {
      return this.$refs['data-chart-line']
    },
    chartOptions() {
      const { colDesc, xGroupByDesc, groupByDesc, data, modelName } = this.chartData
      const xAxisData = Array.from(new Set(data?.map(item => item[xGroupByDesc?.colDesc]))) || []
      const [yGroupByDesc = {}] = groupByDesc?.filter(item => !item.xaxis)

      const config = {
        title: {
          text: modelName
        },
        legend: {
          data: []
        },
        xAxis: {
          name: xGroupByDesc?.colDesc ? (`${xGroupByDesc?.colDesc.substr(0, 4).split('').join('\n')}${xGroupByDesc?.colDesc.length > 4 ? '\n···' : ''}`) : xGroupByDesc?.colDesc,
          data: xAxisData
        }
      }

      // 堆叠图
      if (groupByDesc?.length === 2) {
        let formatData = data
        if (yGroupByDesc?.colValues) {
          formatData = data.map(item => {
            item[yGroupByDesc.colDesc] = yGroupByDesc.colValuesDesc[item[yGroupByDesc.colDesc]]
            return item
          })
        }
        config.series = yGroupByDesc?.groupByValues?.map(yItem => {
          const name = yGroupByDesc.colValues ? yGroupByDesc?.colValuesDesc[yItem] : yItem
          const obj = {
            data: xAxisData.map(xAxisDataItem => {
              let dataItem = ''
              formatData.forEach(item => {
                colDesc.forEach(colDescItem => {
                  if (xAxisDataItem === item[xGroupByDesc?.colDesc]) {
                    if (item[yGroupByDesc.colDesc] == name) {
                      dataItem = item[colDescItem]
                    }
                  } else if (item[yGroupByDesc.colDesc] == name) {
                    if (xAxisDataItem === item[xGroupByDesc?.colDesc]) {
                      dataItem = item[colDescItem]
                    }
                  }
                })
              })
              return dataItem
            }),
            name,
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            }
          }
          return obj
        })
      } else if (groupByDesc?.length === 1) {
        config.series = colDesc.map(yItem => ({
          name: yItem,
          data: data.map(item => item[yItem]),
          type: 'line',
          emphasis: {
            focus: 'line'
          }
        }))
        config.legend.data = colDesc
      }

      return this.$parent.merge(config)
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-chart-line {
  width: 100%;
  height: 100%;
}
</style>
