<template>
  <el-dialog v-bind="$attrs" v-on="$listeners">
    <slot slot="title" name="title" />
    <slot />
    <slot slot="footer" name="footer" />
  </el-dialog>
</template>

<script>
export default {
  name: 'KshDialog',
  data() {
    return {}
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  color: #0f0f0f;
  font-size: 12px;
  width: 460px;
  .el-dialog__header {
    color: #0f0f0f;
    font-size: 12px;
    font-weight: bold;
    background-color: #fff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__headerbtn {
      color: #0f0f0f;
      top: 15px;
    }
  }
  .el-dialog__body {
    color: #0f0f0f;
    font-size: 12px;
    text-align: center;
    padding: 15px 15px;
  }
  .el-dialog__close {
    color: #909399 !important;
  }
  .el-dialog__footer {
    color: #0f0f0f;
    text-align: center;
  }
}
</style>
