<template>
  <el-button v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-button>
</template>

<script>
export default {
  name: 'KshButton'
}
</script>

<style scoped lang="scss">
// scss
.el-button {
  border-radius: 2px;
  min-width: 90px;
}
.el-button--small {
  padding: 0 0;
  height: 30px;
  line-height: 30px;
}
</style>
