import { Message } from 'element-ui'

const SDKAppIdData = {
  'localhost': 1400388764,
  'factory-dev.quxueshu.com': 1400388764,
  'factory-dev2.quxueshu.com': 1400388764,
  'factory-dev3.quxueshu.com': 1400388764,
  'factory-dev4.quxueshu.com': 1400388764,
  'factory-pre.quxueshu.com': 1400404508,
  'factory.quxueshu.com': 1400404508
}
const state = {
  SDKAPPID: SDKAppIdData[window.location.hostname] || SDKAppIdData['localhost'],
  level: 0, // 日志级别
  ready: false,
  liveStatus: 0,
  roomStatus: {
    disable: false, // 房间状态
    message: '',
    type: 'error'
  }
}
const getters = {
  getLevel: state => {
    return state.level
  }
}
const actions = {}

const mutations = {
  /**
   * 设置 sdk 日志级别
   * 调用时机：启动活动时根据环境变量设置
   * @param {Object} state
   * @returns
   */
  setLevel(state) {
    const env = process.env.VUE_APP_BUILD_ENV
    state.level = env === 'development' ? 0 : env === 'pre' ? 1 : env === 'publish' ? 4 : 2
  },
  /**
   * 更新 sdk 初始化状态
   * 调用时机：sdk 初始化成功或失败时
   * @param {Object} state
   * @param {data | Boolean} data
   * @returns
   */
  updateReadyStatus(state, data) {
    state.ready = data
  },
  setRoomStatus(state, data) {
    state.roomStatus = data
  },
  showDisableMsg(state) {
    if (!state.roomStatus.message) return
    Message({
      type: state.roomStatus.type,
      message: state.roomStatus.message
    })
  },
  showMessage(state, options) {
    if (state.message) {
      state.message.close()
    }
    state.message = this.$message({
      message: options.message,
      type: options.type || 'success',
      duration: options.duration || 2000,
      offset: 40
    })
  },
  setLiveStatus(state, data) {
    state.liveStatus = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
