import actions from "./action";
import mutations from "./mutation";
const state = {
  trainClassifyList: [],  // 代表培训内容分类
  trainCourseList: [],    // 厂家端课程列表
  trainProgramList: [],

};
const getters = {
  trainClassifyList: state => state.trainClassifyList,
  trainCourseList: state => state.trainCourseList,
  trainProgramList: state => state.trainProgramList,
};
export default {
  state,
  getters,
  actions,
  mutations
};
