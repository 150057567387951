<template>
  <div v-if="visible">
    <ksh-dialog
      v-show="!showTagModal"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="false"
      width="50%"
    >
      <div slot="title">{{ videoId ? '编辑视频' : '上传视频' }}</div>
      <div class="pagemain-upload-video-box">
        <div class="upload-video-content-box">
          <div class="flex-start">
            <div class="grow-shrink0"><span style="color: #e8312f;">*</span>视频名称:</div>
            <div class="w100v pdg-lr10">
              <ksh-input
                v-model.trim="title"
                v-clear-emoij
                type="text"
                placeholder="输入视频名称"
                maxlength="40"
                show-word-limit
              />
            </div>
          </div>
          <div class="mgn-t20">
            <div><span style="color: #e8312f;">*</span>上传视频 (建议视频时长不少于5分钟，如视频内存较大，建议压缩后上传)</div>
            <div class="w100v mgn-t10" style="position: relative; z-index: 10;">
              <el-upload
                action=""
                :http-request="onHttpRequest"
                name="video"
                :disabled="isDisableUpload"
                :show-file-list="false"
                :before-upload="beforeUploadVideo"
                list-type="picture-card"
                style="top: 0; left: 10px; width: 177px; height: 100px; border-radius: 6px; z-index: 500;"
              >
                <img v-if="videoCoverUrl" :src="videoCoverUrl" style="width: 100%; height: 100%;">
                <div
                  v-else-if="upLoadSuccess"
                  slot="trigger"
                  class="wrapper"
                  :class="{ 'upload-success': upLoadSuccess }"
                >
                  <div><img :src="require('@/assets/iconImg/success.png')" alt="" class="image-w"></div>
                  <div>上传成功</div>
                </div>
                <div v-else-if="upLoading" slot="trigger" class="wrapper">
                  <div><el-progress type="circle" :percentage="percent" :width="50" /></div>
                  <div>上传中...</div>
                </div>
                <div
                  v-else-if="upLoadError"
                  slot="trigger"
                  class="wrapper"
                  :class="{ 'upload-error': upLoadError }"
                >
                  <div><img :src="require('@/assets/iconImg/fail.png')" alt="" class="image-w"></div>
                  <div>上传失败</div>
                </div>
                <div v-else slot="trigger" class="wrapper">
                  <div><SvgIcon name="uploadvideo" class="image-w" style="font-size: 28px;" /></div>
                  <div>上传视频</div>
                </div>
                <div v-if="isDisableUpload || !title" style="position: absolute; top: 0; left: 10px; width: 177px; height: 100px; border-radius: 6px; z-index: 1000;" @click="onDisabledVideo" />
              </el-upload>
              <el-upload
                v-if="upLoaded"
                action=""
                :http-request="onHttpRequest"
                name="video"
                :disabled="isDisableUpload"
                :show-file-list="false"
                :on-progress="uploadVideoProcess"
                :before-upload="beforeUploadVideo"
              >
                <el-button type="text">重新上传</el-button>
              </el-upload>
              <div v-if="duration" class="mgn-t10 color-red">
                <!-- 提示：视频时长{{ duration >= 60 ? parseInt(duration / 60) + '分' + (duration % (parseInt(duration / 60) * 60)) + '秒' : duration + '秒' }}， -->
                <!-- 科会观看时长要求{{ durationNeed >= 60 ? parseInt(durationNeed / 60) + '分' + (durationNeed % (parseInt(durationNeed / 60) * 60)) + '秒' : durationNeed + '秒' }}。 -->
              </div>
            </div>
          </div>
          <div class="mgn-t20">
            <div class="grow-shrink0"><span style="color: #e8312f;">*</span>视频封面 (建议图片尺寸16：9)</div>
            <div class="w100v mgn-t10">
              <el-upload
                :action="uploadApi"
                :headers="headers"
                name="image"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                list-type="picture-card"
                accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                style="top: 0; left: 10px; width: 177px; height: 100px; border-radius: 6px; z-index: 500;"
              >
                <img v-if="customCoverUrl" :src="customCoverUrl" style="width: 100%; height: 100%; border-radius: 6px;">
                <div v-else slot="trigger" class="wrapper">
                  <div><SvgIcon name="pic" class="image-w" style="font-size: 28px;" /></div>
                  <div>上传封面</div>
                </div>
              </el-upload>
              <div class="flex-start mgn-t20" style="align-items: baseline;">
                <div class="grow-shrink0"><span style="color: #e8312f;">*</span>标签/关键信息:</div>
                <div class="pdg-lr10">
                  <ProductLabelKnowledge :selected-tag-list="selectedTagList" :disabled-ids="disabledIds" @change="onTagChange" />
                  <el-button plain type="info" @click="onOpenTagModal">添加标签<svg-icon name="el-icon-plus" /></el-button>
                </div>
              </div>
              <div class="flex-start mgn-t20">
                <div class="grow-shrink0">选择分类:</div>
                <div class="w100v pdg-lr10">
                  <ksh-select v-model="categoryId" clearable placeholder="选择分类">
                    <el-option
                      v-for="item in optionsList"
                      :key="item.categoryId"
                      :label="item.title"
                      :value="item.categoryId"
                    />
                  </ksh-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex-center">
        <ksh-button @click="onClose">取消</ksh-button>
        <ksh-button :loading="btnLoading" type="primary" @click="onSubmit"> 确定 </ksh-button>
      </div>
    </ksh-dialog>
    <ProductLabel
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="selectedTagList"
      :options="{
        'SPREAD_DEPARTMENT_GROUP': {
          isShowTab: false
        },
        'CUSTOMIZE_TAG_GROUP': {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>

<script>
import API from '@/apis/api-types'
import { getToken } from '@/utils/auth'
import { mapActions } from 'vuex'
import { getCategoryList } from '@/services/materialService.js'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'

export default {
  name: 'UploadVideo',
  components: {
    ProductLabel,
    ProductLabelKnowledge
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    itemObj: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      showTagModal: false,
      selectedTagList: [],
      disabledIds: [],
      videoId: '',
      // -------------------- submit ----------------
      btnLoading: false,
      videoCoverUrl: '',
      customCoverUrl: '',
      upLoadCover: '',
      title: '',
      categoryId: '',
      // ---------------------- upLoad ------------------
      uploader: null,
      aliVideoAuthDto: {},
      isDisableUpload: false,

      percent: 0,
      upLoading: false,
      upLoaded: false,
      upLoadSuccess: false,
      upLoadError: false,
      // ------------------------- 管理分类 ---------------

      optionsList: [],
      duration: 0,
      durationNeed: 0
    }
  },
  computed: {
    uploadApi() {
      return `${API.uploadImage}?bucketType=104`
    },
    headers() {
      return { token: getToken() }
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.videoId = this.itemObj.videoId
        this.isDisableUpload = !!this.videoId
        this.getOptionsList()

        if (!this.videoId) {
          Object.assign(this.$data, this.$options.data())
          this.initUploader()
        } else {
          this.onGetTrainVideoDetail({ videoId: this.videoId }).then(res => {
            this.title = res.data.title
            this.videoCoverUrl = res.data.coverUrl || res.data.customCoverUrl
            this.customCoverUrl = res.data.customCoverUrl
            this.categoryId = res.data.category && res.data.category.categoryId
            this.upLoadCover = res.data.fileKey
            this.selectedTagList = res.data.tagInfos
            this.disabledIds = this.isDisableUpload ? res.data.tagInfos?.map(v => v.tagId) : []
          })
        }
      }
    }
  },
  methods: {
    ...mapActions(['onGetTrainVideoDetail', 'onUpdateTrainVideo']),
    onSubmitTag(selectedList) {
      this.showTagModal = false
      this.selectedTagList = [...selectedList]
    },
    onTagChange(selectedList) {
      this.selectedTagList = [...selectedList]
    },
    onOpenTagModal() {
      this.showTagModal = true
    },
    onClose() {
      if (this.upLoading) return this.$message.error('请稍等，正在上传视频')
      this.$emit('onClose')
    },
    async getOptionsList(categoryType = 'video') {
      try {
        const { data } = await getCategoryList({ categoryType })
        this.optionsList = data
      } catch (error) {
        this.optionsList = []
      }
    },
    onCheck(data) {
      const arr = [
        {
          name: 'title',
          message: '请填写视频名称'
        },
        {
          name: 'videoId',
          message: '请上传视频'
        },
        {
          name: 'coverUrl',
          message: '请上传视频封面'
        },
        {
          name: 'memberTagInfo',
          message: '请选择标签/关键信息'
        }
      ]
      for (const item of arr) {
        const checkItem = !!String(data[item.name])
        if (!checkItem) {
          this.$message.error(item.message)
          return true
        }
      }
    },
    onSubmit() {
      console.log('------------------- onSubmit -------------------------')
      if (this.upLoading) return this.$message.error('请稍等，正在上传视频')
      const memberTagInfo = this.selectedTagList.map(v => {
        return {
          userTagType: 10,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          knowledgePointIds: v.knowledgePointIds
        }
      })
      const Data = {
        videoId: this.videoId || this.aliVideoAuthDto.videoId,
        categoryId: this.categoryId || '',
        coverUrl: this.upLoadCover,
        title: this.title,
        memberTagInfo
      }
      if (this.onCheck(Data)) return
      this.onUpdateTrainVideo(Data).then(res => {
        this.$emit('onUpload', [res.data || {}])
        this.$message({
          message: this.videoId ? '编辑成功' : '保存成功',
          type: 'success',
          duration: 2000
        })
        this.onClose()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    initUploader() {
      this.uploader = null
      const _this = this
      const AliyunUpload = window.AliyunUpload
      this.uploader = new AliyunUpload.Vod({
        // 文件上传失败
        onUploadFailed: function(uploadInfo, code, message) {
          _this.upLoading = false
          _this.upLoaded = true
          _this.upLoadSuccess = false
          _this.upLoadError = true
          _this.$message.error(message || '上传失败，请稍后再试')
          console.log(message)
        },
        // 文件上传完成
        onUploadSucceed: function(uploadInfo) {
          _this.upLoading = false
          _this.upLoaded = true
          _this.upLoadSuccess = true
          _this.upLoadError = false
          _this.$message.success('上传成功')
          console.log(`onUploadSucceed: ${uploadInfo.file.name}, endpoint:${uploadInfo.endpoint}, bucket:${uploadInfo.bucket}, object:${uploadInfo.object}`)
        },
        // 文件上传进度
        onUploadProgress: function(uploadInfo, totalSize, percent) {
          _this.upLoading = true
          _this.upLoaded = false
          _this.upLoadSuccess = false
          _this.upLoadError = false
          _this.percent = Math.floor(percent * 100)
        },
        // STS临时账号会过期，过期时触发函数
        onUploadTokenExpired: function() {
          _this.$message.success('上传凭证过期，请重试')
        },
        // 开始上传
        onUploadstarted: async uploadInfo => {
          const { file } = uploadInfo
          if (uploadInfo.videoId) {
            const { data } = await this.$axios.get(this.$API.refreshUploadVideo, { params: { videoId: uploadInfo.videoId } })
            this.aliVideoAuthDto = data || {}
          } else {
            const { data } = await this.$axios.get(this.$API.getUploadVideoInfo, { params: { fileName: file.name, title: this.title } })
            this.aliVideoAuthDto = data || {}
          }
          if (!this.aliVideoAuthDto.uploadAuth) return
          this.percent = 0
          this.upLoading = true
          this.upLoaded = false
          this.upLoadSuccess = false
          this.upLoadError = false
          this.uploader.setUploadAuthAndAddress(uploadInfo, this.aliVideoAuthDto.uploadAuth, this.aliVideoAuthDto.uploadAddress, this.aliVideoAuthDto.videoId)
        }
      })
      this.uploader.init()
    },

    handleAvatarSuccess(res, file) {
      this.customCoverUrl = URL.createObjectURL(file.raw)
      this.upLoadCover = res.data.imageName || ''
    },
    onDisabledVideo() {
      if (!this.title) {
        this.$message.error({ message: '请先填写视频名称' })
        return false
      }
      this.$message.error({ message: '视频内容不可编辑' })
    },
    doUpload() {
      this.uploader.startUpload()
    },
    onHttpRequest() {
      // 覆盖默认行为
    },
    // 上传前回调
    beforeUploadVideo(file) {
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
      // var fileSize = file.size / 1024 / 1024 > 300;
      // if (fileSize) {
      //     this.$message.error("视频大小不能超过300MB");
      //     return false;
      // }
      const userData = '{"Vod":{"UserData":{"IsShowWaterMark":"false","Priority":"7"}}}'
      this.uploader.addFile(file, null, null, null, userData)
      this.doUpload()

      const video = document.createElement('video')
      video.src = URL.createObjectURL(file)
      // eslint-disable-next-line no-unused-vars
      video.addEventListener('loadedmetadata', _event => {
        this.duration = Math.round(video.duration)
        this.durationNeed = Math.ceil(this.duration * 0.95)
      })
    },
    // 进度条
    uploadVideoProcess(event, file) {
      this.videoUploadPercent = file.percentage.toFixed(0) * 1
    },
    // 图片上传
    beforeAvatarUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = 16 // 限制图片尺寸为16:9
        const height = 9
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = (img.width / img.height).toFixed(2) === (width / height).toFixed(2)
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          _this.$message.warning('建议视频封面图片尺寸16:9')
          return Promise.resolve()
        }
      )
      return isSize
    }
  }
}
</script>

<style>
.pagemain-upload-video-box .el-upload--picture-card {
  width: 177px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 0;
  background-color: #f5f5f5;
}
</style>
<style scoped lang="scss">
.pagemain-upload-video-box {
  text-align: left;
}
.upload-success {
  color: #09bb07;
  background: #e7fce6;
  border-radius: 6px;
}
.upload-error {
  color: #f6625e;
  background: #ffe8ea;
  border-radius: 6px;
}
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #787878;
  font-size: 12px;
  position: relative;
}
.title {
  padding: 0 20px 20px 35px;
  color: #0f0f0f;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #eee;
  margin-left: -20px;
  margin-right: -20px;
}
.text-info {
  color: #969696;
  text-align: left;
}
.text-5a5a {
  color: #5a5a5a;
}
.image-w {
  margin-bottom: 15px;
}
</style>
