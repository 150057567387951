/*
 * @Author: your name
 * @Date: 2020-12-23 19:19:34
 * @LastEditTime: 2020-12-24 19:14:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/live/mutation.js
 */
import * as Types from '../../mutations-types'

export default {
  [Types.GET_LIVE_PREVIEW_QR_CODE] (state, res) {
    state.liveQrCodePreviewData = {...state.liveQrCodePreviewData,[res.liveId]:res.code}
  },
  setLiveDefaultReviewSec(state, res) {
    state.liveDefaultReviewSec = res
  }
}
