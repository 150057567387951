<template>
  <el-button
    v-show="isShow"
    :ref="initItem.ref"
    :class="initItem.class"
    :style="initItem.style"
    :type="initItem.type"
    :size="initItem.size"
    :plain="initItem.plain"
    :round="initItem.round"
    :icon="initItem.icon"
    :circle="initItem.circle"
    :disabled="initItem.disabled"
    :loading="initItem.loading"
    @click="activate(item,'click')"
  >
    {{ initItem.text }}
    <i :class="initItem.iconTag" />
  </el-button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      keyMap: {
        click: 'item'
      }
    }
  },
  computed: {
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  methods: {
    activate(event, type = 'click') {
      this.$emit('onActivate', {
        value: event,
        key: this.item.key || this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    }
  }

}
</script>

