/*
 * @Author: your name
 * @Date: 2020-12-10 17:33:06
 * @LastEditTime: 2021-08-03 18:08:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/org/action.js
 */

import * as OrgService from '@/services/orgService'
import {GET_ORG_TREE} from '../../mutations-types'
import {GET_ORG_MANAGE_TREE} from '../../mutations-types'
export default {
    getCurrentLoginInfo({ commit, state }, params) {
        return OrgService.getCurrentLoginInfo(params).then(
            res => {
                return Promise.resolve(res.data);
            },
            rea => {
                return Promise.reject(rea.message);
            }
        );
    },
    getOrgList({ commit, state }, params) {
        return OrgService.getOrgList(params).then(
            res => {
                commit(GET_ORG_TREE, res.data);
                return Promise.resolve(res.data);
            },
            rea => {
                return Promise.reject(rea.message);
            }
        );
    },
  getOrgManageList({ commit, state }, params) {
        return OrgService.getOrgManageList(params).then(
            res => {
                commit(GET_ORG_MANAGE_TREE, res.data);
                return Promise.resolve(res.data);
            },
            rea => {
                return Promise.reject(rea.message);
            }
        );
    },
    getTopOrgTree({ commit, state }, params) {
        return OrgService.getTopOrgTree(params).then(
            res => {
                return Promise.resolve(res.data);
            },
            rea => {
                return Promise.reject(rea.message);
            }
        );
    },
    getRepresentList({ commit, state }, params) {
        return OrgService.getRepresentList(params).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea.message);
            }
        );
    },
    getTeamList({ commit }, data) {
        // 团队列表
        return OrgService.getTeamList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getDepartmentLayerDetail({}, data) {
        return OrgService.getDepartmentLayerDetail(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    onCreateDepartment({}, data) {
        return OrgService.onCreateDepartment(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },

    // --------------------------------------- 组织管理重构 ---------------------------------------
    conditionTreeList({}, data) {
        return OrgService.conditionTreeList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getRepresentInfo({}, data) {
        return OrgService.getRepresentInfo(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },

    getRepresentDetailInfo({}, data) {
        return OrgService.getRepresentDetailInfo(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    bindRepresentList({}, data) {
        return OrgService.bindRepresentList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    batchRecallCode({}, data) {
        return OrgService.batchRecallCode(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    batchImportOrgModel({}, data) {
        return OrgService.batchImportOrgModel(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    uploadBatchImportOrg({}, data) {
        return OrgService.uploadBatchImportOrg(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    uploadBatchImportOrgConfirm({}, data) {
        return OrgService.uploadBatchImportOrgConfirm(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },

    batchImportRepresentModel({}, data) {
        return OrgService.batchImportRepresentModel(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    uploadBatchImportRepresent({}, data) {
        return OrgService.uploadBatchImportRepresent(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    uploadBatchImportRepresentConfirm({}, data) {
        return OrgService.uploadBatchImportRepresentConfirm(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getRepresentDoctors({}, data) {
        return OrgService.getRepresentDoctors(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    doctorChangeRepresent({}, data) {
        return OrgService.doctorChangeRepresent(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    changeOrganization({}, data) {
        return OrgService.changeOrganization(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getBatchConditionTreeList({}, data) {
        return OrgService.getBatchConditionTreeList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getOrgSearchList({}, data) {
        return OrgService.getOrgSearchList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getOrgOperate({}, data) {
        return OrgService.getOrgOperate(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    editOrgInfo({}, data) {
        return OrgService.editOrgInfo(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    onDelDepartment({}, data) {
        return OrgService.onDelDepartment(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    onMoveDepartment({}, data) {
        return OrgService.onMoveDepartment(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getOrgInfoList({}, data) {
        return OrgService.getOrgInfoList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    frozenOrgInfo({}, data) {
        return OrgService.frozenOrgInfo(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    unFrozenOrgInfo({}, data) {
        return OrgService.unFrozenOrgInfo(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    uploadLogo({}, data) {
        return OrgService.uploadLogo(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    representSceneList({}, data) {
        return OrgService.representSceneList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    factoryUsePer({}, data) {
        return OrgService.factoryUsePer(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    createOrModify({}, data) {
        return OrgService.createOrModify(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    removeUser({}, data) {
        return OrgService.removeUser(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    orgStaffList({}, data) {
        return OrgService.orgStaffList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    factoryDirectRepresent({}, data) {
        return OrgService.factoryDirectRepresent(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    factoryAllRepresent({}, data) {
        return OrgService.factoryAllRepresent(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    needNoticeDelRepresent({}, data) {
        return OrgService.needNoticeDelRepresent(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    delOrgRepresent({}, data) {
        return OrgService.delOrgRepresent(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    freezeStaff({}, data) {
        return OrgService.freezeStaff(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    freezeMoreStaff({}, data) {
        return OrgService.freezeMoreStaff(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    projectJuniorList({}, data) {
        return OrgService.projectJuniorList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    representsDownload({}, data) {
        return OrgService.representsDownload(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    // screenRepresentList({}, data) {
    //   return OrgService.screenRepresentList(data).then(
    //     res => {
    //       return Promise.resolve(res);
    //     },
    //     rea => {
    //       return Promise.reject(rea);
    //     }
    //   );
    // },
    screenTrainRepresentList({}, data) {
        return OrgService.screenTrainRepresentList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    createUpdateRepresent({}, data) {
        return OrgService.createUpdateRepresent(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    recallResource({}, data) {
        return OrgService.recallResource(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    recallRepresentCode({}, data) {
        return OrgService.recallRepresentCode(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    getAccounts({}, data) {
        return OrgService.getAccounts(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    },
    downloadOrgList({}, data) {
        return OrgService.downloadOrgList(data).then(
            res => {
                return Promise.resolve(res);
            },
            rea => {
                return Promise.reject(rea);
            }
        );
    }
};
