import Layout from '@/layout'
import MicroApp from '@/layout/views/micro-app.vue'

export default {
  path: '/stategic',
  redirect: '/stategic/product/list',
  component: Layout,
  meta: {
    title: '市场策略',
    icon: 'stategic'
  },
  children: [
    {
      path: '/sub-app/stategic/product/list',
      name: 'ProductStaegic',
      component: MicroApp,
      meta: {
        title: '产品策略',
        activeMenu: '/sub-app/stategic/product/list',
        auth: ['stategic.ProductStaegic']
      }
    },
    {
      path: 'product/edit-create',
      name: 'ProductStategicCreate',
      component: () => import(/* webpackChunkName: "product" */ '@/views/stategic/product/edit-create.vue'),
      meta: {
        title: '新建策略',
        activeMenu: '/stategic/product/list',
        sidebar: false,
        auth: ['stategic.ProductStategicCreate']
      }
    }, {
      path: 'project/list',
      name: 'ProjectStaegic',
      component: () => import(/* webpackChunkName: "project" */ '@/views/stategic/project/list.vue'),
      meta: {
        title: '项目管理',
        activeMenu: '/stategic/project/list',
        cache: ['ProjectStaegicEditCreate', 'ProjectStaegicDetail'],
        auth: ['stategic.ProjectStaegic']
      }
    }, {
      path: 'project/edit-create',
      name: 'ProjectStaegicEditCreate',
      component: () => import(/* webpackChunkName: "project" */ '@/views/stategic/project/edit-create.vue'),
      meta: {
        title: '新建项目',
        activeMenu: '/stategic/project/list',
        sidebar: false,
        auth: ['stategic.ProjectStaegicEditCreate']
      }
    }, {
      path: 'project/detail',
      name: 'ProjectStaegicDetail',
      component: () => import(/* webpackChunkName: "project" */ '@/views/stategic/project/detail.vue'),
      meta: {
        title: '项目详情',
        activeMenu: '/stategic/project/list',
        sidebar: false,
        auth: ['stategic.ProjectStaegicDetail']
      }
    }
  ]
}
