<template>
  <div class="data-chart-card">
    <p v-if="chartData.groupByDesc.length" class="title">{{ chartData.modelName }}</p>
    <VueScrollBar v-show="chartData.data.length" v-if="chartData.groupByDesc.length">
      <div :class="{ 'data-chart-card-grid-layout': chartData.groupByDesc.length }">
        <div v-for="(dataItem, dataIndex) in chartData.data" :key="dataIndex" class="card-item">
          <div class="card-title" :title="cardTitle(dataItem)">{{ cardTitle(dataItem) }}</div>
          <div class="context">
            <span v-for="(item, index) in chartData.colDesc" :key="item">
              {{ item+': ' || '-' }}<strong>{{ dataItem[item] || '-' }}</strong> {{ index === chartData.colDesc.length - 1 ? '' : '，' }}
            </span>
          </div>
          <div class="suffix-icon">{{ dataIndex + 1 }}</div>
        </div>
      </div>
    </VueScrollBar>
    <div v-else class="data-chart-card-wrap">
      <div v-for="(dataItem, dataIndex) in chartData.data" :key="dataIndex" class="card-item">
        <div class="card-title" :title="chartData.modelName">{{ chartData.modelName }}</div>
        <div class="context">
          <span v-for="(item, index) in chartData.colDesc" :key="item">
            <template v-if="dataItem">
              {{ item+': ' || '-' }}<strong>{{ dataItem[item] || '-' }}</strong> {{ index === chartData.colDesc.length - 1 ? '' : '，' }}
            </template>
          </span>
        </div>
      </div>
    </div>
    <Empty v-show="!chartData.data.length" />
  </div>
</template>

<script>
import Empty from '../Empty/index.vue'
export default {
  name: 'DataChartCard',
  components: { Empty },
  props: {
    chartData: {
      type: Object,
      default: Object
    },
    aggregation: {
      type: Object,
      default: Object
    }
  },
  computed: {
    cardTitle() {
      const {
        xGroupByDesc: { colDesc }
      } = this.chartData
      return dataItem => colDesc + dataItem[colDesc]
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-chart-card {
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 1px;
    font-size: 18px;
    color: #464646;
    font-weight: bolder;
    padding: 0 5px;
    font-family: sans-serif;
  }
  .data-chart-card-grid-layout {
    width: calc(100% - 3px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
  }
  .data-chart-card-wrap {
    height: 100% !important;
    .card-item {
      width: 100% !important;
    }
  }
  .card-item {
    background-image: url('~./data-chart-card-bg.png');
    background-size: 100% 100%;
    background-position: cover;
    border: 2px solid #2196f3;
    border-radius: 6px;
    position: relative;
    .suffix-icon {
      width: 20px;
      position: absolute;
      right: 1px;
      bottom: calc(25% - 14px);
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    .card-title {
      padding-left: 15px;
      margin-top: 10px;
      margin-bottom: 5px;
      color: #2196f3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      font-size: 16px;
    }
    .context {
      padding: 0 30px 10px 20px;
      color: #464646;
      font-size: 14px;
    }
  }
}
</style>
