/*
 * @Author: your name
 * @Date: 2021-07-27 11:32:36
 * @LastEditTime: 2021-07-27 13:49:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/material/mutation.js
 */
import {GET_KNOWLEDGE_POINT_ENABLE, GET_KNOWLEDGE_POINT_LIST} from "@/store/mutations-types";

export default {
    [GET_KNOWLEDGE_POINT_LIST] (state,list) {
        state.knowledgePointList = list
    },
    [GET_KNOWLEDGE_POINT_ENABLE] (state,list) {
        state.knowledgePointEnable = list
    },
}