const getComponentName = async target => {
  let componentName
  if (typeof target.matched[target.matched.length - 1].components.default === 'function') {
    const res = await target.matched[target.matched.length - 1].components.default()
    componentName = res.default.name
  } else {
    componentName = target.matched[target.matched.length - 1].components.default.name
  }
  return componentName
}
// 处理路由缓存
export const dealCache = async(to, from, next, store) => {
  // 处理缓存页面
  if (to.meta.cache) {
    const componentName = await getComponentName(to)
    if (componentName) {
      store.commit('keepAlive/add', componentName)
    } else {
      console.warn('页面没有设置组件名称或与路由名不一致，请检查后修改')
    }
  }
  if (from.meta.cache) {
    const componentName = await getComponentName(from)
    // 通过 meta.cache 判断针对哪些页面进行缓存
    switch (Object.prototype.toString.call(from.meta.cache)) {
      case '[object String]':
        if (from.meta.cache != to.name) {
          store.commit('keepAlive/remove', componentName)
        }
        break
      case '[object Array]':
        if (!from.meta.cache.includes(to.name)) {
          store.commit('keepAlive/remove', componentName)
        }
        break
    }
    // 通过 meta.noCache 判断针对哪些页面不需要进行缓存
    // if (from.meta.noCache) {
    //   switch (Object.prototype.toString.call(from.meta.noCache)) {
    //     case '[object String]':
    //       if (from.meta.noCache == to.name) {
    //         store.commit('keepAlive/remove', componentName)
    //       }
    //       break
    //     case '[object Array]':
    //       if (from.meta.noCache.includes(to.name)) {
    //         store.commit('keepAlive/remove', componentName)
    //       }
    //       break
    //   }
    // }
    // 如果进入的是 Reload 页面，则也将离开页面的缓存清空
    if (to.name === 'reload') {
      store.commit('keepAlive/remove', componentName)
    }
  }
}
