<template>
  <div ref="data-chart-radar" class="data-chart-radar" />
</template>

<script>
import mixinsChart from '../../mixins/charts.js'
export default {
  name: 'DataChartRadar',
  mixins: [mixinsChart],
  computed: {
    chartDOM() {
      return this.$refs['data-chart-radar']
    },
    chartOptions() {
      const { colDesc, xGroupByDesc, modelName, data } = this.chartData

      xGroupByDesc.groupByValues = this.$parent.loadsh.uniq(data.map(item => {
        return item[xGroupByDesc.colDesc]
      }).filter(v => v))

      const config = {
        title: {
          text: modelName
        },
        radar: {
          indicator: xGroupByDesc.groupByValues.map(groupByValuesItem => {
            return {
              name: groupByValuesItem,
              max: Math.max(...data.map(item => item[colDesc]))
            }
          })
        },
        series: [{
          name: xGroupByDesc.colDesc,
          type: 'radar',
          tooltip: {
            trigger: 'item'
          },
          data: colDesc.map(colDescItem => ({
            name: colDescItem,
            value: xGroupByDesc.groupByValues.map(groupByValuesItem => {
              return (data.map(dataItem => {
                if (groupByValuesItem === dataItem[xGroupByDesc.colDesc]) {
                  return dataItem[colDescItem]
                }
              }).filter(v => v)).reduce((pre, cur) => pre + cur, 0)
            })
          }))
        }]
      }

      return  this.$parent.merge(config)
    }
  }
}
</script>
