/*
 * @Author: your name
 * @Date: 2021-02-23 11:45:36
 * @LastEditTime: 2021-08-16 18:05:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/store/module/trainCourseModule/action.js
 */
import * as TrainingService from '@/services/trainingService'
import * as Types from '../../mutations-types'
import moment from 'moment'

export default {
  onSendPreview({ commit, state }, data) {
    return TrainingService.sendPreview(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  // ------------------------------------ 课程培训 -------------------------
  onTrainClassifyList({ commit }, data) {
    // 代表培训内容分类
    return TrainingService.trainClassify(data).then(
      res => {
        commit(Types.SET_TRAIN_CLASSIFY_LIST, res.data || [])
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  creatTrainClassify({ commit }, data) {
    // 代表培训内容分类
    return TrainingService.creatTrainClassify(data).then(
      res => {
        commit(Types.CREAT_TRAIN_CLASSIFY_LIST, res.data || [])
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  removeTrainClassify({ commit, state }, classifyId) {
    // 删除代表培训内容分类
    return TrainingService.removeTrainClassify(classifyId).then(
      res => {
        commit(Types.REMOVE_TRAIN_CLASSIFY_LIST, classifyId)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  initTrainCourseList({ commit, state }, req) {
    commit(Types.SET_TRAIN_COURSE_LIST, [])
    return TrainingService.trainCourseList(req).then(res => {
      commit(Types.SET_TRAIN_COURSE_LIST, res.data.data)
      return Promise.resolve(res.data.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  getTrainCourseList({ commit, state }, req) {
    return TrainingService.trainCourseList(req).then(res => {
      commit(Types.SET_TRAIN_COURSE_LIST, [...state.trainCourseList, ...res.data.data])
      return Promise.resolve(res.data.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  onTrainCourseList({ commit }, data) {
    // 厂家端课程列表
    return TrainingService.trainCourseList(data).then(
      res => {
        commit(Types.SET_TRAIN_COURSE_LIST, res.data || [])
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainModifyCourse({ commit, state }, data) {
    // 保存课程详情
    return TrainingService.trainModifyCourse(data).then(
      res => {
        let obj = {
          id: res.data.id,
          title: res.data.title,
          coverUrl: res.data.coverUrl,
          videoCount: res.data.videoCount
        }
        if (!data.course.id) {
          commit(Types.ADD_TRAIN_COURSE_ITEM, { ...obj, createTime: moment().format('YYYY-MM-DD') })
        } else {
          commit(Types.UPDATE_TRAIN_COURSE_ITEM, obj)
        }
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onUploadVideo({ commit }, data) {
    // 视频上传
    return TrainingService.upLoadVideo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onGetTrainVideoList({ commit }, data) {
    // 视频列表
    return TrainingService.getTrainVideoList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onGetTrainVideoDetail({ commit }, data) {
    // 视频详情
    return TrainingService.getTrainVideoDetail(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onUpdateTrainVideo({ commit }, data) {
    // 编辑视频
    return TrainingService.updateTrainVideo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onGetCategoryList({ commit }, data) {
    // 视频、问题分类
    return TrainingService.getCategoryList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainCreateExam({ commit }, data) {
    // 创建问题
    return TrainingService.trainCreateExam(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onGetTrainExamList({ commit }, data) {
    // 问题列表
    return TrainingService.getTrainExamList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainCoursePreview({ commit }, data) {
    // 查看课程预览
    return TrainingService.trainCoursePreview(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getCourseDetail({ commit }, data) {
    // 查看课程预览
    return TrainingService.getCourseDetail(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainVideoList({ commit }, data) {
    // 通过课程编号获取视频列表
    return TrainingService.trainVideoList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainExamList({ commit }, data) {
    // 通过课程编号查询题目列表
    return TrainingService.trainExamList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainCourseDistribute({ commit }, data) {
    //课程分配给下级
    return TrainingService.trainCourseDistribute(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  trainCourseDegreeDistribute({ commit }, data) {
    //课程次数分配给下级
    return TrainingService.trainCourseDegreeDistribute(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainRemoveCourse({ commit, state }, id) {
    // 删除课程(绑定关系一起删除)
    return TrainingService.trainRemoveCourse(id).then(res => {
        commit(Types.DELETE_TRAIN_COURSE_ITEM, id)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getTrainProgramList({ commit, state }, req) {
    if (req.pageNo === 1) {
      commit(Types.SET_TRAIN_PROGRAM_LIST, [])
    }
    return TrainingService.trainProgramList(req).then(res => {
      commit(Types.SET_TRAIN_PROGRAM_LIST, [...state.trainProgramList, ...res.data.data])
      return Promise.resolve(res.data.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  onTrainModifyProgram({ commit }, data) {
    // 创建编辑活动
    return TrainingService.trainModifyProgram(data).then(
      res => {
        console.log('data', data)
        let obj = {
          id: res.data.id,
          title: res.data.title,
          coverUrl: res.data.coverUrl,
          status: res.data.status,
          finishedCount: 0,
          count: 0
        }
        if (!data.programsTrain.id) {
          commit(Types.ADD_TRAIN_PROGRAM_ITEM, {
            ...obj,
            startTime: res.data.startTime ? moment(res.data.startTime).format('YYYY-MM-DD') : '',
            endTime: res.data.endTime? moment(res.data.endTime).format('YYYY-MM-DD') : ''
          })
        } else {
          commit(Types.UPDATE_TRAIN_PROGRAM_ITEM, obj)
        }
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  allotSceneCode({ commit, state }, req) {
    return TrainingService.allotSceneCode(req).then(
      res => {
        let allotCount = 0
        req.body.quotaBos.map(v => {
          allotCount += v.codeCount
        })
        commit(Types.UPDATE_TRAIN_PROGRAM_LIST, {
          id: req.body.quotaSceneInfo.sceneId,
          allotCount: allotCount
        })
        return Promise.resolve(res)
      }, rea => {
        return Promise.reject(rea.message)
      })
  },
  onTrainRemoveProgram({ commit }, data) {
    // 删除培训活动（未分配的活动可被删除）
    return TrainingService.trainRemoveProgram(data).then(
      res => {
        commit(Types.DELETE_TRAIN_PROGRAM_ITEM, data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainProgramDataInfo({ commit }, data) {
    // 活动数据（活动详情+课程详情）
    return TrainingService.trainProgramDataInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainProgramProcessData({ commit }, data) {
    // 活动培训数据统计
    return TrainingService.trainProgramProcessData(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainExportProcessData({ commit }, data) {
    // 导出代表培训数据
    return TrainingService.trainExportProcessData(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onProgramTotalData({ commit }, data) {
    // 获取厂家端-活动信息total
    return TrainingService.programTotalData(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onCreateCategory({ commit }, data) {
    // 题目添加分类
    return TrainingService.createCategory(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  contactUs({ commit }, data) {
    return TrainingService.contactUs(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  codeUsedStatistic({ commit }, data) {
    return TrainingService.getCodeUsedStatistic(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getChildFactoryList() {
    return TrainingService.getChildFactoryList().then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getBannerList({ commit }, data) {
    return TrainingService.getBannerList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  checkCourseDegree({ commit }, data) {
    return TrainingService.checkCourseDegree(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
    getTrainRepresentList({ commit }, data) {
    return TrainingService.getTrainRepresentList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  // ------------------------------------ 课程培训 end ----------------------
}
