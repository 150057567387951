import actions from './action'
import mutations from './mutation'

const state = {
  isGenerate: false,
  routes: [],
  headerActived: 0
}
const getters = {
  sidebarRoutes: state => {
    return state.routes.length > 0 ? state.routes[state.headerActived].children : []
  },
  mobileSidebarRoutes: state => {
    const route = state.routes.length > 0 ? state.routes[state.headerActived].children : []
    function findRoute(routes, path) {
      // 定义一个内部递归函数
      function search(routesArray, path) {
        for (const route of routesArray) {
          // 如果找到匹配的路径，直接返回
          if (route.path === path) {
            return route
          }
          // 如果存在子路由，继续在子路由中查找
          if (route.children && route.children.length) {
            const found = search(route.children, path)
            if (found) {
              return found // 如果在子路由找到，返回结果
            }
          }
        }
        return null // 如果没有找到，返回null
      }

      return search(routes, path) // 调用内部递归函数开始搜索
    }
    const arr = [
      findRoute(route, '/'),
      findRoute(route, '/academic'),
      findRoute(route, '/entrust'),
      findRoute(route, '/account'),
      findRoute(route, '/approver')
    ].filter(v => v)
    return arr
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
