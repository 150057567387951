// FHD： 超高清，系统缺省名称
// HD： 高清，系统缺省名称
// SD： 标清，系统缺省名称
// LD： 流畅，系统缺省名称
export default {
  props: {
    videoUrl: {
      type: Object,
      default: () => ({
        mp4: {
          hd: '',
          sd: ''
        },
        hls: {
          hd: '',
          sd: ''
        }
      })
    }
  },
  data() {
    return {
      definition: {
        sd: '标清',
        hd: '高清',
        ld: '标清',
        fd: '流畅'
      }
    }
  },
  methods: {
    initVideo(defaultOptions = this.defaultOptions, fun) {
      defaultOptions.video.quality = []
      for (const key in this.videoUrl) {
        for (const childrenKey in this.videoUrl[key]) {
          if (this.videoUrl[key][childrenKey]) {
            defaultOptions.video.quality.push({
              name: `${this.definition[childrenKey] }-${key}`,
              url: this.videoUrl[key][childrenKey],
              type: key
            })
          }
        }
      }
      this.defaultOptions.video.quality = defaultOptions.video.quality.filter(item => item.url)
      fun()
    }
  }
}
