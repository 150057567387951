import request from '@/utils/request'
import API from '@/apis/api-types'
const state = {

}
const getters = {

}
const mutations = {

}
const actions = {
  importCommonDownModel(dataType) {
    return request.get(API.importCommonDownModel, { params: {dataType} }).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  confirmBatchImportOrgGradeSpeakerModel() {
    return request.get(API.confirmBatchImportOrgGradeSpeakerModel).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
