<template>
  <div ref="data-chart-pie" class="data-chart-pie" />
</template>

<script>
import mixinsChart from '../../mixins/charts.js'
export default {
  name: 'DataChartPie',
  mixins: [mixinsChart],
  computed: {
    chartDOM() {
      return this.$refs['data-chart-pie']
    },
    chartOptions() {
      const { colDesc, xGroupByDesc, data, modelName } = this.chartData
      const colDescLength = colDesc.length
      const config = {
        title: { text: modelName },
        series: colDesc.map((item, index) => {
          const seriesItem = {
            name: item,
            type: 'pie',
            radius: '50%',
            center: ['50%', '50%'],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              formatter: '{b}：{c}({d}%)',
              rich: {
                b: {
                  color: '#4C5058',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                }
              }
            }
          }

          let cutNum = 5
          const orderByData = this.$parent.loadsh.orderBy(this.$parent.loadsh.cloneDeep(data), [item], ['desc'])
          // 总数
          const totalDesc = orderByData.reduce((prev, cur) => (prev + cur[item]), 0)
          if (data.length > cutNum) {
            while (cutNum < 9) {
              const headerData = orderByData.slice(0, cutNum)
              // 前 cutNum 个总数
              const headerTotal = headerData.reduce((prev, cur) => prev + cur[item], 0)
              // 前五总占比
              const headerScale = headerTotal / totalDesc
              if (headerScale > 0.90 || cutNum === 8) {
                //
                const footerData = orderByData.slice(cutNum, orderByData.length - 1)
                seriesItem.data = data.map(yItem =>  {
                  let obj = null
                  headerData.forEach(headerDataItem => {
                    if (JSON.stringify(headerDataItem) === JSON.stringify(yItem)) {
                      obj = {
                        name: yItem[xGroupByDesc.colDesc],
                        value: yItem[item]
                      }
                    }
                  })
                  return obj
                }).filter(v => v)
                seriesItem.data.push({name: '其它', value: footerData.reduce((prev, cur) => {
                  return prev + cur[item]
                }, 0)})

                break
              } else {
                cutNum++
              }
            }
          } else {
            seriesItem.data = data.map(yItem => ({
              name: yItem[xGroupByDesc.colDesc],
              value: yItem[item]
            }))
          }

          if (colDescLength === 1) {
            seriesItem.center = ['50%', '60%']
          } else if (colDescLength === 2) {
            if (index === 0) {
              seriesItem.right = '40%'
              seriesItem.left = ''
              seriesItem.center = ['40%', '60%']
            } else {
              seriesItem.right = ''
              seriesItem.left = '40%'
              seriesItem.center = ['60%', '60%']
            }
          } else if (colDescLength === 3) {
            if (index === 0) {
              seriesItem.right = '40%'
              seriesItem.bottom = '50%'
              seriesItem.top = ''
              seriesItem.left = ''
              seriesItem.center = ['40%', '78%']
            } else if (index === 1) {
              seriesItem.right = ''
              seriesItem.bottom = '50%'
              seriesItem.top = ''
              seriesItem.left = '40%'
              seriesItem.center = ['60%', '78%']
            } else {
              seriesItem.right = '40%'
              seriesItem.bottom = ''
              seriesItem.top = '50%'
              seriesItem.left = ''
              seriesItem.center = ['40%', '60%']
            }
          } else if (colDescLength === 4) {
            if (index === 0) {
              seriesItem.right = '40%'
              seriesItem.bottom = '50%'
              seriesItem.top = ''
              seriesItem.left = ''
              seriesItem.center = ['40%', '78%']
            } else if (index === 1) {
              seriesItem.right = ''
              seriesItem.bottom = '50%'
              seriesItem.top = ''
              seriesItem.left = '40%'
              seriesItem.center = ['60%', '78%']
            } else if (index === 2) {
              seriesItem.right = '40%'
              seriesItem.bottom = ''
              seriesItem.top = '50%'
              seriesItem.left = ''
              seriesItem.center = ['40%', '60%']
            } else {
              seriesItem.right = ''
              seriesItem.bottom = ''
              seriesItem.top = '50%'
              seriesItem.left = '40%'
              seriesItem.center = ['60%', '60%']
            }
          }
          return seriesItem
        })
      }
      return  this.$parent.merge(config)
    }
  }
}
</script>
