<template>
  <div class="user">
    <el-dropdown class="user-container" trigger="click" @command="handleCommand">
      <div class="user-wrapper">
        <el-avatar>
          <svg-icon name="avatar" />
        </el-avatar>
      </div>
      <el-dropdown-menu slot="dropdown" class="user-dropdown">
        <el-dropdown-item command="account-and-security">
          <svg-icon class="user-icon-svg" name="account-and-security" />
          <span>账户和安全</span>
        </el-dropdown-item>
        <el-dropdown-item v-if="personInfo.accountNum" command="multiple-account-switching">
          <svg-icon class="user-icon-svg" name="multiple-account-switching" /><span>切换账号</span>
        </el-dropdown-item>
        <el-dropdown-item v-if="personInfo.orgLoginVo.isTopOrg" command="more-setting">
          <svg-icon class="user-icon-svg" name="more-setting" /><span>更多设置</span>
        </el-dropdown-item>
        <el-dropdown-item command="recovery-station">
          <svg-icon class="user-icon-svg" name="del" /><span>回收站</span>
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          <svg-icon class="user-icon-svg" name="logout" /><span>退出登录</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'MobileUserMenu',
  inject: ['reload'],
  computed: {
    personInfo() {
      return {
        ...this.$store.state.user.metadataUserInfo,
        orgLoginVo: {
          ...this.$store.state.user.metadataUserInfo?.orgLoginVo
        }
      }
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'account-and-security':
          this.$router.push({
            name: 'SafeCenter'
          })
          break
        case 'multiple-account-switching':
          this.$router.push({
            name: 'SelectFactory'
          })
          break
        case 'more-setting':
          this.$router.push({
            name: 'DoctorCertifySetRule'
          })
          break
        case 'recovery-station':
          this.$router.push({
            name: 'RecoveryStation'
          })
          break
        case 'logout':
          this.$store.dispatch('logout', 'UserMenu->login').then(() => {
            this.$router.push({
              name: 'UserLogin',
              query: {
                redirect: this.$route.fullPath
              }
            })
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-container {
  display: inline-block;
  cursor: pointer;
  padding-right: 5px;
  .user-wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    .el-avatar {
      width: 30px;
      height: 30px;
      line-height: 30px;
      background: #eee;
      vertical-align: middle;
    }
  }
}
.user-dropdown {
  width: 155px;
  .user-icon-svg {
    margin-right: 13px;
    font-size: 14px;
  }
  .el-dropdown-menu__item:not(.is-disabled) {
    color: #a3a3a3;
    >span {
      color: #5a5a5a default;
    }
  }
  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    >span {
      color: $color-primary;
    }
  }
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    line-height: 36px !important;
    padding-left: 33px !important;
  }
}
</style>
