<template>
  <div v-if="item.meta && item.meta.sidebar !== false && showItem">
    <router-link
      v-if="!hasChildren"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      :to="resolvePath(item.path)"
    >
      <a
        :href="isExternal(resolvePath(item.path)) ? resolvePath(item.path) : href"
        :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        :target="isExternal(resolvePath(item.path)) ? '_blank' : '_self'"
        @click="navigate"
      >
        <el-menu-item :title="item.meta.title" :index="resolvePath(item.path)">
          <svg-icon v-if="item.meta.icon" :name="item.meta.icon" />
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </a>
    </router-link>
    <el-submenu v-else :title="item.meta.title" :index="resolvePath(item.path)">
      <template slot="title">
        <svg-icon v-if="item.meta.icon" :name="item.meta.icon" />
        <span slot="title">{{ item.meta.title }}</span>
      </template>
      <template v-if="item.children && item.children.lenght !== 0">
        <SidebarItem
          v-for="route in item.children"
          :key="route.path"
          :item="route"
          :base-path="resolvePath(item.path)"
        />
      </template>
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import {isMobile, isLandscape} from '@/utils/util'
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMobile: isMobile(),
      isLandscape: isLandscape()
    }
  },
  computed: {
    hasChildren() {
      let flag = true
      if (this.item.children) {
        if (this.item.children.every(item => item.meta.sidebar === false)) {
          flag = false
        }
      } else {
        flag = false
      }
      return flag
    },
    showItem() {
      // 如果是移动端，只显示首页、财务管理、活动管理、我的审批, 如果是PC端，显示所有
      if (this.isMobile && this.isLandscape) {
        return this.item.meta.title === '首页' || this.item.meta.title === '财务管理' || this.item.meta.title === '活动管理' || this.item.meta.title === '我的审批'
      } else {
        return true
      }
    }
  },
  methods: {
    isExternal(path) {
      return /^(https?:|http?:|mailto:|tel:)/.test(path)
    },
    resolvePath(routePath) {
      if (this.isExternal(routePath)) {
        return routePath
      }
      if (this.isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-item.is-active,
.el-submenu .el-menu--inline .el-menu-item.is-active {
  background-color: $g-sub-sidebar-menu-active-bg !important;
  span {
    color: $g-main-sidebar-menu-color !important;
  }
}
.el-menu-item,
.el-menu-item span,
.el-submenu__title,
.el-submenu__title div {
  vertical-align: inherit;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
::v-deep {
  .el-menu-item,
  .el-submenu__title {
    vertical-align: middle;
    height: 40px !important;
    line-height: 40px;
    font-size: $font-size-normal;
    text-align: justify;
    text-align-last: justify;
    text-justify: inter-ideograph;
    color: #a3a3a3;
    span {
      width: 60px;
      display: inline-block;
      position: relative;
      text-align: justify;
      color: #787878;
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #eee;
        position: absolute;
        left: 0;
        bottom: 1px;
      }
    }
  }
}
.el-submenu {
  .el-menu--inline {
    .el-submenu > .el-submenu__title,
    .el-menu-item {
      line-height: 40px;
      padding-left: 45px !important;
      color: #a3a3a3;
      >span {
        &::after {
          height: 0;
        }
      }
      &:hover {
        transform: all 0.3s;
        background-color: $g-main-sidebar-menu-active-bg;
      }
    }
  }
}
.el-submenu,
.el-menu-item {
  min-width: inherit !important;
  color: #a3a3a3;
  >span {
    color: #787878;
  }
  .svg-icon {
    font-size: 16px;
    margin-right: 10px;
    vertical-align: -0.25em;
  }
}
a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
</style>
