<!--
 * @Author: your name
 * @Date: 2021-12-27 17:09:33
 * @LastEditTime: 2022-01-13 11:18:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ksh-factory-vue/src/views/academic/child/PageEnd.vue
-->
<template>
  <div class="exam-wrapper" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
    <el-row type="flex" align="middle">
      <span class="auto-line" />
      <span>第{{ currentPageIndex }} / {{ totalPage }}页</span>
      <span class="auto-line" />
    </el-row>
    <el-row
      v-if="showAction"
      type="flex"
      justify="space-between"
      align="middle"
      class="opt-wrapper"
    >
      <el-row type="flex" align="middle">
        <el-row v-if="hasHover || showPop">
          <el-popover
            placement="bottom"
            trigger="click"
            :value="showPop"
            @show="onShow"
            @hide="onHide"
          >
            <el-row type="flex" class="mgn-b10">
              <el-button
                type="primary"
                plain
                size="small"
                class="w100"
                @click.stop="onAddExam('single')"
              >
                单选题
              </el-button>
              <el-button
                type="primary"
                plain
                size="small"
                class="w100"
                @click.stop="onAddExam('multiple')"
              >
                多选题
              </el-button>
              <el-button
                type="primary"
                plain
                size="small"
                class="w100"
                @click.stop="onAddExam('blank_fill')"
              >
                填空题
              </el-button>
            </el-row>
            <el-row type="flex">
              <el-button
                type="primary"
                plain
                size="small"
                class="w100"
                @click.stop="onAddExam('text_fill')"
              >
                问答题
              </el-button>
              <el-button
                type="primary"
                plain
                size="small"
                class="w100"
                @click.stop="onAddExam('scale')"
              >
                量表题
              </el-button>
              <el-button
                type="primary"
                plain
                size="small"
                class="w100"
                @click.stop="onAddExam('sort')"
              >
                排序题
              </el-button>
            </el-row>
            <span slot="reference" class="text-primary-link">
              <i class="el-icon-plus font-weight" />
              <span class="mgn-l5">在此题后加入新题</span>
            </span>
          </el-popover>
        </el-row>
      </el-row>
      <el-row>
        <exam-opt-action
          :show-edit="false && !item.quoted"
          @onDel="onDel"
          @onMoveUp="onMoveUp"
          @onMoveDown="onMoveDown"
        />
      </el-row>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'PageEnd',
  props: {
    showAction: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    currentPageIndex: {
      type: Number,
      default: 0
    },
    totalPage: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }

  },
  data() {
    return {
      hasHover: false,
      showPop: false
    }
  },
  computed: {
  },
  mounted() {
    console.log(this.item)
  },
  methods: {
    onAddExam(type) {
      this.showPop = false
      this.$emit('onAddExam', type, this.index)
    },
    onMouseOver() {
      this.hasHover = true
    },
    onMouseOut() {
      this.hasHover = false
    },
    onShow() {
      this.showPop = true
    },
    onHide() {
      this.showPop = false
    },
    onDel() {
      this.$emit('onDel')
    },
    onMoveUp() {
      this.$emit('onMoveUp')
    },
    onMoveDown() {
      this.$emit('onMoveDown')
    }
  }
}
</script>
<style lang="scss" scoped>
  .exam-wrapper {
    position: relative;
    padding-top: 15px;
    padding-bottom: 45px;
  }
  .auto-line {
    flex: 1;
    background-color: #c8c8c8;
    height: 1px;
    margin: 0 15px;
  }
  .opt-wrapper {
    position: absolute;
    height: 40px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .active-btn {
    color: rgba(25, 140, 255, 1);
    text-decoration: underline;
    position: relative;
    .image-preview {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
</style>
