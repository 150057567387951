import store from '@/store'
import { filterSpeakerIdentity } from '@/views/academic/tim/config/msg-filter'
const excludeCustomMessageType = [
  'co_present_change', 'webinar_forbidden_audio', 'webinar_audio_or_video_control', 'webinar_reject_open_audio_or_video',
  'webinar_apply_open_audio_or_video', 'webinar_reply_apply_open_audio_or_video', 'webinar_is_close',
  'webinar_network_report', 'webinar_stop_share', 'webinar_share_permission', 'stream_push_link_tracing_error', 'webinar_video_action'
]
export default function eventListener(_tim, TIM) {
  // 监听事件，例如：
  const sdkReady = event => {
    //     console.log('收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口', event)
    // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
    // event.name - TIM.EVENT.SDK_READY
    console.log('%c [ _tim, TIM ]-5', 'font-size:13px; background:pink; color:#bf2c9f;', event)
    if (event.name === 'sdkStateReady') {
      store.commit('tim/settings/updateReadyStatus', true)
    }
  }

  const messageReceived = ({ data }) => {
    console.log('收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面======================', data)

    data && data.map(item => {
      if (item.type !== TIM.TYPES.MSG_GRP_SYS_NOTICE && !(item.type === TIM.TYPES.MSG_CUSTOM && excludeCustomMessageType.includes(item.payload.description))) {
        const [msg] = filterSpeakerIdentity([item], store.state.tim.user.qresenterIDList || [])
        store.commit('tim/currentSession/sendMsg', { message: msg })
      }
    })
    // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
    // event.name - TIM.EVENT.MESSAGE_RECEIVED
    // event.data - 存储 Message 对象的数组 - [Message]
  }

  const messageRevoked = event => {
    console.log('收到消息被撤回的通知', event)
    event.data.map(item => {
      store.commit('tim/currentSession/revocation', { message: item })
    })
    // 收到消息被撤回的通知
    // event.name - TIM.EVENT.MESSAGE_REVOKED
    // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
  }

  const messageReadByPeer = event => {
    console.log('SDK 收到对端已读消息的通知，即已读回执。使用前需要将 SDK 版本升级至 v2.7.0 或以上。仅支持单聊会话。', event)

    // SDK 收到对端已读消息的通知，即已读回执。使用前需要将 SDK 版本升级至 v2.7.0 或以上。仅支持单聊会话。
    // event.name - TIM.EVENT.MESSAGE_READ_BY_PEER
    // event.data - event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
  }

  const conversaTionListUpdated = event => {
    console.log('收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面', event)

    // 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
    // event.name - TIM.EVENT.CONVERSATION_LIST_UPDATED
    // event.data - 存储 Conversation 对象的数组 - [Conversation]
  }

  const groupListUpdated = event => {
    console.log('收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面', event)

    // 收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面
    // event.name - TIM.EVENT.GROUP_LIST_UPDATED
    // event.data - 存储 Group 对象的数组 - [Group]
  }

  const profileUpdated = event => {
    console.log('收到自己或好友的资料变更通知', event)

    // 收到自己或好友的资料变更通知
    // event.name - TIM.EVENT.PROFILE_UPDATED
    // event.data - 存储 Profile 对象的数组 - [Profile]
  }

  const blacklistUpdated = event => {
    console.log('收到黑名单列表更新通知', event)

    store.commit('tim/currentSession/updateSpecialUser', event.data)
    // 收到黑名单列表更新通知
    // event.name - TIM.EVENT.BLACKLIST_UPDATED
    // event.data - 存储 userID 的数组 - [userID]
  }

  const error = event => {
    console.log('收到 SDK 发生错误通知，可以获取错误码和错误信息', event)
    store.commit('tim/settings/updateReadyStatus', false)
    // 收到 SDK 发生错误通知，可以获取错误码和错误信息
    // event.name - TIM.EVENT.ERROR
    // event.data.code - 错误码
    // event.data.message - 错误信息
  }

  const sdkNotReady = event => {
    console.log('收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作', event)
    store.commit('tim/settings/updateReadyStatus', false)

    // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
    // event.name - TIM.EVENT.SDK_NOT_READY
  }

  const kickedOut = event => {
    console.log('收到被踢下线通知', event)
    this.$message.error('被踢下线通知!')
    // 收到被踢下线通知
    // event.name - TIM.EVENT.KICKED_OUT
    // event.data.type - 被踢下线的原因，例如:
    //    - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
    //    - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
    //    - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢 （v2.4.0起支持）。
  }

  const netStateChange = event => {
    // console.log(' 网络状态发生改变（v2.5.0 起支持）。', event)
    if (event.data.state === TIM.TYPES.NET_STATE_DISCONNECTED) {
      this.$message.error('网络异常!')
    } else if (event.data.state === TIM.TYPES.NET_STATE_CONNECTING) {
      this.$message({
        type: 'warning',
        message: '连接中...'
      })
    } else if (event.data.state === TIM.TYPES.NET_STATE_CONNECTED) {
      this.$message({
        type: 'success',
        message: '连接成功'
      })
    }
    //  网络状态发生改变（v2.5.0 起支持）。
    // event.name - TIM.EVENT.NET_STATE_CHANGE
    // event.data.state 当前网络状态，枚举值及说明如下：
    //     \- TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
    //     \- TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
    //    \- TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
  }

  _tim.on(TIM.EVENT.SDK_READY, sdkReady)
  _tim.on(TIM.EVENT.MESSAGE_RECEIVED, messageReceived)
  _tim.on(TIM.EVENT.MESSAGE_REVOKED, messageRevoked)
  _tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, messageReadByPeer)
  _tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, conversaTionListUpdated)
  _tim.on(TIM.EVENT.GROUP_LIST_UPDATED, groupListUpdated)
  _tim.on(TIM.EVENT.PROFILE_UPDATED, profileUpdated)
  _tim.on(TIM.EVENT.BLACKLIST_UPDATED, blacklistUpdated)
  _tim.on(TIM.EVENT.ERROR, error)
  _tim.on(TIM.EVENT.SDK_NOT_READY, sdkNotReady)
  _tim.on(TIM.EVENT.KICKED_OUT, kickedOut)
  _tim.on(TIM.EVENT.NET_STATE_CHANGE, netStateChange)
}
