import request from '@/utils/request'
import API from '@/apis/api-types'
export function getDoctorSummary() {
  return request({
    method: 'get',
    url: API.getDoctorSummary
  })
}
export function onUploadExcelDoctor(req) {
  return request({
    method: 'post',
    url: API.uploadExcelToDoctor,
    data: req
  })
}
export function setDoctorCertifyRule(req) {
  return request({
    method: 'post',
    url: API.setDoctorCertifyRule,
    data: req
  })
}
export function getDoctorCertifyRule(params) {
  return request({
    method: 'get',
    url: API.getDoctorCertifyRule,
    params
  })
}
export function getDoctorImportRealAuthCount() {
  return request({
    method: 'get',
    url: API.getDoctorImportRealAuthCount
  })
}
export function onSetDoctorRealName(arr) {
  return request({
    method: 'post',
    url: API.doctorRealNameOpt,
    data: arr
  })
}
export function getDoctorMeetingAnswerInfo({codeId = '', doctorId = ''}) {
  // 获取医生的科会答题详情
  return request({
    method: 'get',
    url: API.getDoctorMeetingAnswerInfo,
    params: {
      codeId,
      doctorId
    }
  })
}
export function getDoctorLiveAnswerInfo({codeId = '', doctorId = '', liveId = ''}) {
  // 获取医生的直播答题详情
  return request({
    method: 'get',
    url: API.getDoctorLiveAnswerInfo,
    params: {
      codeId,
      doctorId,
      liveId
    }
  })
}
export function getDoctorList(data) {
  return request({
    method: 'get',
    url: API.getDoctorList,
    params: data
  })
}
export function getHospitalList(data) {
  return request({
    method: 'get',
    url: API.getHospitalList,
    params: data
  })
}
export function getSpeakerList(data) {
  return request({
    method: 'get',
    url: API.getSpeakerList,
    params: data
  })
}
export function getRepresentOrgList(data) {
  return request({
    method: 'get',
    url: API.getRepresentOrgList,
    params: data
  })
}
export function getDoctorAuth(doctorId = '') {
  return request({
    method: 'get',
    url: API.getDoctorAuth,
    params: {
      doctorId: doctorId,
      getMeetingInfo: false
    }
  })
}

export function inviteDoctorCertify(doctorId) {
  return request({
    method: 'get',
    url: API.inviteDoctorCertify,
    params: {doctorId}
  })
}
export function onSignUpDoctor(doctorId = '') {
  return request({
    method: 'post',
    url: API.signUpDoctor,
    params: {doctorId}
  })
}
export function unSignUpDoctor(doctorId = '') {
  return request({
    method: 'post',
    url: API.unSignUpDoctor,
    params: {doctorId}
  })
}
export function getTagCoverDoctor(tagId = '') {
  return request({
    method: 'get',
    url: API.getTagCoverDoctorSummary,
    params: {tagId}
  })
}
export function getTagCoverDoctorList(req) {
  return request({
    method: 'get',
    url: API.getTagCoverDoctorList,
    params: req
  })
}
export function setGradeDoctor({id = '', doctorLevelTypeEnum = '', levelQuestionnaireStatusEnum = '', rejectRemark = '', onlyUpdate = false}) {
  return request({
    method: 'post',
    url: API.setGradeDoctor,
    params: {
      id,
      doctorLevelTypeEnum,
      levelQuestionnaireStatusEnum,
      rejectRemark,
      onlyUpdate
    }
  })
}
export function getTagCoverDoctorChartData(tagId = '') {
  return request({
    method: 'get',
    url: API.getTagCoverDoctorChartData,
    params: {tagId}
  })
}

export function getTagDoctorHistory(doctorId = '') {
  return request({
    method: 'get',
    url: API.getTagDoctorHistory,
    params: {doctorId}
  })
}

export function getDoctorSceneSummary(doctorId = '') {
  // 医生个人数据汇总
  return request({
    method: 'get',
    url: API.getDoctorAllSceneCode,
    params: {doctorId}
  })
}

export function getDoctorSceneStatList(req) {
  // 医生个人场景数据
  return request({
    method: 'get',
    url: API.getDoctorSceneData,
    params: req
  })
}

export function getDoctorLiveVideoList(req) {
  // 医生个人直播视频列表
  return request({
    method: 'get',
    url: API.getDoctorLiveList,
    params: req
  })
}

export function departmentQuery(data) {
  return request({
    url: API.departmentQuery,
    method: 'get',
    params: data
  })
}

export function saveDoctorCode(req) {
  return request({
    method: 'post',
    url: API.saveDoctorCode,
    data: req
  })
}
export function getSpeakerListDown(req) {
  // 导出医生数据
  return request({
    method: 'get',
    url: API.getSpeakerListDown,
    params: req
  })
}

export function doctorDataCheck(data) {
  return request({
    method: 'post',
    url: API.doctorDataCheck,
    data
  })
}

export function doctorQuestionnaireDetail(params) {
  return request({
    method: 'get',
    url: API.doctorQuestionnaireDetail,
    params
  })
}
