<template>
  <div class="">
    <el-row type="flex" class="mgn-b10">
      <el-button
        type="primary"
        plain
        size="small"
        class="w100"
        @click.stop="onAddExam('single')"
      >
        单选题
      </el-button>
      <el-button
        type="primary"
        plain
        size="small"
        class="w100"
        @click.stop="onAddExam('multiple')"
      >
        多选题
      </el-button>
      <el-button
        type="primary"
        plain
        size="small"
        class="w100"
        @click.stop="onAddExam('blank_fill')"
      >
        填空题
      </el-button>
    </el-row>
    <el-row type="flex">
      <el-button
        type="primary"
        plain
        size="small"
        class="w100"
        @click.stop="onAddExam('text_fill')"
      >
        问答题
      </el-button>
      <el-button
        type="primary"
        plain
        size="small"
        class="w100"
        @click.stop="onAddExam('scale')"
      >
        量表题
      </el-button>
      <el-button
        type="primary"
        plain
        size="small"
        class="w100"
        @click.stop="onAddExam('sort')"
      >
        排序题
      </el-button>
    </el-row>
  </div>
</template>

<script>

export default {
  name: '',
  components: {},
  data() {
    return {

    }
  },
  computed: {},
  mounted() {

  },
  methods: {
    onAddExam(type) {
      this.$emit('addExam', type)
    }
  }
}
</script>