/*
 * @Author: your name
 * @Date: 2021-07-20 10:23:34
 * @LastEditTime: 2021-08-31 11:27:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/academic/index.js
 */
import actions from './action'
import mutations from './mutation'
const state = {
  academicSceneList: [], // 活动场景列表
  downOrgList: [], // 活动场景列表
  academicSceneType: 'meeting',
  academicSourceType: 'ower'
}
const getters = {
  academicSceneList: () => state.academicSceneList,
  downOrgList: () => state.downOrgList,
  academicSceneType: () => state.academicSceneType,
  academicSourceType: () => state.academicSourceType
}
export default {
  state,
  getters,
  actions,
  mutations
}
