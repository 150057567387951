import store from '@/store'
import { TIM, TIMUploadPlugin,
  eventListener
} from './tim-config'

const options = {
  SDKAppID: store.state.tim.settings.SDKAPPID
}

// 创建 SDK 实例，TIM.create() 方法对于同一个 SDKAppID 只会返回同一份实例
const _tim = TIM.create(options) // SDK 实例通常是 tim 表示

// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
// 0 普通级别，日志量较多，接入时建议使用
// 1 release级别，SDK 输出关键信息，生产环境时建议使用
// 2 告警级别，SDK 只输出告警和错误级别的日志
// 3 错误级别，SDK 只输出错误级别的日志
// 4 无日志级别，SDK 将不打印任何日志
_tim.setLogLevel(4)
// _tim.setLogLevel(store.getters['tim/settings/getLevel'])

// 注册腾讯云即时通信IM上传插件
_tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })

eventListener(_tim, TIM)
export { _tim }

// TYPES

// MSG_TEXT 文本消息
// MSG_IMAGE 图片消息
// MSG_GRP_TIP 群提示消息
// MSG_GRP_SYS_NOTICE 群系统通知消息
// MSG_CUSTOM 自定义消息
// MSG_PRIORITY_HIGH 群消息高优先级。建议选择该优先级的消息类型：红包消息和礼物消息

// CONV_C2C C2C(Client to Client, 端到端) 会话
// CONV_GROUP GROUP(群组) 会话
// CONV_SYSTEM SYSTEM(系统) 会话

// GRP_WORK 好友工作群
// GRP_PUBLIC 陌生人社交群
// GRP_MEETING 临时会议群
// GRP_AVCHATROOM 直播群

// GRP_MBR_ROLE_OWNER 群主
// GRP_MBR_ROLE_ADMIN 管理员
// GRP_MBR_ROLE_MEMBER 普通群成员

// GRP_TIP_MBR_JOIN 有成员加群
// GRP_TIP_MBR_QUIT 有群成员退群
// GRP_TIP_MBR_KICKED_OUT 有群成员被踢出群
// GRP_TIP_MBR_SET_ADMIN 有群成员被设为管理员
// GRP_TIP_MBR_CANCELED_ADMIN 有群成员被撤回管理员

// KICKED_OUT_MULT_ACCOUNT 多账号登录被踢
// KICKED_OUT_USERSIG_EXPIRED 签名过期

// 加群选项
// JOIN_OPTIONS_FREE_ACCESS 自由加入
// JOIN_STATUS_ALREADY_IN_GROUP 已在群中

// GRP_PROFILE_NOTIFICATION 群公告

// 网络状态
// NET_STATE_CONNECTED 已接入网络
// NET_STATE_CONNECTING 连接中
// NET_STATE_DISCONNECTED 未接入网络
