<template>
  <el-upload
    v-bind="$attrs"
    :action="action"
    :data="data"
    :name="name"
    :on-change="onChange"
    :before-upload="beforeUpload"
    :on-exceed="onExceed"
    :file-list="files"
    :limit="max === 1? null: max"
    :headers="Object.keys(headers).length === 0 ? {token} : headers"
    drag
    v-on="$listeners"
  >
    <slot>
      <div class="slot">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </div>
    </slot>
    <div v-if="!notip" slot="tip" class="el-upload__tip">
      <div style="display: inline-block;">
        <el-alert
          :title="`上传文件支持 ${ext.join(' / ')} 格式，单个文件大小不超过 ${size}MB，且文件数量不超过 ${max} 个`"
          type="info"
          show-icon
          :closable="false"
        />
      </div>
    </div>
  </el-upload>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    action: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: 'file'
    },
    size: {
      type: Number,
      default: 2
    },
    headers: {
      type: Object,
      default: () => ({})
    },
    max: {
      type: Number,
      default: 3
    },
    files: {
      type: Array,
      default: () => []
    },
    notip: {
      type: Boolean,
      default: true
    },
    ext: {
      type: Array,
      default: () => ['zip', 'rar']
    },
    token: {
      type: String,
      default: function() {
        return this.$store.state.user.userInfo.token
      }
    },
    autoUpload: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    beforeUpload(file) {
      const { isTypeOk, isSizeOk } = this.verify(file)
      if (!isTypeOk) {
        this.$message.error(`上传文件只支持 ${this.ext.join(' / ')} 格式！`)
      }
      if (!isSizeOk) {
        this.$message.error(`上传文件大小不能超过 ${this.size}MB！`)
      }

      return isTypeOk && isSizeOk
    },
    onExceed() {
      this.$message.warning('文件上传超过限制')
    },
    // 验证上传文件
    verify(file) {
      const fileName = file.name.split('.')
      const fileExt = fileName[fileName.length - 1]
      const isTypeOk = this.ext.indexOf(fileExt) >= 0
      const isSizeOk = file.size / 1024 / 1024 < this.size

      return {
        isTypeOk,
        isSizeOk
      }
    },
    onChange(file) {
      if (!this.autoUpload) {
        const {isTypeOk, isSizeOk} = this.verify(file)
        if (isTypeOk && isSizeOk) this.$emit('on-choose', file)
      }
      this.$emit('on-change', file)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
  width: auto;
  height: auto;
  overflow: unset;
  border-radius: 0;
  border: none;
  &.is-dragover {
    border-width: 1px;
  }
}
.slot {
  width: 300px;
  height: 160px;
  border: 1px dashed #ddd;
  transition: all 0.3s;
  &:hover,
  &:active {
    border-color: $color-primary;
  }
}
</style>
