<template>
  <div
    v-show="isShow"
    :ref="initItem.ref"
    class="base-vue-box"
    :class="initItem.class"
    :style="initItem.style"
  >
    <div v-if="initItem.layoutLeft || initItem.leftText">
      <div :class="initItem.leftClass" :style="initItem.leftStyle">{{ initItem.leftText }}</div>
    </div>
    <el-input-number
      :ref="initItem.ref"
      v-model="value"
      class="flex"
      :class="initItem.inputClass"
      :style="initItem.inputStyle"
      :min="initItem.min"
      :max="initItem.max"
      :step="initItem.step"
      :size="initItem.size"
      :name="initItem.name"
      :label="initItem.label"
      :precision="initItem.precision"
      :controls="initItem.controls"
      :controls-position="initItem.controlsPosition"
      :step-strictly="initItem.stepStrictly"
      :clearable="initItem.clearable"
      :placeholder="initItem.placeholder"
      @change="activate($event,'change')"
    />
    <div v-if="initItem.layoutRight || initItem.rightText">
      <div :class="initItem.rightClass" :style="initItem.rightStyle">{{ initItem.rightText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      initVal: null,
      keyMap: {
        change: 'change'
      }
    }
  },
  computed: {
    value: {
      get() {
        if (this.initVal === '') {
          return this.initVal
        }
        return this.initVal  || this.initItem.value
      },
      set(val) {
        this.initVal = val
      }
    },
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  watch: {
    ['initItem.value']() {
      this.value = this.initItem.value
    }
  },
  methods: {
    activate(event, type = 'input') {
      console.log(event, '+++++++')
      this.$emit('onActivate', {
        value: event,
        key: this.item.key || this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.base-vue-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .flex {
    flex: 1;
    ::v-deep .el-input__inner {
      background: #fafafa;
    }
  }
}
</style>
