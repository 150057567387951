<template>
  <div
    v-show="isShow"
    :ref="initItem.ref"
    class="base-vue-box"
    :class="initItem.class"
    :style="initItem.style"
  >
    <div v-if="initItem.layoutLeft || initItem.leftText">
      <span :class="initItem.leftClass" :style="initItem.leftStyle">{{ initItem.leftText }}</span>
    </div>
    <el-input
      :ref="initItem.ref"
      v-model="value"
      v-debounce="[activate, 'input']"
      class="flex"
      :class="initItem.inputClass"
      :style="initItem.inputStyle"
      :type="initItem.type"
      :clearable="initItem.clearable"
      :rows="initItem.rows"
      :autosize="initItem.autosize"
      :maxlength="initItem.maxlength"
      show-word-limit
      prefix-icon="el-icon-search"
      :placeholder="initItem.placeholder"
      @clear="activate($event,'input')"
      @change="activate($event,'change')"
      @keydown.enter.native="activate($event,'input')"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      initVal: null,
      keyMap: {
        input: 'input',
        change: 'input'
      }
    }
  },
  computed: {
    value: {
      get() {
        if (this.initVal === '') {
          return this.initVal
        }
        return this.initVal  || this.initItem.value
      },
      set(val) {
        this.initVal = val
      }
    },
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  watch: {
    ['initItem.value']() {
      this.value = this.initItem.value
    }
  },
  methods: {
    activate(event, type = 'input') {
      this.$emit('onActivate', {
        value: this.value,
        key: this.item.key || this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.base-vue-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .flex {
    flex: 1;
    ::v-deep .el-input__inner {
      background: #fafafa;
      border-radius: 20px;
    }
    ::v-deep .el-textarea__inner {
      background: #fafafa;
    }
  }
  .no-radius {
    ::v-deep .el-input__inner {
      border-radius: 0;
    }
  }
}
</style>
