<template>
  <div class="page-main">
    <slot name="title">
      <div v-if="title" class="title-containe-wrap">
        <div v-if="back && canGoBack" class="back-btn" @click="$router.back()"><svg-icon class="svg-icon" name="chevron-back" /> 返回</div>
        <div class="title-text-wrap">
          <div class="left-title">
            <span v-if="titleType === 'string'">{{ title }}</span>
            <span v-else-if="titleType === 'boolean'">{{ titlePath }}</span>
          </div>
          <div class="title-right">
            <slot name="title-right-container">
              {{ titleRightContainer }}
            </slot>
          </div>
        </div>
      </div>
    </slot>
    <div v-if="title" class="top-wrap">
      <slot name="top" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageMain',
  props: {
    title: [Boolean, String],
    titleRightContainer: String,
    back: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      titlePath: this.titlePathFn(),
      canGoBack: true
    }
  },
  computed: {
    titleType() {
      return typeof this.title
    }
  },
  mounted() {
    this.watcher(this.$route.meta, 'title', () => {
      if (Object.keys(this.$route.query).length) {
        this.$route.matched[this.$route.matched.length - 1].query = this.$route.query
      }
      this.titlePath = this.titlePathFn()
    })

    this.canGoBack = window.history.length > 1
  },
  methods: {
    titlePathFn() {
      const { matched } = this.$route
      return matched
        .map(item => {
          return item.meta?.title?.trim()
        })
        .join(' - ')
    },
    watcher(obj, name, callback) {
      let value = obj[name]
      Object.defineProperty(obj, name, {
        set: function(e) {
          value = e
          callback && callback(e)
        }.bind(this),
        get: function() {
          return value
        }.bind(this)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-main {
  position: relative;
  margin: $g-main-wrap-margin;
  background-color: #fff;
  padding: $g-main-wrap-padding;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  .title-containe-wrap {
    height: 36px;
    padding: 0 $g-main-wrap-padding 0 0;
    margin-left: -$g-main-wrap-padding;
    margin-right: -$g-main-wrap-padding;
    margin-top: -$g-main-wrap-padding;
    margin-bottom: $g-main-wrap-padding;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .back-btn {
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #5a5a5a;
      transition: all 0.3s;
      position: relative;
      &::after {
        content: '';
        width: 1px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #ddd;
      }
      &:hover {
        color: #555;
        background-color: #f8f8f8;
      }
      .svg-icon {
        margin-right: 3px;
        font-size: 16px;
        color: #c1c5c9;
      }
    }
    .title-text-wrap {
      flex: 1;
      margin-left: $g-main-wrap-padding;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    .left-title {
      font-weight: 600;
      color: #0f0f0f;
      line-height: 36px;
    }
  }
  .top-wrap {
    min-height: 1px;
    margin-top: -$g-main-wrap-padding;
    margin-left: -$g-main-wrap-padding;
    margin-right: -$g-main-wrap-padding;
    margin-bottom: $g-main-wrap-padding;
  }
  & + .page-main {
    margin-top: 0;
  }
}
</style>
