<template>
  <div style="height: 100%;">
    <div v-if="isMobile && !isLandscape" class="layout-mobile">
      <div id="app-main" :style="{ '--real-sidebar-width': realSidebarWidth }">
        <header class="header">
          <div class="header-container">
            <el-menu
              :default-active="$route.meta.activeMenu || $route.path"
              mode="horizontal"
              unique-opened
            >
              <template v-for="route in $store.getters['menu/mobileSidebarRoutes']">
                <TopbarItem
                  v-if="route.meta && route.meta.sidebar !== false"
                  :key="route.path"
                  class="topbar-item"
                  :item="route"
                  :base-path="route.path"
                />
              </template>
            </el-menu>
            <MobileUserMenu />
          </div>
        </header>
        <div class="wrapper-layout">
          <div class="main">
            <transition name="main" mode="out-in">
              <keep-alive
                v-if="isRouterAlive"
                :include="$store.state.keepAlive.list"
              >
                <RouterView :key="$route.path" />
              </keep-alive>
            </transition>
          </div>
        </div>
      </div>
      <novice-strategy v-model="dialogVisible" />
    </div>
    <div v-else class="layout-pc">
      <div
        id="app-main"
        :style="{
          '--real-sidebar-width': realSidebarWidth,
          'padding-top': paddingTop
        }"
      >
        <header v-if="!isMobile" class="header">
          <div class="header-container">
            <div class="main">
              <Logo
                :show-logo="true"
                :collapse="collapse"
                :class="{
                  'sidebar-logo-bg': true,
                  shadow: sidebarScrollTop
                }"
              />
              <div class="owner-title">
                {{ orgName }}
              </div>
            </div>
            <UserMenu :name="name" @open-guide-mode="onOpenGuideMode" />
          </div>
        </header>
        <div class="wrapper-layout">
          <div class="show sidebar-container">
            <div class="sub-sidebar-container" @scroll="onSidebarScroll">
              <el-menu
                class="menu"
                unique-opened
                :collapse-transition="true"
                :collapse="!(collapse === 'expand')"
                :default-active="$route.meta.activeMenu || $route.path"
              >
                <template v-for="route in $store.getters['menu/sidebarRoutes']">
                  <SidebarItem
                    v-if="route.meta && route.meta.sidebar !== false"
                    :key="route.path"
                    :item="route"
                    :base-path="route.path"
                  />
                </template>
                <template v-if="isMobile && isLandscape">
                  <SidebarUserMenu />
                </template>
              </el-menu>
            </div>
            <div
              :class="`sidebar-control ${collapse}`"
              @click="collapse = collapse === 'expand' ? '' : 'expand'"
            >
              <svg-icon
                class="icon"
                :name="`sidebar-control${collapse ? '-' + collapse : ''}`"
              />
              <span v-if="collapse === 'expand'">收起菜单</span>
            </div>
          </div>
          <div class="main-container">
            <!-- <Breadcrumb :class="{ shadow: scrollTop }" /> -->
            <div class="main">
              <transition name="main" mode="out-in">
                <keep-alive v-if="isRouterAlive" :include="$store.state.keepAlive.list">
                  <RouterView :key="$route.path" />
                </keep-alive>
              </transition>
            </div>
          </div>
        </div>
        <el-backtop
          :right="5"
          :bottom="5"
          title="回到顶部"
          style="z-index: 11;"
        />
      </div>
      <novice-strategy v-model="dialogVisible" />
    </div>
  </div>
</template>

<script>
import Logo from './components/Logo.vue'
import UserMenu from './components/UserMenu'
import MobileUserMenu from './components/Mobile/MobileUserMenu'
import NoviceStrategy from './components/NoviceStrategy.vue'
import TopbarItem from './components/Mobile/TopBarItem'
import SidebarUserMenu from './components/Mobile/SidebarUserMenu'
import SidebarItem from './components/SidebarItem'
import { mapGetters } from 'vuex'
import { isMobile, isLandscape } from '@/utils/util.js'
export default {
  name: 'Layout',
  components: {
    Logo,
    SidebarItem,
    TopbarItem,
    UserMenu,
    MobileUserMenu,
    SidebarUserMenu,
    NoviceStrategy
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      sidebarScrollTop: 0,
      scrollTop: 0,
      dialogVisible: false,
      collapse: 'expand',
      onChangeSideBarThrottled: Function,
      isMobile: isMobile(),
      isLandscape: isLandscape()
    }
  },
  computed: {
    ...mapGetters(['moduleType', 'moduleMenuObj', 'userInfo']),
    realSidebarWidth() {
      return this.collapse === 'expand' ? '150px' : '48px'
    },
    paddingTop() {
      return this.isMobile && this.isLandscape ? '0' : '48px'
    },
    orgName() {
      const {
        orgName = ''
      } = this.$store.state.user.metadataUserInfo?.orgLoginVo
      return orgName
    },
    name() {
      return this.moduleType
        ? `${this.moduleMenuObj[this.moduleType]?.name}引导`
        : '新手引导攻略'
    }
  },
  watch: {
    collapse(newVal) {
      sessionStorage.setItem('collapse', newVal)
    }
  },
  mounted() {
    // console.log(this.$store.getters['menu/sidebarRoutes'])

    // 监听是横屏还是竖屏
    window.addEventListener('orientationchange', () => {
      // 如果旋转了90度或者-90度
      if (window.orientation === 90 || window.orientation === -90) {
        this.isLandscape = true
      } else {
        this.isLandscape = false
      }
    })
    const storageCollapseVal = sessionStorage.getItem('collapse')
    if (
      storageCollapseVal === 'null' ||
      sessionStorage.getItem('collapse') == null
    ) {
      sessionStorage.setItem('collapse', this.collapse)
    }
    if (window.innerWidth < 500) {
      sessionStorage.setItem('collapse', null)
    }
    this.collapse = sessionStorage.getItem('collapse')

    window.addEventListener('scroll', this.onScroll)

    if (!localStorage.getItem('userGuide') && !this.isMobile) {
      // 第一次打开新手用户 默认打开
      this.dialogVisible = true

      localStorage.setItem('userGuide', true)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    reload(type = 1) {
      if (type == 1) {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      } else {
        this.$router.push({
          name: 'reload'
        })
      }
    },
    routeChange(newVal, oldVal) {
      if (newVal.name == oldVal.name) {
        this.reload()
      }
    },
    onSidebarScroll(e) {
      this.sidebarScrollTop = e.target.scrollTop
    },
    onScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
    },
    onOpenGuideMode() {
      if (!this.moduleType || this.moduleType === 'Index') {
        return (this.dialogVisible = true)
      }
      const url = this.moduleMenuObj[this.moduleType]?.url || ''
      url && window.open(`${window.location.origin}${url}`, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-pc {
  height: 100%;
  display: block;
}
#app-main {
  width: 100%;
  height: inherit;
  margin: 0 auto;
  transition: all 0.2s;
  @media screen and (min-width: 1200px) {
    min-width: 1200px;
  }
}
header {
  width: 100vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: $g-header-height;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #eee;
  .header-container {
    width: 100%;
    height: $g-topbar-height;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s, box-shadow 0.2s;
    .main {
      display: flex;
      align-items: center;
      height: 100%;
      .owner-title {
        font-size: 18px;
        margin-left: 10px;
        color: rgb(39, 46, 64);
      }
    }
  }
  ::v-deep .title {
    position: relative;
    height: inherit;
    padding: inherit;
  }
  .nav {
    display: flex;
    margin-left: 50px;
    .item {
      margin: 0 10px;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s;
      &.active,
      &:hover {
        background-color: $g-header-menu-active-bg;
      }
    }
  }
}
.wrapper-layout {
  position: relative;
  width: 100%;
  height: 100%;
  .sidebar-container {
    position: fixed;
    z-index: 1001;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: $g-sidebar-width;
    transition:
      0.3s width ease-in-out,
      0.3s padding-left ease-in-out,
      0.3s padding-right ease-in-out;
    .sidebar-control {
      width: calc(100% + 1px);
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
      padding: 0 17px;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      cursor: pointer;
      color: #787878;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      overflow: hidden;
      transition:
        0.3s width ease-in-out,
        0.3s padding-left ease-in-out,
        0.3s padding-right ease-in-out;
      span {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex-shrink: 0;
      }
      .icon {
        font-size: 14px;
        flex-shrink: 0;
      }
      &.expand {
        padding: 0 20px;
      }
    }
  }
  .sidebar-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.5);
    backdrop-filter: blur(2px);
    transition:
      0.3s width ease-in-out,
      0.3s padding-left ease-in-out,
      0.3s padding-right ease-in-out;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  .main-sidebar-container,
  .sub-sidebar-container {
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    // firefox隐藏滚动条
    scrollbar-width: none;

    // chrome隐藏滚动条
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .main-sidebar-container {
    width: $g-main-sidebar-width;
    background-color: $g-header-bg;
    color: #fff;
    .nav {
      width: inherit;
      @media screen and (min-width: 1200px) {
        padding-top: $g-header-height;
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60px;
        padding: 0 5px;
        cursor: pointer;
        transition: 0.3s;
        &.active,
        &:hover {
          background-color: $g-main-sidebar-menu-active-bg;
        }
        .svg-icon {
          margin: 0 auto;
          font-size: 20px;
        }
        span {
          text-align: center;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .sub-sidebar-container {
    background-color: $g-sub-sidebar-bg;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 40px;
    box-shadow: inset -1px 0 0 0 #eee;
    padding-right: 1px;
    .sidebar-logo {
      background: $g-sub-sidebar-bg;
      transition:
        0.3s width ease-in-out,
        0.3s padding-left ease-in-out,
        0.3s padding-right ease-in-out;
      &:not(.sidebar-logo-bg) {
        ::v-deep span {
          color: $g-sub-sidebar-menu-color;
        }
      }
      &.sidebar-logo-bg {
        background: $g-header-bg;
      }
      &.shadow {
        box-shadow: 0 10px 10px -10px darken($g-sub-sidebar-bg, 20);
      }
    }
    .menu {
      border-right: none;
      @media screen and (min-width: 1200px) {
        padding-top: 38px;
      }
      &:not(.el-menu--collapse) {
        width: var(--real-sidebar-width);
      }
      &.el-menu--collapse {
        width: 48px;
        ::v-deep .svg-icon {
          margin-right: 0;
        }
      }
    }
  }
  .main-sidebar-container + .sub-sidebar-container {
    left: $g-main-sidebar-width;
  }
  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin-left: $g-sidebar-width;
    background-color: #f5f7f9;
    transition:
      0.3s margin-left ease-in-out,
      0.3s padding-left ease-in-out,
      0.3s padding-right ease-in-out;
    .shadow {
      ::v-deep .breadcrumb {
        box-shadow: 0 10px 10px -10px #ccc;
      }
    }
    .main {
      height: 100%;
      flex: auto;
      position: relative;
      overflow: hidden;
    }
  }
}
header + .wrapper-layout {
  .sidebar-container {
    top: $g-header-height;
    .sidebar-logo {
      display: none;
    }
  }
  .main-container {
    .topbar-container {
      top: $g-header-height;
      ::v-deep .user {
        display: none;
      }
    }
  }
}

// 侧边栏收起时的样式
::v-deep .el-menu--collapse {
  .el-menu-item,
  .el-submenu {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .el-tooltip,
    .el-submenu__title {
      padding: 0 15px !important;
      > span,
      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
  .el-submenu.is-active > .el-submenu__title {
    color: $g-sub-sidebar-menu-active-color !important;
    background-color: $g-sub-sidebar-menu-active-bg !important;
  }
}

// 主内容区动画
.main-enter-active,
.main-leave-active {
  transition: all 0.2s;
}
.main-enter {
  opacity: 0;
  margin-left: -20px !important;
}
.main-leave-to {
  opacity: 0;
  margin-left: 20px !important;
}
@media screen and (max-width: 768px) {
  .layout-mobile {
    display: block;
    height: 100%;
  }
  #app-main {
    padding-top: $g-header-height;
    width: 100%;
    height: inherit;
    margin: 0 auto;
    transition: all 0.2s;
  }
  header {
    display: flex;
    align-items: center;
    .header-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s, box-shadow 0.2s;
      .main {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
  .topbar-item {
    display: inline-block;
  }
  .el-menu.el-menu--horizontal {
    border: 0;
  }
}
</style>
