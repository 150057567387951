import request from '@/utils/request'
import API from '@/apis/api-types'
export function getHelpNavList() {
  return request({
    url: API.getHelpNavList,
    method: 'get'
  })
}
export function getHelpMessageList({pageNo = 1, pageSize = 20, categoryId = '', searchKey = '', categoryName = ''}) {
  return request({
    url: API.helpMessageList,
    method: 'get',
    params: {pageNo, pageSize, categoryId, searchKey, categoryName}
  })
}
export function getHelpMessageDetail(articleId = '') {
  return request({
    url: API.helpMessageDetail,
    method: 'get',
    params: {articleId}
  })
}

export function onHelpMessageMark(params) {
  return request({
    url: API.helpMessageMark,
    method: 'get',
    params: params
  })
}
export function platformFeedback({content = '', imageUrls = [], feedBackType = 1}) {
  return request({
    url: API.platformFeedback,
    method: 'post',
    data: {content, imageUrls, feedBackType}
  })
}
