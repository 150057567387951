import request from '@/utils/request'
import API from '@/apis/api-types'
export function getPlatformList(pageNo = 1, pageSize = 20) {
  return request({
    method: 'get',
    url: API.getPlatformList,
    params: {pageNo, pageSize}
  })
}

export function onReadPlatform(messageId = '') {
  return request({
    method: 'post',
    url: `${API.onReadPlatform}?messageId=${messageId}`
  })
}
export function getTransformMessage(itemId = '') {
  return request({
    method: 'get',
    url: API.getTransformMessage,
    params: {itemId}
  })
}
export function getAllotMessage(itemId = '') {
  return request({
    method: 'get',
    url: API.getAllotMessage,
    params: {itemId}
  })
}
