<template>
  <component
    :is="require(`./${item.baseVue}.vue`).default"
    :item="item"
    :root="root"
    :reset-item="initItem(item,root)"
    @onActivate="onActivate"
  />
</template>

<script>
import {deepClone} from '@/utils/util'
import {runInitFunSingleton, parseFunction, setRootItem, getRootItem} from './baseUtils'

export default {
  name: 'BaseIndex',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    root: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      runInitFun: null,
      count: 0
    }
  },
  created() {
    this.runInitFun = runInitFunSingleton(this.root, setRootItem)
  },
  mounted() {
    this.initItem(this.item, this.root, 'init')
  },
  methods: {
    getBaseVue(name) {
      return require(`./${name}.vue`).default
    },
    // eslint-disable-next-line no-unused-vars
    initItem(item, root, type = 'attr') {
      const obj = {}
      for (const it of Object.entries(item)) {
        if (it[0] === 'init' && type === 'attr') continue
        if (it[1] && typeof it[1] === 'string') {
          if (it[1].indexOf('getRootItem') > -1) {
            const valueStr = it[1].replace(/(!)?getRootItem\(.*?\)/, '#')
            // eslint-disable-next-line no-useless-escape
            const getStr = it[1].replace(/.*?(\!*getRootItem\(.*?\)).*/, '$1')
            const key = getStr.replace(/getRootItem\((.*?)\)/, '$1').split('.')
            // 获取value属性值
            const keyVal = getStr.indexOf('!') > -1 ? !getRootItem(root, item[key[1]]) : getRootItem(root, item[key[1]])
            if ((typeof keyVal === 'string' || typeof keyVal === 'number') && valueStr.length > 1) {
              obj[it[0]] = valueStr.replace(/#/ig, keyVal)
            } else {
              obj[it[0]] = keyVal
            }
          } else {
            // 获取其它属性值
            obj[it[0]] = it[1].indexOf('function') > -1
              ? parseFunction(it[1].toString()).call(root.that, {item, root, runInitFun: this.runInitFun, deepClone})
              : it[1]
          }
        } else {
          obj[it[0]] = typeof it[1] === 'function' ? it[1].call(root.that, {item, root, runInitFun: this.runInitFun, deepClone}) : it[1]
        }
        if (it[0] === 'init' && type === 'init') {
          return
        }
      }
      return obj
    },
    onActivate(options) {
      const fun = options.item.methods && options.item.methods[options.methodType]
      if (fun && typeof fun === 'string') {
        fun.indexOf('function') > -1 ? parseFunction(fun.toString()).call(this.root.that, options, {root: this.root, runInitFun: this.runInitFun, setRootItem, deepClone}) : fun
      } else if (fun && typeof fun === 'function') {
        fun.call(this.root.that, options, {root: this.root, runInitFun: this.runInitFun, setRootItem, deepClone})
      }
      return this.$emit('onActivate', options)
    }
  }
}
</script>

