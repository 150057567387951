const state = {
  userID: '', // 用户id
  groupID: '', // 群组ID
  bannedUserList: [],
  speakerList: [], // 播客列表
  qresenterIdList: [] // 播客id列表
}
const getters = {}
const mutations = {
  setUserID(state, data) {
    state.userID = data
  },
  setGroupID(state, data) {
    state.groupID = data
  },
  setBannedUserList(state, data) {
    state.bannedUserList = data
  },
  setSpeakerList(state, data) {
    state.speakerList = data
  },
  setQresenterIDList(state, data) {
    state.qresenterIdList = data
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
