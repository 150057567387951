/*
 * @Author: your name
 * @Date: 2020-09-14 13:47:18
 * @LastEditTime: 2021-07-27 13:54:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factorydata/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import common from './module/common/index'
import index from './module/index/index'
import help from './module/help/index'
import doctor from './module/doctor/index'
import org from './module/org/index'
import user from './module/user/index'
import account from './module/account/index'
import live from './module/live/index'
import academic from './module/academic/index'
import training from './module/trainCourseModule/index'
import material from './module/material'
import menu from './module/menu'
import tim from './module/tim'
import keepAlive from './module/keepAlive'
import report from './module/report'
import importExcelModel from './module/importExcelModel/index'
Vue.use(Vuex)

const debug = process.env.VUE_APP_BUILD_ENV !== 'publish'

export default new Vuex.Store({
  modules: {
    common,
    index,
    doctor,
    org,
    user,
    account,
    help,
    live,
    training,
    academic,
    material,
    menu,
    tim,
    keepAlive,
    report,
    importExcelModel
  },
  strict: debug
})
