<template>
  <Skeleton>
    <div slot="header" class="help-header">
      <div class="logo-wrap">
        <Logo link-to-name="HelpList" collapse="expand" />
      </div>
      <span style="margin-left: 10px; font-size: 18px;">产品帮助中心</span>
    </div>
    <div slot="menu">
      <el-menu
        style="margin-top: 40px;"
        :default-active="activeNavKey"
        @select="onJump"
        @open="onOpen"
      >
        <template v-for="item in helpNavList">
          <el-submenu
            v-if="item.childes && item.childes.length > 0"
            :key="item.id"
            :index="item.id"
            name="item.name"
          >
            <template slot="title">
              <li class="submenu-padding">
                <p class="img-wrap">
                  <img v-if="openKey === item.id" :src="item.highlightIconVo && item.highlightIconVo.small" alt="">
                  <img v-else :src="item.normalIconVo && item.normalIconVo.small" alt="">
                </p>
                <span>{{ item.name }}</span>
              </li>
            </template>
            <el-menu-item v-for="v in item.childes" :key="v.id" :index="v.id">
              <span class="submenu-child-padding">{{ v.name }}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :key="item.id" :index="item.id">
            <li class="submenu-padding">
              <p class="img-wrap">
                <img v-if="activeNavKey === item.id" :src="item.highlightIconVo && item.highlightIconVo.small" alt="">
                <img v-else :src="item.normalIconVo && item.normalIconVo.small" alt="">
              </p>
              <span>{{ item.name }}</span>
            </li>
          </el-menu-item>
        </template>
        <el-menu-item :index="feedBackKey">
          <li class="submenu-padding">
            <p class="img-wrap">
              <img v-if="activeNavKey === feedBackKey" src="@/assets/nav/nav_fankui_active.png" alt="">
              <img v-else src="@/assets/nav/nav_fankui_normal.png" alt="">
            </p>
            <span>问题反馈</span>
          </li>
        </el-menu-item>
      </el-menu>
    </div>
  </Skeleton>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Skeleton from '../components/Skeleton.vue'
import Logo from '../components/Logo.vue'
import HelpRouter from '@/router/help.js'
export default {
  name: 'Help',
  components: { Skeleton, Logo },
  data() {
    return {
      activeNavKey: '',
      openKey: '',
      feedBackKey: 'feed',
      list: HelpRouter.children
    }
  },
  computed: {
    ...mapGetters(['helpNavList'])
  },
  watch: {
    $route(to) {
      if (to.name === 'HelpList') {
        this.activeNavKey = to.params.id
      } else if (to.name === 'HelpFeedBack') {
        this.activeNavKey = this.feedBackKey
      }
    }
  },
  mounted() {
    console.log(this.helpNavList)
    this.activeNavKey = this.$route.params.id
    this.openKey = this.$route.params.id
    this.initHelpNav()
  },
  methods: {
    ...mapActions(['initHelpNav']),
    onOpen(id = '') {
      this.openKey = id
    },
    onJump(id = '', props, elm) {
      this.activeNavKey = id
      if (id === this.feedBackKey) {
        this.$router.push('/help/feedback')
      } else {
        const name = elm.$el.textContent || ''
        this.$router.push({ path: `/help/list/${id}`, query: { name } })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-menu {
  width: var(--real-sidebar-width);
  border-right: none;
  .el-menu-item {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
  }
  .el-submenu {
    .el-submenu__title {
      height: 40px;
      line-height: 40px;
    }
    .el-menu {
      .el-menu-item {
        min-width: inherit;
        padding-left: 0 !important;
      }
    }
  }
}
.help-header {
  height: 100%;
  display: flex;
  align-items: center;
  .logo-wrap {
    width: var(--real-sidebar-width);
    height: 100%;
  }
}
.help-title {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 28px;
}
.submenu-padding {
  display: flex;
  align-items: center;
  .img-wrap {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    > img {
      width: 100%;
    }
  }
}
.submenu-child-padding {
  padding-left: 55px;
}
.el-main {
  padding: $g-main-wrap-padding;
  margin: $g-main-wrap-margin;
  background: #eff0f2;
}
</style>
