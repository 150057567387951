/*
 * @Author: your name
 * @Date: 2021-07-20 10:23:28
 * @LastEditTime: 2021-12-30 20:02:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/academic/action.js
 */
import * as AcademicService from '@/services/academicService'
import * as SceneService from '@/services/sceneService'
import * as Types from '../../mutations-types'
import moment from 'moment'

export default {
    initAcademicSceneList({ commit, state }, req) {
        commit(Types.GET_ACADEMIC_SCENE_LIST, [])
        return AcademicService.getAcademicSceneList(req).then(
            res => {
                commit(Types.GET_ACADEMIC_SCENE_LIST, res.data.data)
                return Promise.resolve(res.data)
            },
            rea => {
                return Promise.reject(rea.message)
            }
        )
    },
    getAcademicSceneList({ commit, state }, req) {
        return AcademicService.getAcademicSceneList(req).then(
            res => {
                commit(Types.GET_ACADEMIC_SCENE_LIST, [
                    ...state.academicSceneList,
                    ...res.data.data
                ])
                return Promise.resolve(res.data.data)
            },
            rea => {
                return Promise.reject(rea.message)
            }
        )
    },
    initDownOrgList({ commit, state }, req) {
        commit(Types.GET_DOWN_ORG_LIST, [])
        return AcademicService.getDownOrgList(req).then(
            res => {
                commit(Types.GET_DOWN_ORG_LIST, res.data.pageData)
                return Promise.resolve(res.data)
            },
            rea => {
                return Promise.reject(rea.message)
            }
        )
    },
    onShieldMeetingComment({ commit, state }, req) {
        return AcademicService.onShieldComment(req).then(
            res => {
                return Promise.resolve(res.data)
            },
            rea => {
                return Promise.reject(rea.message)
            }
        )
    },
    getOrganizationSiblingList({ commit, state }, opt) {
        return AcademicService.getOrganizationSiblingList(opt).then(
            res => {
                return Promise.resolve(res.data)
            },
            rea => {
                return Promise.reject(rea.message)
            }
        )
    },
    getSceneCommentList({ commit, state }, req) {
        return AcademicService.getSceneCommentList(req).then(res => {
            return Promise.resolve(res)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getSceneReplyList({ commit, state }, req) {
        return AcademicService.getSceneReplyList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onShieldSceneComment({ commit, state }, req) {
        return AcademicService.onShieldComment(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onSetTopComment({ commit, state }, req) {
        return AcademicService.onSetTopComment(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    setCommentShowType({ commit, state }, req) {
        return AcademicService.setCommentShowType(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onReplyComment({ commit, state }, req) {
        return AcademicService.onReplyComment(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onSetActiveComment({ commit, state }, req) {
        return AcademicService.onSetActiveComment(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getSceneTemplateList({ commit, state }, req) {
        return AcademicService.getSceneTemplateList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getSceneDetailTag({ commit, state, }, reqObj) {
        return AcademicService.getSceneDetailTag(reqObj).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onBindSceneTag({ commit, state }, req) {
        return AcademicService.onBindSceneTag(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onEditMeeting({ commit, state }, reqObj) {
        let req = reqObj.meetingId ? AcademicService.editMeeting : AcademicService.createMeeting
        return req(reqObj).then(res => {
            let obj = {
                id: res.data.meetingId,
                title: res.data.title,
                coverUrl: res.data.coverUrl,
            }
            if (!reqObj.meetingId) {
                if (state.academicSceneType === 'meeting' && state.academicSourceType === 'ower') {
                    commit(Types.ADD_ACADEMIC_SCENE_ITEM, {
                        ...obj,
                        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        usedCount: 0,
                        completeCount: 0,
                        totalCount: 0
                    })
                }
            } else {
                commit(Types.UPDATE_ACADEMIC_SCENE_ITEM, obj)
            }
            return Promise.resolve(res)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onDelMeeting({ commit, state }, meetingId) {
        return AcademicService.deleteMeeting(meetingId).then(res => {
            commit(Types.DELETE_ACADEMIC_SCENE_ITEM, meetingId)
            return Promise.resolve(res)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getMeetingDetail({ commit, state }, meetingId) {
        return AcademicService.getMeetingDetail(meetingId).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onEditLive({ commit, state }, reqObj) {
        return AcademicService.createLive(reqObj).then(res => {
            let obj = {
                id: res.data.id,
                title: res.data.title,
                coverUrl: res.data.coverImg && res.data.coverImg.normal,
                startAt: res.data.startAt
            }
            if (!reqObj.id) {
                if ((state.academicSceneType === 'live' || state.academicSceneType === 'webinar') && state.academicSourceType === 'ower') {
                    commit(Types.ADD_ACADEMIC_SCENE_ITEM, {
                        ...obj,
                        usedCount: 0,
                        completeCount: 0,
                        totalCount: 0
                    })
                }
            } else {
                commit(Types.UPDATE_ACADEMIC_SCENE_ITEM, obj)
            }
            return Promise.resolve(res)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onDelLive({ commit, state }, liveId) {
        return AcademicService.onDelLive(liveId).then(res => {
            commit(Types.DELETE_ACADEMIC_SCENE_ITEM, liveId)
            return Promise.resolve(res)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getLiveDetail({ commit, state }, liveId) {
        return AcademicService.getLiveDetail(liveId).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getAcademicVisitDetail({ commit, state }, sceneId) {
        return AcademicService.getAcademicVisitDetail(sceneId).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onDelAcademicVisit({ commit, state }, sceneId) {
        return AcademicService.onDelAcademicVisit(sceneId).then(res => {
            commit(Types.DELETE_ACADEMIC_SCENE_ITEM, sceneId)
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onEditAcademicVisit({ commit, state }, req) {
        let url = req.id ? AcademicService.onEditAcademicVisit : AcademicService.onCreateAcademicVisit
        return url(req).then(res => {
            if (req.id) {
                commit(Types.UPDATE_ACADEMIC_SCENE_ITEM, {
                    id: res.data.id,
                    title: res.data.title,
                    sceneChildTypeCount: req.visitContentBos.length
                })
            } else {
                if (state.academicSceneType === 'academicVisit' && state.academicSourceType === 'ower') {
                    commit(Types.ADD_ACADEMIC_SCENE_ITEM, {
                        id: res.data.id,
                        title: res.data.title,
                        sceneChildTypeCount: req.visitContentBos.length,
                        completeCount: 0,
                        recallCount: 0,
                        totalCount: 0,
                        createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
                    })
                }
            }
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getQuestionnaireJumpTree({ commit, state }, sceneId) {
        return AcademicService.getQuestionnaireJumpTree(sceneId).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onValidExamJumpLogic({ commit, state }, req) {
        return AcademicService.onValidExamJumpLogic(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getQuestionnaireDetail({ commit, state }, sceneId) {
        return AcademicService.getQuestionnaireDetail(sceneId).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onDelQuestionnaire({ commit, state }, sceneId) {
        return AcademicService.onDelQuestionnaire(sceneId).then(res => {
            commit(Types.DELETE_ACADEMIC_SCENE_ITEM, sceneId)
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getSimilarExamList({ commit, state }, req) {
        return AcademicService.getSimilarExamList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onEditQuestionnaire({ commit, state }, req) {
        let url = req.id ? AcademicService.onEditQuestionnaire : AcademicService.onCreateQuestionnaire
        return url(req).then(res => {
            let examCount = (res.data.questionnaireExamVoList || []).filter(v => v.examId).length
            if (req.id) {
                commit(Types.UPDATE_ACADEMIC_SCENE_ITEM, {
                    id: res.data.id,
                    title: res.data.title,
                    examCount: examCount
                })
            } else {
                if (state.academicSceneType === 'questionnaire' && state.academicSourceType === 'ower') {
                    commit(Types.ADD_ACADEMIC_SCENE_ITEM, {
                        type: req.type,
                        id: res.data.id,
                        title: res.data.title,
                        examCount: examCount,
                        completeCount: 0,
                        recallCount: 0,
                        totalCount: 0,
                        createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
                    })
                }
            }
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onCreateSceneExamJumpRule({ commit, state }, req) {
        return AcademicService.onCreateSceneExamJumpRule(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onEditSceneExamJumpRule({ commit, state }, req) {
        return AcademicService.onEditSceneExamJumpRule(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onDelSceneExamJumpRule({ commit, state }, req) {
        return AcademicService.onDelSceneExamJumpRule(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getSceneExamJumpRuleList({ commit, state }, req) {
        return AcademicService.getSceneExamJumpRuleList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getLiveSummary({ commit, state }, liveId) {
        return AcademicService.getLiveSummary(liveId).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getFactoryAllotCode({ commit }, data) {
        //获取厂家端拥有任务 =>meeting or questionnaire
        return AcademicService.getFactoryAllotCode(data).then(
            res => {
                return Promise.resolve(res)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    getSceneDepList({ commit, state }, opt) {
        return AcademicService.getSceneDepList(opt).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getFactoryOrgUserList({ commit, state }, req) {
        return AcademicService.getFactoryOrgUserList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onEnsetRepDoctorList({ commit, state }, req) {
        return AcademicService.onEnsetRepDoctorList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea)
        })
    },
    onAllotSceneCode({ commit, state }, req) {
        return AcademicService.onAllotSceneCode(req).then(res => {
            let allotCount = 0
            req?.body?.quotaBos?.map(v => {
                allotCount += v.codeCount
            })
            commit(Types.ADD_ACADEMIC_SCENE_CODE_COUNT, {
                id: req.body.quotaSceneInfo.sceneId,
                allotCount: allotCount
            })
            return Promise.resolve(res)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    onGetJsonRepresentList({ commit }, data) {
        // 获取代表身份
        return AcademicService.getJsonRepresentList(data).then(
            res => {
                return Promise.resolve(res)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    onCreateSceneRule({ commit, state }, req) {
        return AcademicService.onCreateSceneRule(req).then(res => {
            return Promise.resolve(res)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getAllotRuleDetail({ commit, state }, req) {
        return AcademicService.getAllotRuleDetail(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getFactoryAllotDepList({ commit, state }, req) {
        return SceneService.getFactoryAllotDepList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    },
    getSceneCodeUsedStatistic({ commit }, data) {
        return AcademicService.getSceneCodeUsedStatistic(data).then(
            res => {
                return Promise.resolve(res)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    getSceneAllotRecordList({ commit, state }, req) {
        return AcademicService.getSceneAllotRecordList(req).then(
            res => {
                return Promise.resolve(res.data)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    onDownSceneAllotRecordData({ commit }, req) {
        return AcademicService.onDownSceneAllotRecordData(req).then(
            res => {
                return Promise.resolve(res)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    onRecallSceneRepCode({ commit, state }, req) {
        return AcademicService.onRecallSceneRepCode(req).then(
            res => {
                let recallCount = 0
                req?.recallUsers?.map(v => {
                    recallCount += v.intVal
                })
                commit(Types.RECALL_ACADEMIC_SCENE_CODE_COUNT, { id: req.sceneId, recallCount })
                return Promise.resolve(res)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    onRecallSceneDepCode({ commit, state }, req) {
        return AcademicService.onRecallSceneDepCode(req).then(
            res => {
                let recallCount = 0
                req?.recallUsers?.map(v => {
                    recallCount += v.intVal
                })
                commit(Types.RECALL_ACADEMIC_SCENE_CODE_COUNT, { id: req.sceneId, recallCount })
                return Promise.resolve(res)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    // 分享模版
    sceneShareToDep({ commit, state }, req) {
        return AcademicService.sceneShareToDep(req).then(
            res => {
                return Promise.resolve(res)
            },
            rea => {
                return Promise.reject(rea)
            }
        )
    },
    getWebinarSpeakerList({ commit, state }, req) {
        return AcademicService.getWebinarSpeakerList(req).then(res => {
            return Promise.resolve(res.data)
        }, rea => {
            return Promise.reject(rea.message)
        })
    }
}
