import request from '@/utils/request'
import API from '@/apis/api-types'

export function sendPreview(data) {
  return request({
    url: API.sendPreview,
    method: 'post',
    data
  })
}
// --------------------------- 课程培训 -------------------------
export function trainClassify(options) {
  // 获取代表培训内容分类
  return request({
    url: API.trainClassify,
    method: 'get',
    params: options
  })
}
export function creatTrainClassify(options) {
  // 获取代表培训内容分类
  return request({
    url: API.creatTrainClassify,
    method: 'get',
    params: options
  })
}
export function removeTrainClassify(options) {
  // 获取代表培训内容分类
  return request({
    url: API.removeTrainClassify,
    method: 'get',
    params: options
  })
}
export function trainCourseList(options) {
  // 厂家端课程列表
  return request({
    url: API.trainCourseList,
    method: 'get',
    params: options
  })
}
export function trainModifyCourse(options) {
  // 保存课程详情
  return request({
    url: API.trainModifyCourse,
    method: 'post',
    data: options
  })
}
export function upLoadVideo(options) {
  // 视频上传
  return request({
    url: API.getUploadVideoInfo,
    method: 'get',
    params: options
  })
}
export function getTrainVideoList(options) {
  // 视频列表
  return request({
    url: API.getVideoList,
    method: 'get',
    params: options
  })
}
export function getTrainVideoDetail(options) {
  // 视频详情
  return request({
    url: API.getVideoDetail,
    method: 'get',
    params: options
  })
}
export function updateTrainVideo(options) {
  // 编辑视频
  return request({
    url: API.updateVideo,
    method: 'post',
    data: options
  })
}
export function getCategoryList(options) {
  // 视频、问题分类
  return request({
    url: API.getCategoryList,
    method: 'get',
    params: options
  })
}
export function trainCreateExam(options) {
  // 创建问题
  return request({
    url: API.createExam,
    method: 'post',
    data: options
  })
}
export function getTrainExamList(options) {
  // 问题列表
  return request({
    url: API.getExamList,
    method: 'post',
    data: options
  })
}
export function trainCoursePreview(options) {
  // 查看课程预览
  return request({
    url: API.trainCoursePreview,
    method: 'get',
    params: options
  })
}
export function getCourseDetail(options) {
  // 查看课程预览
  return request({
    url: API.getCourseDetail,
    method: 'get',
    params: options
  })
}
export function trainVideoList(options) {
  // 通过课程编号获取视频列表
  return request({
    url: API.trainVideoList,
    method: 'get',
    params: options
  })
}
export function trainExamList(options) {
  // 通过课程编号查询题目列表
  return request({
    url: API.trainExamList,
    method: 'get',
    params: options
  })
}

export function trainCourseDistribute(options) {
  // 课程分配给下级
  return request({
    url: API.trainCourseDistribute,
    method: 'post',
    data: options
  })
}
export function trainCourseDegreeDistribute(options) {
  // 课程次数分配给下级
  return request({
    url: API.trainCourseDegreeDistribute,
    method: 'post',
    data: options
  })
}
export function trainRemoveCourse(id = '') {
  // 删除课程(绑定关系一起删除)
  return request({
    url: API.trainRemoveCourse,
    method: 'get',
    params: { id }
  })
}
export function trainProgramList(options) {
  // 获取活动列表
  return request({
    url: API.trainProgramList,
    method: 'get',
    params: options
  })
}
export function trainModifyProgram(options) {
  // 创建/修改活动
  return request({
    url: API.trainModifyProgram,
    method: 'post',
    data: options
  })
}
export function trainRemoveProgram(options) {
  // 删除培训活动（未分配的活动可被删除）
  return request({
    url: API.trainRemoveProgram,
    method: 'get',
    params: options
  })
}
export function trainProgramDataInfo(options) {
  // 活动数据（活动详情+课程详情）
  return request({
    url: API.trainProgramDataInfo,
    method: 'get',
    params: options
  })
}
export function trainProgramProcessData(options) {
  // 活动培训数据统计
  return request({
    url: API.trainProgramProcessData,
    method: 'get',
    params: options
  })
}
export function trainExportProcessData(options) {
  // 导出活动培训数据
  return request({
    url: API.trainExportProcessData,
    method: 'get',
    params: options
  })
}
export function programTotalData(options) {
  // 获取厂家端-活动信息total
  return request({
    url: API.programTotalData,
    method: 'get',
    params: options
  })
}
export function createCategory(options) {
  // 题目添加分类
  return request({
    url: `${API.createCategory}?categoryType=${options.categoryType}&title=${options.title}`,
    method: 'post'
  })
}
export function contactUs(options) {
  return request({
    url: API.contactUs,
    method: 'get',
    params: options
  })
}
export function getCodeUsedStatistic(options) {
  return request({
    url: API.getCodeUsedStatistic,
    method: 'get',
    params: options
  })
}
export function getChildFactoryList() {
  return request({
    url: API.getChildFactoryList,
    method: 'get'
  })
}
export function getBannerList(options) {
  return request({
    url: API.getBannerList,
    method: 'get',
    params: options
  })
}
export function checkCourseDegree(options) {
  return request({
    url: API.checkCourseDegree,
    method: 'get',
    params: options
  })
}
export function getTrainRepresentList(options) {
  return request({
    url: API.getTrainRepresentList,
    method: 'get',
    params: options
  })
}
export function allotSceneCode(opt) {
  return request({
    url: API.onAllotWithCode,
    method: 'post',
    data: opt.body,
    params: { checkValue: opt.ticket }
  })
}
// --------------------------- 课程培训 end ----------------------
