<template>
  <div id="app">
    <!-- <router-view v-if='$route.meta.key' :key="$route.fullPath"></router-view> -->
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import warterMark from './utils/warter-mark'
import { onWxShare } from './utils/wx-config'
import { isMobile } from './utils/util'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['orgList'])
  },
  watch: {
    $route(to) {
      if (window.__wxjs_is_wkwebview) {
        if (window.wxShareLink === '' || window.wxShareLink == undefined) {
          window.wxShareLink = window.location.href.split('#')[0]
        }
      }
      // 判断微信浏览器
      if (isMobile()) {
        this.initWxShare()
      }
      if (to.name !== 'UserLogin') {
        this.getOrgList()
      }
    }
  },
  beforeDestroy() {
    this.removeWaterMark()
  },
  methods: {
    ...mapActions(['getOrgList', 'userSetUserInfo']),
    // 移除水印
    removeWaterMark() {
      warterMark.out(`${this.$store.state.user.userInfo.login}`)
    },
    initWxShare() {
      onWxShare({
        title: '趣学术SaaS系统-厂家端',
        image: 'https://yao-file.oss-cn-hangzhou.aliyuncs.com/ksh/static/img/quxueshucomlog.png',
        content: '实现优质医疗信息0.1秒精准传递到临床一线',
        func: () => {}
      })
    }
  }
}
</script>

<style lang="scss">
@import './styles/index';
#app {
  height: 100%;
  margin: 0 auto;
}
.is-pointor {
  cursor: pointer;
  -webkit-touch-callout: none; /* 系统默认菜单被禁用 */
  user-select: none;
}
#nprogress {
  .bar {
    background: $color-primary !important;
  }
  .spinner {
    .spinner-icon {
      border-top-color: $color-primary;
      border-left-color: $color-primary;
    }
  }
}
</style>
