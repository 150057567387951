<template>
  <el-dialog
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="false"
    :show-close="showClose"
    :visible.sync="visible"
    :title="title"
  >
    <div v-if="message" v-safe-html="message" />
    <div v-if="newLineMessage">{{ newLineMessage }}</div>
    <component :is="component" v-if="component" />
    <span slot="footer">
      <ksh-button v-if="cancelText" @click="onCancel">{{ cancelText }}</ksh-button>
      <ksh-button v-if="submitText" type="primary" @click="onSubmit">{{ submitText }}</ksh-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'MessageDialog',
  data() {
    return {
      visible: false,
      title: '提示',
      message: '',
      newLineMessage: '',
      component: '',
      closeOnClickModal: false,
      cancelText: '取消',
      submitText: '确定',
      showClose: false
    }
  },
  mounted() {
    this.visible = true
  },
  methods: {
    onCancel() {
      this.reject(false)
      this.visible = false
    },
    onSubmit() {
      this.resolve(true)
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  color: #0f0f0f;
  font-size: 12px;
  width: 460px;
  .el-dialog__header {
    color: #0f0f0f;
    font-size: 12px;
    font-weight: bold;
    background-color: #fff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    padding: 7px 14px;
    .el-dialog__headerbtn {
      color: #0f0f0f;
      top: 15px;
    }
  }
  .el-dialog__body {
    color: #0f0f0f;
    font-size: 12px;
    text-align: center;
    padding: 15px 15px;
  }
  .el-dialog__close {
    color: #909399 !important;
  }
  .el-dialog__footer {
    color: #0f0f0f;
    text-align: center;
  }
}
</style>
