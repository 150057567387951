/*
 * @Author: your name
 * @Date: 2020-10-28 17:46:02
 * @LastEditTime: 2020-11-10 17:34:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/common/index.js
 */
import actions from './action'
import mutations from './mutation'
const state = {
  downLoadFileList: [],
  messageList: [],
  indexHelpList: [],
  indexTopMessage: []
}
const getters = {
  downLoadFileList: () => state.downLoadFileList,
  messageList: () => state.messageList,
  indexHelpList: () => state.indexHelpList,
  indexTopMessage: () => state.indexTopMessage
}
export default {
  state,
  getters,
  actions,
  mutations
}
