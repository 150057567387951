<template>
  <el-select v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-select>
</template>

<script>
export default {
  name: 'KshSelect',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
// scss
    ::v-deep input {
        border-radius: 1px;
        &::placeholder {
          // color: #5A5A5A;
        }
    }
    ::v-deep .el-input--samll {
        height: 38px;
    }
</style>
