<template>
  <div ref="data-chart-bar" class="data-chart-bar" />
</template>

<script>
import mixinsChart from '../../mixins/charts.js'
export default {
  name: 'DataChartBar',
  mixins: [mixinsChart],
  computed: {
    chartDOM() {
      return this.$refs['data-chart-bar']
    },
    chartOptions() {
      const { colDesc, xGroupByDesc, groupByDesc, data = [], modelName } = this.chartData
      const xAxisData = Array.from(new Set(data?.map(item => item[xGroupByDesc?.colDesc]))) || []
      const [yGroupByDesc = {}] = groupByDesc.filter(item => !item.xaxis)

      yGroupByDesc.groupByValues = this.$parent.loadsh.uniq(data.map(item => {
        return item[yGroupByDesc.colDesc]
      }))
      xGroupByDesc.groupByValues = this.$parent.loadsh.uniq(data.map(item => {
        return item[xGroupByDesc.colDesc]
      }))

      const config = {
        title: {
          text: modelName
        },
        legend: {
          data: []
        },
        xAxis: {
          name: xGroupByDesc?.colDesc ? (`${xGroupByDesc?.colDesc.substr(0, 4).split('').join('\n')}${xGroupByDesc?.colDesc.length > 4 ? '\n···' : ''}`) : xGroupByDesc?.colDesc,
          data: xAxisData // 分组列数据
        },
        toolbox: {
          feature: {
            myFull: {
              onclick: () => {
                // 全屏查看
                if (this.$parent.$el.requestFullScreen) { // HTML W3C 提议
                  this.$parent.$el.requestFullScreen()
                } else if (this.$parent.$el.msRequestFullscreen) { // IE11
                  this.$parent.$el.msRequestFullScreen()
                } else if (this.$parent.$el.webkitRequestFullScreen) { // Webkit
                  this.$parent.$el.webkitRequestFullScreen()
                } else if (this.$parent.$el.mozRequestFullScreen) { // Firefox
                  this.$parent.$el.mozRequestFullScreen()
                }
                // 退出全屏
                if (this.$parent.$el.requestFullScreen) {
                  document.exitFullscreen()
                } else if (this.$parent.$el.msRequestFullScreen) {
                  document.msExitFullscreen()
                } else if (this.$parent.$el.webkitRequestFullScreen) {
                  document.webkitCancelFullScreen()
                } else if (this.$parent.$el.mozRequestFullScreen) {
                  document.mozCancelFullScreen()
                }
              }
            }
          }
        }
      }
      // 堆叠图
      if (groupByDesc.length === 2) {
        const arr = colDesc.map(colDescItem => {
          return yGroupByDesc.groupByValues.map(yGroupByDescItem => {
            const obj = {
              name: `${yGroupByDescItem || '/'}-${colDescItem}`,
              type: 'bar',
              stack: colDescItem,
              emphasis: {
                focus: 'series'
              },
              data: []
            }
            obj.data = xGroupByDesc.groupByValues.map(xGroupByDescItem => {
              let targetVal = null
              data.forEach(item => {
                if (item[yGroupByDesc.colDesc] === yGroupByDescItem && item[xGroupByDesc.colDesc] === xGroupByDescItem) {
                  targetVal = item[obj.stack]
                }
              })
              return targetVal
            })
            return obj
          })
        })
        config.series = arr.flat(Infinity)
        delete config.legend
      } else if (groupByDesc.length === 1) {
        config.series = colDesc.map(yItem => ({
          name: yItem,
          data: data.map(item => item[yItem]),
          type: 'bar',
          emphasis: {
            focus: 'series'
          }
        }))
        config.legend.data = colDesc
      }
      return this.$parent.merge(config)
    }
  }
}
</script>
