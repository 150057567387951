<template>
  <div
    v-show="isShow"
    :ref="initItem.ref"
    class="base-vue-box"
    :class="initItem.class"
    :style="initItem.style"
  >
    <div v-if="initItem.layoutLeft || initItem.leftText">
      <span :class="initItem.leftClass" :style="initItem.leftStyle">{{ initItem.leftText }}</span>
    </div>
    <span>{{ initItem.text }}</span>
    <div v-if="initItem.layoutRight || initItem.rightText">
      <span :class="initItem.rightClass" :style="initItem.rightStyle">{{ initItem.rightText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      initVal: null
    }
  },
  computed: {
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  }

}
</script>

<style lang="scss" scoped>
.base-vue-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .flex {
    flex: 1;
  }
}
</style>
