<template>
  <el-dialog
    :ref="initItem.ref"
    :title="initItem.title"
    :visible.sync="initItem.visible"
    :width="initItem.width"
    :center="initItem.center"
    :append-to-body="initItem.appendToBody"
    :before-close="handleClose"
  >
    <template v-if="initItem.content">
      <template v-for="it in initItem.content">
        <BaseIndexVue
          :key="it.id"
          :item="it"
          :root="root"
        />
      </template>
    </template>
    <template v-if="initItem.footer">
      <div slot="footer">
        <template v-for="it in initItem.footer">
          <BaseIndexVue
            :key="it.id"
            :item="it"
            :root="root"
          />
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    root: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      keyMap: {
        handleClose: 'close'
      }
    }
  },
  computed: {
    initItem() {
      return {...this.item, ...this.resetItem}
    }
  },
  methods: {
    activate(event, type = 'click') {
      console.log(type, '========')
      this.$emit('onActivate', {
        value: event,
        key: this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    },
    handleClose(done) {
      this.activate(done, 'handleClose')
    }
  }
}
</script>

