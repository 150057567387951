/*
 * @Author: your name
 * @Date: 2020-12-10 17:33:19
 * @LastEditTime: 2021-12-09 18:28:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/org/mutation.js
 */
import {GET_ORG_TREE} from '../../mutations-types'
import {GET_ORG_MANAGE_TREE} from '../../mutations-types'

export default {
  [GET_ORG_TREE] (state, res) {
    state.orgList = [res]
    state.factoryId = res.orgId
    state.factoryName = res.orgName
  },
  [GET_ORG_MANAGE_TREE] (state, res) {
    state.orgManageList = [res]
  },
}
