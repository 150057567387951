<template>
  <div class="content">
    <div>
      题目设置：{{ examType === 0? '分对错' : '不分对错' }}
    </div>
    <div>
      答题方式：<span>{{ answerType === 1? '随机答题（随机抽取'+randomExamCount+'道题目） ' : '顺序答题（所有题目）' }}</span>
    </div>
    <div class="exam-list">
      <el-table
        :data="examList"
        style="width: 100%;"
        stripe
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="title"
          fixed
          :label="'已选题目（共'+examList.length+'题）'"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.title | textFilter }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="category.title"
          align="center"
          label="分类"
        />
        <el-table-column
          prop="examTypeEnum"
          align="center"
          label="题目类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.examTypeEnum | examTypeFilter }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EntrustExam',
  components: {},
  filters: {
    textFilter(text) {
      return text.replace(/<filter><\/filter>/g, ' ______ ')
    },
    examTypeFilter(type) {
      switch (type) {
        case 'single':
          return '单选题'
        case 'multiple':
          return '多选题'
        case 'text_fill':
          return '问答题'
        case 'blank_fill':
          return '填空题'
        case 'sort':
          return '排序题'
        case 'scale':
          return '量表题'
      }
    },
    answerIndexFilter(i) {
      const arr = Array.from(new Array(26), (ele, index) => {
        return String.fromCharCode(65 + index)
      })
      return arr[i - 1]
    }
  },
  props: {
    examList: {
      type: Array,
      default: () => []
    },
    examType: {
      type: Number,
      default: 0
    },
    answerType: {
      type: Number,
      default: 0
    },
    randomExamCount: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {

    }
  },
  computed: {},
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang='scss' scoped>
.content {
  div {
    margin-bottom: 15px;
    color: #5a5a5a;
  }
}
.exam-list {
  margin-left: 60px;
}
</style>
