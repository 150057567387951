import TIM from 'tim-js-sdk'
// 发送图片、文件等消息需要腾讯云即时通信IM上传插件
import TIMUploadPlugin from 'tim-upload-plugin'
import eventListener from './eventListener'
import eventBus from '@/utils/eventBus'

export {
  TIM,
  TIMUploadPlugin,
  eventListener,
  eventBus
}