/*
 * @Author: your name
 * @Date: 2020-10-28 17:45:55
 * @LastEditTime: 2020-11-30 14:50:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/common/action.js
 */
import * as DownloadService from '@/services/downloadService'
import * as MessageService from '@/services/messageService'
import * as HelpService from '@/services/helpService'

import {
  GET_DOWNLOAD_FILE_LIST,
  GET_MESSAGE_LIST,
  READ_MESSAGE_STATUS,
  GET_INDEX_HELP_MESSAGE_LIST,
  INIT_MESSAGE_LIST,
  GET_INDEX_TOP_MESSAGE
  } from '../../mutations-types'
export default {
  getIndexTopMessage ({commit, state}, params) {
    return HelpService.getHelpMessageList (params).then(res=>{
      commit(GET_INDEX_TOP_MESSAGE, res.data.data)
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getIndexHelpMessageList ({commit, state}, params) {
    return HelpService.getHelpMessageList (params).then(res=>{
      commit(GET_INDEX_HELP_MESSAGE_LIST, res.data.data)
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getDownLoadFileList ({commit, state}, pageNo) {
    return DownloadService.getDownloadFileList(pageNo).then(res => {
      commit(GET_DOWNLOAD_FILE_LIST, res.data.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  exportMeetingData ({commit, state}, req) {
    return DownloadService.exportMeetingData(req).then(res => {
      return Promise.resolve(res.data)
    },rea=>{
      return Promise.reject(rea.message)
    })
  },
  onReadMessage ({commit, state},itemId) {
  return MessageService.onReadPlatform(itemId).then(res => {
    commit(READ_MESSAGE_STATUS, itemId)
    return Promise.resolve(res.data)
  }, rea => {
    return Promise.reject(rea.message)
  })
  },
  getMessageList ({commit, state}, pageNo) {
    return MessageService.getPlatformList(pageNo).then(res => {
      commit(GET_MESSAGE_LIST, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  initMessageList ({commit, state}, pageNo) {
    return MessageService.getPlatformList(pageNo).then(res => {
      commit(INIT_MESSAGE_LIST, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  getAllotMessage ({commit, state}, itemId) {
    return MessageService.getAllotMessage(itemId).then(res => {
      // commit()
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getTransformMessage ({commit, state}, itemId) {
    return MessageService.getTransformMessage(itemId).then(res => {
      // commit()
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  }
}