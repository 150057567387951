<template>
  <div ref="data-chart-funnel" class="data-chart-funnel" />
</template>

<script>
import mixinsChart from '../../mixins/charts.js'
// 图表解析
export default {
  name: 'DataChartFunnel',
  mixins: [mixinsChart],
  computed: {
    chartDOM() {
      return this.$refs['data-chart-funnel']
    },
    chartOptions() {
      const { colDesc, xGroupByDesc, modelName, data } = this.chartData

      const config = {
        title: {
          text: modelName
        },
        legend: {},
        series: colDesc.map(item => {
          return {
            name: item,
            type: 'funnel',
            left: '10%',
            width: '80%',
            label: {
              formatter: '{c}'
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              opacity: 0.7
            },
            emphasis: {
              label: {
                position: 'inside',
                formatter: `{b}${item}: {c}`
              }
            },
            data: data.map(yItem => ({
              name: yItem[xGroupByDesc.colDesc],
              value: yItem[item]
            }))
          }
        })
      }
      colDesc.length === 1 ? '' : config['legend']['data'] = colDesc
      return  this.$parent.merge(config)
    }
  }
}
</script>
