import Vue from 'vue'
// import router from '@/router/index'
const component = require('./index.vue').default
const Constructor = Vue.extend({
  // router, // 组件中需要使用路由跳转
  ...component
})

let instance
export const MessageDialog = options => {

  return new Promise((resolve, reject) => {
    options = options || {}
    instance = new Constructor({
      data: options,
      methods: {resolve, reject}
    })
    instance.vm = instance.$mount()
    instance.dom = instance.vm.$el
    document.body.appendChild(instance.dom)
    return instance.vm
  })
}

export default {
  install: Vue => {
    Vue.prototype[`$${component.name}`] = MessageDialog
  }
}
