import { deepClone } from '@/utils/util'

export default {
  invalidRoutes(state) {
    state.isGenerate = false
    state.headerActived = 0
  },
  setRoutes(state, routes) {
    state.isGenerate = true
    const newRoutes = deepClone(routes)
    state.routes = newRoutes.filter(item => {
      return item.children.length != 0
    })
  },
  setHeaderActived(state, path) {
    state.routes.map((item, index) => {
      if (
        item.children.some(r => {
          return path.indexOf(`${r.path  }/`) === 0 || path == r.path
        })
      ) {
        state.headerActived = index
      }
    })
  }
}
