// import * as MaterialService from '@/services/materialService'
import { deepClone } from '@/utils/util'

function hasPermission(permissions, route) {
  let isAuth = false
  if (route.meta && route.meta.auth) {
    isAuth = permissions.some(auth => {
      if (typeof route.meta.auth == 'string') {
        return route.meta.auth === auth
      } else {
        return route.meta.auth.some(routeAuth => {
          return routeAuth === auth
        })
      }
    })
  } else {
    isAuth = true
  }
  return isAuth
}

function filterAsyncRoutes(routes, permissions) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions)
        tmp.children.length && res.push(tmp)
      } else {
        res.push(tmp)
      }
    }
  })
  return res
}

// 将多层嵌套路由处理成平级
function flatAsyncRoutes(routes, breadcrumb, baseUrl = '') {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (tmp.children) {
      let childrenBaseUrl = ''
      if (baseUrl == '') {
        childrenBaseUrl = tmp.path
      } else if (tmp.path != '') {
        childrenBaseUrl = `${baseUrl}/${tmp.path}`
      }
      const childrenBreadcrumb = deepClone(breadcrumb)
      if (route.meta?.breadcrumb !== false) {
        childrenBreadcrumb.push({
          path: childrenBaseUrl,
          title: route.meta?.title
        })
      }
      const tmpRoute = deepClone(route)
      tmpRoute.path = childrenBaseUrl
      if (tmpRoute.meta) {
        tmpRoute.meta.breadcrumbNeste = childrenBreadcrumb
      }
      delete tmpRoute.children
      res.push(tmpRoute)
      const childrenRoutes = flatAsyncRoutes(tmp.children, childrenBreadcrumb, childrenBaseUrl)
      childrenRoutes.map(item => {
        // 如果 path 一样则覆盖，因为子路由的 path 可能设置为空，导致和父路由一样，直接注册会提示路由重复
        if (res.some(v => v.path == item.path)) {
          res.forEach((v, i) => {
            if (v.path == item.path) {
              res[i] = item
            }
          })
        } else {
          res.push(item)
        }
      })
    } else {
      if (baseUrl != '') {
        if (tmp.path != '') {
          tmp.path = `${baseUrl}/${tmp.path}`
        } else {
          tmp.path = baseUrl
        }
      }
      // 处理面包屑导航
      const tmpBreadcrumb = deepClone(breadcrumb)
      if (tmp.meta?.breadcrumb !== false) {
        tmpBreadcrumb.push({
          path: tmp.path,
          title: tmp.meta?.title
        })
      }
      tmp.meta.breadcrumbNeste = tmpBreadcrumb
      res.push(tmp)
    }
  })
  return res
}

export default {
  // 根据权限动态生成路由
  async generateRoutes({ dispatch, commit }, data) {
    const permissions = await dispatch('getPermission', null, { root: true })
    const accessedRoutes = filterAsyncRoutes(data.asyncRoutes, permissions)

    commit('setRoutes', accessedRoutes)
    commit('setHeaderActived', data.currentPath)
    const routes = []
    accessedRoutes.map(item => {
      routes.push(...item.children)
    })
    routes.map(item => {
      if (item.children) {
        item.children = flatAsyncRoutes(item.children, [{
          path: item.path,
          title: item.meta?.title
        }])
      }
    })
    return routes
  }
}
