<!--
 * @Author: your name
 * @Date: 2021-04-07 17:13:55
 * @LastEditTime: 2022-05-24 15:23:03
 * @LastEditors: haima haima@yaomaitong.cn
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/baseComponents/Editor.vue
-->
<template>
  <div :id="ueditorId" />
</template>

<script>
export default {
  name: 'Editor',
  model: {
    prop: 'initContent',
    event: 'change'
  },
  props: {
    context: String,
    initContent: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 400
    },
    ueditorId: {
      type: String,
      default: 'qxs-ueditor'
    }
  },
  data() {
    return {
      editor: '',
      ue: null,
      ueStatus: {
        ready: false,
        inited: false
      },
      toolbars: [
        ['bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'forecolor', 'backcolor', 'formatmatch', '|', 'justifyleft', 'justifycenter', 'justifyright', '|', 'insertorderedlist', 'insertunorderedlist', 'rowspacingtop', 'rowspacingbottom', 'lineheight'],
        ['insertimage',  'link', 'paragraph', 'fontfamily', 'fontsize', 'preview', 'source']
      ]
    }
  },
  watch: {
    initContent() {
      this.ueStatus.inited = false
      this.initiateConent()
    }
  },
  beforeDestroy() {
    if (this.ue) {
      this.ue.destroy()
    }
  },
  mounted() {
    window.UEDITOR_CONFIG.serverUrl = `${process.env.VUE_APP_GATEWAY_QXS}/common/v1/`
    this.initUeditor()
    this.initiateConent()
  },
  methods: {
    getValue() {
      if (!this.ueStatus.ready) {
        return this.$props.initContent
      } else {
        if (this.ue.getContent().indexOf('</div>') > -1 && this.ue.getContent().indexOf('16px') > -1) {
          return this.ue.getContent()
        }
        const body = document.createElement('div')
        body.style.fontSize = '16px'
        body.innerHTML = this.ue.getContent()
        return body.outerHTML
      }
    },
    clearValue() {
      this.ue.setContent('', false)
    },
    initiateConent() {
      if (this.ueStatus.ready && !this.ueStatus.inited && this.$props.initContent) {
        this.ueStatus.inited = true
        this.ue.setContent(this.$props.initContent)
      }
    },
    onSetContent() {
      this.ue.setContent(this.$props.initContent)
    },
    initUeditor() {
      this.ue = window.UE.getEditor(this.ueditorId, {
        toolbars: this.toolbars,
        initialFrameHeight: Math.min(this.$props.height, window.screen.height / 2),
        initialFrameWidth: '100%',
        elementPathEnabled: false,
        scaleEnabled: false,
        autoHeightEnabled: false
      })

      this.ue.ready(() => {
        this.ueStatus.ready = true
        this.initiateConent()
        this.ue.addListener('catchremotebegin', () => {})

        this.ue.addListener('catchremotesuccess', () => {})

        this.ue.addListener('catchremoteerror', () => {})
        this.ue.addListener('contentchange', () => {
          if (this.ueStatus.inited) {
            // this.$emit('change', this.ue.getContent());
          }

          this.$emit('update:context', this.ue.getContent())
        })

        // 初始化context
        if (this.context) {
          this.ue.setContent(this.context)
        }
      })
    }
  }
}
</script>

<style >
.w-e-text-container {
  height: 800px!important;
}
</style>
