<template>
  <el-table class="ksh-table" v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-table>
</template>

<script>
export default {
  name: 'KshTable'
}
</script>

<style scoped lang="scss">
// scss
.ksh-table {
    ::v-deep th{
        opacity: 0.6;
    }
}
</style>
