/*
 * @Author: your name
 * @Date: 2020-11-25 21:09:23
 * @LastEditTime: 2021-01-26 18:05:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/doctor/mutation.js
 */

import {
  GET_DOCTOR_LIST,
  GET_SPEAKER_LIST,
  GET_HOSPITAL_LIST,
  GET_DOCTOR_SCENE_SATA_LIST,
  GET_DOCTOR_SCENE_SUMMARY,
  UPDATE_DOCTOR_SIGN_STATUS,
  GET_TAG_COVER_DOCTOR_LIST,
  GET_TAG_DOCTOR_GRADE_CHART,
  GET_TAG_COVER_DOCTOR_SUMMARY,
  GET_FACTORY_COVER_DOCTOR_SUMMARY,
  GRADED_TAG_COVER_DOCTOR,
  REJECT_TAG_COVER_DOCTOR,
  GET_DOCTOR_LIVE_VIDEO_LIST,
  UPDATE_DOCTOR_CODE_STATUS,
  UPDATE_SPEAKER_CODE_STATUS
  } from '../../mutations-types'

  export default {

    [GET_DOCTOR_LIST] (state, list) {
      state.doctorList = list
    },
    [GET_HOSPITAL_LIST] (state, list) {
      state.hospitalList = list
    },
    [GET_SPEAKER_LIST] (state, list) {
      state.speakerList = list
    },
    [UPDATE_DOCTOR_SIGN_STATUS] (state, p) {
      state.doctorList = [...state.doctorList].map(item => {
        if (item.id === p.id) {
          item = {...item, speakerSignStatus: p.speakerSignStatus}
        }
        return item
      })
      let updateSpeaker = false
      state.speakerList = [...state.speakerList].map(item => {
        if (item.id === p.id) {
          updateSpeaker = true
          item = {...item, speakerSignStatus: p.speakerSignStatus}
        }
        return item
      })
      if (!updateSpeaker) {
        state.speakerList = [p,...state.speakerList]
      }
    },
    [UPDATE_DOCTOR_CODE_STATUS] (state, p) {
      state.doctorList = [...state.doctorList].map(item => {
        if (item.id === p.doctorId) {
          item = {...item, code: p.code}
        }
        return item
      })
    },
    [UPDATE_SPEAKER_CODE_STATUS] (state, p) {
      state.speakerList = [...state.speakerList].map(item => {
        if (item.id === p.doctorId) {
          item = {...item, code: p.code}
        }
        return item
      })
    },
    [GET_TAG_COVER_DOCTOR_SUMMARY] (state, res) {
      state.tagCoverDoctor = res.allDoctorCount || 0
      state.tagCoverLevelDoctor = res.levelDoctorCount || 0
      state.tagWillCoverLevelDoctor = res.willLevelDoctorCount || 0
    },
    [GET_FACTORY_COVER_DOCTOR_SUMMARY] (state, res) {
      state.factoryCoverDoctorSummaryData = res
    },
    [GET_TAG_COVER_DOCTOR_LIST] (state, res) {
      state.tagCoverDoctorList = res
    },
    [GET_TAG_DOCTOR_GRADE_CHART] (state, res) {
      state.tagCoverDoctorChartData = res
    },
    [GRADED_TAG_COVER_DOCTOR] (state, res) {
      state.tagCoverDoctorList = [...state.tagCoverDoctorList].map(v=>{
        if (v.id === res.id) {
          v = {...v,status: 1,doctorLevelType: res.doctorLevelType}
        }
        return  v
      })
    },
    [REJECT_TAG_COVER_DOCTOR] (state, id) {
      state.tagCoverDoctorList = [...state.tagCoverDoctorList].filter(v=>v.id!==id)
    },
    [GET_DOCTOR_SCENE_SUMMARY] (state, data) {
      state.doctorSceneCodeSummary = data
    },
    [GET_DOCTOR_SCENE_SATA_LIST] (state, list) {
      state.doctorSceneData = list
    },
    [GET_DOCTOR_LIVE_VIDEO_LIST] (state, list) {
      state.doctorLiveVideoList = list
    }
  }
