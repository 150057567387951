import request from '@/utils/request'
import API from '@/apis/api-types'

export function getOrgAccountAmount({ orgIdList = [], type = 1 }) {
  // req => {orgId, accountType }
  // accountType 1 积分 2 直播服务费
  return request({
    url: API.getOrgAccountAmount,
    method: 'post',
    data: { orgIdList, type }
  })
}

export function getOrgAccountList(accountType = 1) {
  // accountType 1 积分  2 直播服务费
  return request({
    method: 'get',
    url: API.getOrgAccountList,
    params: { accountType }
  })
}

export function getOrgAccountChildrenList(data) {
  return request({
    method: 'get',
    url: API.getOrgAccountChildrenList,
    params: data
  })
}

export function onRecallUserAccount(req) {
  // params {childOrgId, giftCount}
  return request({
    url: API.onRecallUserAccount,
    method: 'get',
    params: req
  })
}

export function onRecallLiveAccount(req) {
  // params {childOrgId, amount}
  return request({
    url: API.onRecallLiveAccount,
    method: 'get',
    params: req
  })
}

export function getAccountLiveSummary() {
  // 账户直播汇总
  return request({
    url: API.getAccountLiveSummary,
    method: 'get'
  })
}

export function getAccountUserSummary() {
  return request({
    url: API.getAccountUserSummary,
    method: 'get'
  })
}

export function getAccountBusinessList(req) {
  return request({
    url: API.getAccountBusinessList,
    method: 'get',
    params: req
  })
}

export function getAccountLiveBusinessList(req) {
  return request({
    url: API.getAccountLiveBusinessList,
    method: 'get',
    params: req
  })
}

export function getLiveBusinessListDown(req) {
  return request({
    url: API.getLiveBusinessListDown,
    method: 'get',
    params: req
  })
}

export function getAccountLiveList(req) {
  return request({
    url: API.getAccountUserList,
    method: 'get',
    params: req
  })
}

export function getAccountUserList(req) {
  return request({
    url: API.getAccountUserList,
    method: 'get',
    params: req
  })
}

export function getAccountUserChildrenList(req) {
  return request({
    url: API.getAccountUserChildrenList,
    method: 'get',
    params: req
  })
}

export function downloadAccountCollectList(req) {
  return request({
    url: API.downloadAccountCollectList,
    method: 'get',
    params: req
  })
}

export function downloadAllDetailList(req) {
  return request({
    url: API.downloadAllDetailList,
    method: 'get',
    params: req
  })
}
export function downloadDelegationAllCollectList(req) {
  return request({
    url: API.downloadDelegationAllCollectList,
    method: 'get',
    params: req
  })
}

export function onDownAccountData(req) {
  return request({
    url: API.onDownAccountData,
    method: 'get',
    params: req
  })
}

export function onTransferAccountUser(req) {
  return request({
    url: API.onTransferAccountUser,
    method: 'post',
    data: req
  })
}

export function onTransferAccountLive(req) {
  // 财务模块 直播转账
  return request({
    url: API.onTransferAccountLive,
    method: 'get',
    params: req
  })
}

export function onExportAccountLivePayData(req) {
  // 财务模块 直播转账
  return request({
    url: API.onExportAccountLivePayData,
    method: 'get',
    params: req
  })
}

export function onPayLiveAccount(req) {
  // 财务模块 直播转账
  return request({
    url: API.onPayLiveAccount,
    method: 'get',
    params: req
  })
}

export function getAccountLivePayDetail(req) {
  return request({
    url: API.getAccountLivePayDetail,
    method: 'get',
    params: req
  })
}

export function onReviewLiveAccount(req) {
  return request({
    url: API.onReviewLiveAccount,
    method: 'get',
    params: req
  })
}

// ----------------------- 收支明细 任务 ------------------

export function getTrainCode(options) {
  return request({
    url: API.getTrainCode,
    method: 'get',
    params: options
  })
}

export function getTrainDetail(options) {
  return request({
    url: API.getTrainDetail,
    method: 'get',
    params: options
  })
}

export function getWithdrawList(options) {
  return request({
    url: API.getWithdrawList,
    method: 'get',
    params: options
  })
}

export function getWithdrawMoney(options) {
  return request({
    url: API.getWithdrawMoney,
    method: 'get',
    params: options
  })
}

export function accountCodeList(options) {
  // 进行中的任务，table数据
  return request({
    url: API.accountCodeList,
    method: 'get',
    params: options
  })
}

export function callBackCodeDetail(options) {
  // 撤回任务，统计数据
  return request({
    url: API.callBackCodeDetail,
    method: 'get',
    params: options
  })
}

export function callBackCodeList(options) {
  // 撤回任务，table数据
  return request({
    url: API.callBackCodeList,
    method: 'get',
    params: options
  })
}

export function sceneCodeOverview(options) {
  // 撤回任务，任务面额list
  return request({
    url: API.sceneCodeOverview,
    method: 'get',
    params: options
  })
}

export function cashDetailList(options) {
  // 积分账户明细列表
  return request({
    url: API.cashDetailList,
    method: 'get',
    params: options
  })
}

export function commonConvertCode(options) {
  // 撤回任务提交 ---- 代表、厂家Tab
  return request({
    url: API.commonConvertCode,
    method: 'post',
    data: options
  })
}

export function trainWithdrawCode(options) {
  return request({
    url: API.trainWithdrawCode,
    method: 'post',
    data: options
  })
}

// 查询积分摘要列表
export function getSourceList(options) {
  return request({
    url: API.getSourceList,
    method: 'get',
    params: options
  })
}

// 场景列表
export function getSceneList(options) {
  return request({
    url: API.getSceneList,
    method: 'get',
    params: options
  })
}

// 厂家业务明细下载
export function getDetailDown(options) {
  return request({
    url: API.getDetailDown,
    method: 'get',
    params: options
  })
}

// 校验org是否设置了管理员
export function getIsOrgSetAdmin(options) {
  return request({
    url: API.getIsOrgSetAdmin,
    method: 'get',
    params: options
  })
}

// 校验org是否设置了管理员
export function getAccountFactorySetting(params) {
  return request({
    url: API.getAccountFactorySetting,
    method: 'get',
    params
  })
}

export function updateAccountFactorySetting({ uOrgId, ...data }) {
  return request({
    url: API.updateAccountFactorySetting,
    method: 'post',
    data,
    params: { uOrgId }
  })
}

export function downBatchPayLiveFee(data) {
  return request({
    url: API.downBatchPayLiveFee,
    method: 'get',
    params: data
  })
}
export function confirmBatchPayLiveFee(data) {
  return request({
    url: API.confirmBatchPayLiveFee,
    method: 'get',
    params: data
  })
}
