
import axios from '@/utils/request.js'
import API from '@/apis/api-types.js'

const state = {
  // 数据元类型，非必传，默认查所有，1 条件运算 2 聚合运算 3排序方式 4分组
  operatorMap: {}
}
const getters = {
  operatorList: state => type => {
    return state.operatorList[type] || []
  }
}
const actions = {
  async getOperatorList({ commit }, params) {
    try {
      if (state.operatorMap[params.type]) return state.operatorMap[params.type]
      const { data } = await axios.get(API.getRequestSqlMemberList, { params })
      commit('setOperatorMap', {type: params.type, data})
      return data
    } catch (error) {
      console.log('error: ', error)
    }
  }
}

const mutations = {
  setOperatorMap(state, {type, data}) {
    state.operatorMap[type] = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
