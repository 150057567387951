/*
 * @Author: your name
 * @Date: 2021-07-27 11:32:31
 * @LastEditTime: 2021-07-27 13:48:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/material/index.js
 */
import actions from './action'
import mutations from './mutation'
const state = {
  knowledgePointList: [],
  knowledgePointEnable: false
}
const getters = {
  knowledgePointList: state => state.knowledgePointList,
  knowledgePointEnable: state => state.knowledgePointEnable
}
export default {
  state,
  getters,
  actions,
  mutations
}