const BrowserLogger = require('@arms/js-sdk')
import { getToken } from '@/utils/auth'

let __bl = null
export function getEnv() {
  let env = 'prod'
  const domain = window.location.host
  if (domain.indexOf('localhost') > -1) {
    env = 'local'
  } else if (/factory-dev/g.test(domain)) {
    env = 'daily'
  } else if (domain.indexOf('factory.yaomaitong-pre.cn') > -1) {
    env = 'pre'
  }
  return env
}
export function parseUid(token = getToken()) {
  let uid = ''
  if (token) {
    const tokenStr = token.split('_')
    uid = tokenStr[1] || ''
  }
  return uid
}

export function setUid(uid) {
  if (!__bl) return
  __bl.setConfig({
    uid
  })
}

export function setUserType(userType) {
  if (!__bl) return
  __bl.setConfig({
    tag: userType
  })
}

export function loadARMS() {
  if (!__bl) {
    __bl = BrowserLogger.singleton(
      {
        pid: 'b7z525alis@053c55381774d2c',
        appType: 'web',
        imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
        sendResource: true,
        enableLinkTrace: true,
        behavior: true,
        enableSPA: true,
        environment: getEnv()
      }
    )
  }
  return __bl
}
