/*
 * @Author: your name
 * @Date: 2020-10-22 14:02:44
 * @LastEditTime: 2023-03-06 10:12:18
 * @LastEditors: fangteng fangteng@yaomaitong.cn
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/store/mutations-types.js
 */

// 页面状态
export const REQUEST_DOING = 'REQUEST_DOING'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_FAILED = 'REQUEST_FAILED'
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
// 用户权限
export const GET_USER_PERMISSION_LIST = 'GET_USER_PERMISSION_LIST'
// 组织列表
export const GET_ORG_LIST = 'GET_ORG_LIST'
export const GET_ORG_ACCOUNT_TREE = 'GET_ORG_ACCOUNT_TREE'
export const GET_ORG_TREE = 'GET_ORG_TREE'
export const GET_ORG_MANAGE_TREE = 'GET_ORG_MANAGE_TREE'
export const GET_TEAM_LIST = 'GET_TEAM_LIST'
// 消息中心
export const GET_MESSAGE_LIST = 'GET_MESSAGE_LIST'
export const INIT_MESSAGE_LIST = 'INIT_MESSAGE_LIST'
export const READ_MESSAGE_STATUS = 'READ_MESSAGE_STATUS'
// 帮助中心
export const GET_HELP_NAV_LIST = 'GET_HELP_NAV_LIST'
export const INIT_HELP_MESSAGE_LIST = 'INIT_HELP_MESSAGE_LIST'
export const GET_HELP_MESSAGE_LIST = 'GET_HELP_MESSAGE_LIST'
export const GET_HELP_MESSAGE_DETAIL = 'GET_HELP_MESSAGE_DETAIL'
export const ON_HATE_MESSAGE_DETAIL = 'ON_HATE_MESSAGE_DETAIL'
export const ON_LIKE_MESSAGE_DETAIL = 'ON_LIKE_MESSAGE_DETAIL'
export const ON_READ_HELP_MESSAGE = 'ON_READ_HELP_MESSAGE'
export const MODULE_TYPE = 'MODULE_TYPE'
// 下载中心
export const GET_DOWNLOAD_FILE_LIST = 'GET_DOWNLOAD_FILE_LIST'
export const UPDATE_DOWNLOAD_FILE = 'UPDATE_DOWNLOAD_FILE'

// 首页
export const GET_INDEX_HELP_MESSAGE_LIST = 'GET_INDEX_HELP_MESSAGE_LIST'
export const GET_INDEX_TOP_MESSAGE = 'GET_INDEX_TOP_MESSAGE'
export const GET_USER_GUIDE_CONFIG = 'GET_USER_GUIDE_CONFIG' //  新手引导配置

// 标签
export const GET_TAG_GROUP_LIST = 'GET_TAG_GROUP_LIST'
export const GET_TAG_LIST = 'GET_TAG_LIST'
export const UPDATE_TAG = 'UPDATE_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const ADD_TAG = 'ADD_TAG'

//医生
export const GET_FACTORY_COVER_DOCTOR_SUMMARY = 'GET_FACTORY_COVER_DOCTOR_SUMMARY' // 医生汇总信息
export const GET_TAG_COVER_DOCTOR_SUMMARY = 'GET_TAG_COVER_DOCTOR_SUMMARY' // 厂家端查看产品线已分级未分级医生总数
export const GET_TAG_COVER_DOCTOR_LIST = 'GET_TAG_COVER_DOCTOR_LIST' // 厂家端查看产品线已分级未分级医sheng列表
export const GET_DOCTOR_LIST = 'GET_DOCTOR_LIST'
export const GET_SPEAKER_LIST = 'GET_SPEAKER_LIST'
export const GET_HOSPITAL_LIST = 'GET_HOSPITAL_LIST'
export const UPDATE_DOCTOR_SIGN_STATUS = 'UPDATE_DOCTOR_SIGN_STATUS'
export const UPDATE_DOCTOR_CODE_STATUS = 'UPDATE_DOCTOR_CODE_STATUS'
export const UPDATE_SPEAKER_CODE_STATUS = 'UPDATE_SPEAKER_CODE_STATUS'
export const GET_TAG_DOCTOR_GRADE_CHART = 'GET_TAG_DOCTOR_GRADE_CHART' //  产品下分级医生图表
export const GRADED_TAG_COVER_DOCTOR = 'UPDATE_TAG_COVER_DOCTOR_STATUS' // 分级客户
export const REJECT_TAG_COVER_DOCTOR = 'REJECT_TAG_COVER_DOCTOR' // 驳回客户
export const UPDATE_LIVE_PAY_SHOW_STATUS = 'UPDATE_LIVE_PAY_SHOW_STATUS'
export const GET_DOCTOR_SCENE_SATA_LIST = 'GET_DOCTOR_SCENE_SATA_LIST'
export const GET_DOCTOR_SCENE_SUMMARY = 'GET_DOCTOR_SCENE_SUMMARY'
export const GET_DOCTOR_LIVE_VIDEO_LIST = 'GET_DOCTOR_LIVE_VIDEO_LIST'
//用户
export const GET_WX_CODE_IMAGE = 'GET_WX_CODE_IMAGE'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_METADATA_USER_INFO = 'SET_METADATA_USER_INFO'
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION'
export const SET_FACTORY_ACCOUNT_LIST = 'SET_FACTORY_ACCOUNT_LIST'
export const ON_CHECK_FACTORY_ACCOUNT = 'ON_CHECK_FACTORY_ACCOUNT'
export const CHECK_PAYMENT_CODE_IMAGE = 'CHECK_PAYMENT_CODE_IMAGE'
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO'
export const SET_LOGIN_RECORD = 'SET_LOGIN_RECORD'
export const SET_SENSITIVE_RECORD = 'SET_SENSITIVE_RECORD'
export const UPDATE_ORG_USER_ACCOUNT = 'UPDATE_ORG_USER_ACCOUNT'
export const UPDATE_ORG_LIVE_ACCOUNT = 'UPDATE_ORG_LIVE_ACCOUNT'
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'

// 通用
export const GET_HOSPITAL_DEPARTMENT_LIST = 'GET_HOSPITAL_DEPARTMENT_LIST'
export const GET_JOB_TITLE_LIST = 'GET_JOB_TITLE_LIST'
export const GET_PROVINCE_LIST = 'GET_PROVINCE_LIST'
export const GET_HOSPITAL_LEVEL_LIST = 'GET_HOSPITAL_LEVEL_LIST'
export const SET_PERMISSION_ROLES = 'SET_PERMISSION_ROLES'
export const UPDATE_HOSPITAL_EDIT = 'UPDATE_HOSPITAL_EDIT'

// account  账户区块
  // 账户直播
export const GET_ACCOUNT_LIVE_SUMMARY = 'GET_ACCOUNT_LIVE_SUMMARY'
export const GET_ACCOUNT_LIVE_LIST = 'GET_ACCOUNT_LIVE_LIST'
  // 账户总积分
export const GET_ACCOUNT_USER_SUMMARY = 'GET_ACCOUNT_USER_SUMMARY'
export const GET_ACCOUNT_USER_LIST = 'GET_ACCOUNT_USER_LIST'
export const GET_ACCOUNT_BUSINESS_LIST = 'GET_ACCOUNT_BUSINESS_LIST'

// 账户转账列表
export const GET_ACCOUNT_USER_TRANSFER_LIST = 'GET_ACCOUNT_USER_TRANSFER_LIST'
export const TRANSFER_USER_ACCOUNT = 'TRANSFER_USER_ACCOUNT'
export const RECALL_USER_ACCOUNT = 'RECALL_USER_ACCOUNT'
/// 直播转账列表
export const GET_ACCOUNT_LIVE_TRANSFER_LIST = 'GET_ACCOUNT_LIVE_TRANSFER_LIST'
export const TRANSFER_LIVE_ACCOUNT = 'TRANSFER_LIVE_ACCOUNT'
export const RECALL_LIVE_ACCOUNT = 'RECALL_LIVE_ACCOUNT'
// 账户下直播详情
export const GET_ACCOUNT_LIVE_PAYMENT_DETAIL = 'GET_ACCOUNT_LIVE_PAYMENT_DETAIL'
export const PAY_LIVE_ACCOUNT = 'PAY_LIVE_ACCOUNT'
export const ACCOUNT_LIVE_REVIEW = 'ACCOUNT_LIVE_REVIEW' // 直播账户提交审核

// ------------------------ 收支明细 邀请码 --------------------------
export const INVITE_CODE_TABLE = "INVITE_CODE_TABLE";  // 进行中的邀请码，table数据
export const WITHDRAW_CODE_TABLE = "WITHDRAW_CODE_TABLE";  // 进行中的邀请码，table数据
export const SET_CALL_BACK_CODE_DETAIL = "SET_CALL_BACK_CODE_DETAIL"; // 撤回邀请码 统计数据
export const GET_WITHDRAW_MONEY = "GET_WITHDRAW_MONEY";
export const RECALL_CODE_TABLE = "RECALL_CODE_TABLE";  // 撤回邀请码，table数据
export const RECALL_DIALOG_LIST = "RECALL_DIALOG_LIST"; // 撤回邀请码，邀请码面额list
export const RECALL_DIALOG_TABLE = "RECALL_DIALOG_TABLE";  // 撤回邀请码，撤回table数据
export const CASH_DETAIl_TABlE = "CASH_DETAIl_TABlE";  // 积分账户明细Table
// ------------------------ 收支明细 任务 end ----------------------

// 直播模块
export const GET_LIVE_PREVIEW_QR_CODE = 'GET_LIVE_PREVIEW_QR_CODE' // 获取直播预览二维码
export const SET_SPEAKERR_EXE = 'SET_SPEAKERR_EXE'

// ------------------------------ 课程培训 ----------------------
export const SET_TRAIN_CLASSIFY_LIST = "SET_TRAIN_CLASSIFY_LIST";   // 代表培训内容分类
export const CREAT_TRAIN_CLASSIFY_LIST = "CREAT_TRAIN_CLASSIFY_LIST";   // 创建代表培训内容分类
export const REMOVE_TRAIN_CLASSIFY_LIST = "REMOVE_TRAIN_CLASSIFY_LIST";   // 删除代表培训内容分类
export const SET_TRAIN_COURSE_LIST = "SET_TRAIN_COURSE_LIST";    // 厂家端课程列表
export const ADD_TRAIN_COURSE_ITEM = "ADD_TRAIN_COURSE_ITEM";    // 厂家端课程列表
export const DELETE_TRAIN_COURSE_ITEM = "DELETE_TRAIN_COURSE_ITEM";    // 厂家端课程列表
export const UPDATE_TRAIN_COURSE_ITEM = "UPDATE_TRAIN_COURSE_ITEM";    // 厂家端课程列表
export const SET_TRAIN_PROGRAM_LIST = "SET_TRAIN_PROGRAM_LIST";    // 厂家端课程列表
export const ADD_TRAIN_PROGRAM_ITEM = "ADD_TRAIN_PROGRAM_ITEM";    // 厂家端课程列表
export const DELETE_TRAIN_PROGRAM_ITEM = "DELETE_TRAIN_PROGRAM_ITEM";    // 厂家端课程列表
export const UPDATE_TRAIN_PROGRAM_ITEM = "UPDATE_TRAIN_PROGRAM_ITEM";    // 厂家端课程列表
export const UPDATE_TRAIN_PROGRAM_LIST = "UPDATE_TRAIN_PROGRAM_LIST";    // 厂家端课程列表
// ------------------------------ 课程培训 end ----------------------

// 活动管理
export const GET_ACADEMIC_SCENE_LIST = 'GET_ACADEMIC_SCENE_LIST' // 活动管理列表
export const GET_DOWN_ORG_LIST = 'GET_DOWN_ORG_LIST' // 下级活动管理列表
export const DELETE_ACADEMIC_SCENE_ITEM = 'DELETE_ACADEMIC_SCENE_ITEM' // 删除活动列表内条目
export const UPDATE_ACADEMIC_SCENE_ITEM = 'UPDATE_ACADEMIC_SCENE_ITEM'
export const ADD_ACADEMIC_SCENE_ITEM = 'ADD_ACADEMIC_SCENE_ITEM'
export const ADD_ACADEMIC_SCENE_CODE_COUNT = 'ADD_ACADEMIC_SCENE_CODE_COUNT' // 追加活动任务分配数
export const RECALL_ACADEMIC_SCENE_CODE_COUNT = 'RECALL_ACADEMIC_SCENE_CODE_COUNT'
export const UPDATE_ACADEMIC_SCENE_TYPE = 'UPDATE_ACADEMIC_SCENE_TYPE'
export const UPDATE_ACADEMIC_SOURCE_TYPE = 'UPDATE_ACADEMIC_SOURCE_TYPE'
//素材管理
export const GET_KNOWLEDGE_POINT_LIST = 'GET_KNOWLEDGE_POINT_LIST'
export const GET_KNOWLEDGE_POINT_ENABLE = 'GET_KNOWLEDGE_POINT_ENABLE'
