<template>
  <div
    v-show="isShow"
    class="page-main"
    :class="initItem.class"
    :style="initItem.style"
  >
    <slot name="title">
      <div v-if="initItem.title" class="title-containe-wrap">
        <div v-if="initItem.back" class="back-btn" @click="$router.back()"><svg-icon class="svg-icon" name="el-icon-arrow-left" />返回</div>
        <div class="title-text-wrap">
          <div class="left-title">
            <span v-if="titleType === 'string'">{{ initItem.title }}</span>
            <span v-else-if="titleType === 'boolean'">{{ titlePath }}</span>
          </div>
          <div class="title-right">
            <slot name="title-right-container">
              {{ initItem.titleRightContainer }}
            </slot>
          </div>
        </div>
      </div>
    </slot>
    <div v-if="initItem.title" class="top-wrap">
      <slot name="top" />
    </div>
    <template v-for="it in initItem.childSchemeList">
      <BaseIndexVue
        :key="it.id"
        :item="it"
        :root="root"
        :fixed="it.fixed"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'BasePageMain',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    root: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      titlePath: this.titlePathFn()
    }
  },
  computed: {
    titleType() {
      return typeof this.initItem.title
    },
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  mounted() {
    this.watcher(this.$route.meta, 'title', () => {
      if (Object.keys(this.$route.query).length) {
        this.$route.matched[this.$route.matched.length - 1].query = this.$route.query
      }
      this.titlePath = this.titlePathFn()
    })
  },
  methods: {
    titlePathFn() {
      const { matched } = this.$route
      return matched
        .map(item => {
          return item.meta?.title?.trim()
        })
        .join(' - ')
    },
    watcher(obj, name, callback) {
      let value = obj[name]
      Object.defineProperty(obj, name, {
        set: function(e) {
          value = e
          callback && callback(e)
        }.bind(this),
        get: function() {
          return value
        }.bind(this)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-main {
  position: relative;
  margin: $g-main-wrap-margin;
  background-color: #fff;
  padding: $g-main-wrap-padding;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  .title-containe-wrap {
    height: 36px;
    margin-left: -$g-main-wrap-padding;
    margin-right: -$g-main-wrap-padding;
    margin-top: -$g-main-wrap-padding;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .back-btn {
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #5a5a5a;
      transition: all 0.3s;
      position: relative;
      border-right: 1px solid #eee;
      // &::after {
      //   content: '';
      //   width: 1px;
      //   height: 12px;
      //   position: absolute;
      //   right: 0;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   background-color: #ddd;
      // }
      &:hover {
        color: #555;
        background-color: #ddd;
      }
      .svg-icon {
        margin-right: 3px;
      }
    }
    .title-text-wrap {
      flex: 1;
      margin-left: $g-main-wrap-padding;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    .left-title {
      font-weight: 600;
      color: #0f0f0f;
      line-height: 36px;
    }
  }
  .top-wrap {
    min-height: 1px;
    margin-top: -$g-main-wrap-padding;
    margin-left: -$g-main-wrap-padding;
    margin-right: -$g-main-wrap-padding;
    margin-bottom: $g-main-wrap-padding;
  }
}
</style>
