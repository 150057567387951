<template>
  <div
    v-show="isShow"
    :ref="initItem.ref"
    class="base-vue-box"
    :class="initItem.class"
    :style="initItem.style"
  >
    <div v-if="initItem.layoutLeft || initItem.leftText">
      <span :class="initItem.leftClass" :style="initItem.leftStyle">{{ initItem.leftText }}</span>
    </div>
    <el-select
      :ref="initItem.ref"
      v-model="value"
      class="flex"
      :class="initItem.selectClass"
      :style="initItem.selectStyle"
      :clearable="initItem.clearable"
      :placeholder="initItem.placeholder"
      :disabled="initItem.disabled"
      :multiple="initItem.multiple"
      :filterable="initItem.filterable"
      :allow-create="initItem.allowCreate"
      :default-first-option="initItem.defaultFirstOption"
      :collapse-tags="initItem.collapseTags"
      @change="activate($event,'change')"
    >
      <template v-for="it in initItem.selectList">
        <el-option
          :key="it.value"
          :label="it.label"
          :value="it.value"
          :disabled="it.disabled"
        />
      </template>
    </el-select>
    <div v-if="initItem.layoutRight || initItem.rightText">
      <div
        :class="initItem.rightClass"
        :style="initItem.rightStyle"
        @click.stop.prevent="activate($event,'click')"
      >
        <svg-icon name="manage-icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      initVal: null,
      keyMap: {
        change: 'select'
      }
    }
  },
  computed: {
    value: {
      get() {
        if (this.initVal === '') {
          return this.initVal
        }
        return this.initVal  || this.initItem.value
      },
      set(val) {
        this.initVal = val
      }
    },
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  watch: {
    ['initItem.value']() {
      this.value = this.initItem.value
    }
  },
  methods: {
    activate(event, type = 'change') {
      this.$emit('onActivate', {
        value: this.value,
        key: this.item.key || this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.base-vue-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .flex {
    flex: 1;
    ::v-deep .el-input__inner {
      background: #fafafa;
    }
  }
}
.select-complex {
  display: flex;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  background-color: #fbfbfb;
  transition: all 0.3s;
  &:hover {
    border-color: #c0c4cb;
  }
  &:focus {
    border-color: $color-primary;
  }
  ::v-deep .select {
    line-height: 26px;
    .el-input {
      .el-input__inner {
        height: 26px;
        line-height: 26px;
        background-color: transparent;
        border: 1px solid transparent;
        border-right: none;
      }
    }
  }
  .right-btn {
    width: 28px;
    line-height: 26px;
    text-align: center;
    color: #a3a3a3;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 12px;
      background-color: #e1e1e1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }
}
</style>
