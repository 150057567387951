<template>
  <el-dialog class="novice-strategy" :visible.sync="dialogVisible">
    <div class="strategy">
      <div class="left">
        <img :src="require('@/assets/common/guide_remark.png')" alt="">
      </div>
      <div class="right">
        <h1>趣学术新手引导攻略</h1>
        <p class="context">
          趣学术，秉持严谨合规的态度，追求高效率的探索精神，持续推动真正的学术推广。
          以“趣味”互动和“数智”赋能为手段，为药企提供医药信息快速传递、拓展影响面和提升运营效率的综合平台。
        </p>
        <ul class="article">
          <li v-for="item in userGuideConfig.items" :key="item.url" class="article-item">
            <el-link :href="item.url" type="primary" target="_blank">{{ item.title }}</el-link>
          </li>
        </ul>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'

export default {
  name: 'NoviceStrategy',
  props: {
    value: Boolean
  },
  computed: {
    ...mapGetters(['userGuideConfig']),
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.getUserGuideConfig()
  },
  methods: {
    ...mapActions(['getUserGuideConfig'])
  }
}
</script>

<style lang="scss" scoped>
.novice-strategy {
  ::v-deep .el-dialog {
    width: 1000px;
    height: 500px;
    background: top left / 100% 100% no-repeat url('~@/assets/common/user_guide_bg.png');
    border-radius: 18px;
    overflow: hidden;
    .el-dialog__header {
      background-color: transparent;
      border-bottom: none;
      .el-dialog__headerbtn i {
        font-size: 28px;
        color: #aabfdf;
      }
    }
    .el-dialog__body {
      height: calc(100% - 52px);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .strategy {
      display: flex;
      padding: 0 50px;
      padding-bottom: 30px;
      .left {
        img {
          height: 300px;
        }
      }
      .right {
        margin-left: 30px;
        h1 {
          font-size: 36px;
          color: rgb(0, 0, 0);
          font-weight: bold;
        }
        .context {
          margin: 25px 0;
        }
        .article {
          list-style: none;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .article-item {
            position: relative;
            padding: 10px 0;
            padding-left: 25px;
            &::before {
              content: '';
              width: 16px;
              height: 16px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%) rotateZ(45deg);
              background-color: #3d61e3;
              border: 1px solid #3d61e3;
              box-sizing: border-box;
              background-clip: content-box;
              padding: 1px;
            }
          }
        }
      }
    }
  }
}
</style>
