<template>
  <ElImageViewer v-if="value" v-bind="$attrs" :url-list="urlList" :initial-index="initialIndex" :on-close="closeViewer" />
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'ImageViewer',
  components: { ElImageViewer },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    src: {
      type: String
    },
    urlList: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    initialIndex() {
      return this.urlList.indexOf(this.src)
    }
  },
  methods: {
    /**
     * 更新 v-model 的值
     * 调用时机：关闭图片预览
     */
    closeViewer() {
      this.$emit('input', false)
    }
  }
}
</script>
