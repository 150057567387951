<template>
  <div class="layout">
    <div id="app-main" :style="{ '--real-sidebar-width': realSidebarWidth }">
      <header>
        <slot name="header" />
      </header>

      <div class="wrapper-layout">
        <div class="show sidebar-container">
          <div class="sub-sidebar-container " @scroll="onSidebarScroll">
            <slot name="menu" />
          </div>
        </div>
        <div class="main-container">
          <div class="main">
            <transition name="main" mode="out-in">
              <slot name="main">
                <!-- <keep-alive v-if="isRouterAlive" :include="$store.state.keepAlive.list"> -->
                <RouterView :key="$route.path" />
                <!-- </keep-alive> -->
              </slot>
            </transition>
          </div>
        </div>
        <el-backtop
          :right="5"
          :bottom="5"
          title="回到顶部"
          style="z-index: 11;"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      sidebarScrollTop: 0,
      scrollTop: 0,
      dialogVisible: false,
      collapse: 'expand',
      onChangeSideBarThrottled: Function
    }
  },
  computed: {
    realSidebarWidth() {
      return '140px'
    }
  },
  watch: {
    collapse(newVal) {
      sessionStorage.setItem('collapse', newVal)
    }
  },
  mounted() {
    const storageCollapseVal = sessionStorage.getItem('collapse')
    if (storageCollapseVal === 'null' || sessionStorage.getItem('collapse') == null) {
      sessionStorage.setItem('collapse', this.collapse)
    }
    if (window.innerWidth < 500) {
      sessionStorage.setItem('collapse', null)
    }
    this.collapse = sessionStorage.getItem('collapse')

    window.addEventListener('scroll', this.onScroll)

    if (!localStorage.getItem('userGuide')) {
      // 第一次打开新手用户 默认打开
      this.dialogVisible = true

      localStorage.setItem('userGuide', true)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    reload(type = 1) {
      if (type == 1) {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      } else {
        this.$router.push({
          name: 'reload'
        })
      }
    },
    routeChange(newVal, oldVal) {
      if (newVal.name == oldVal.name) {
        this.reload()
      }
    },
    onSidebarScroll(e) {
      this.sidebarScrollTop = e.target.scrollTop
    },
    onScroll() {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
}
#app-main {
  width: 100%;
  min-width: 1200px;
  height: inherit;
  margin: 0 auto;
  padding-top: $g-header-height;
  transition: all 0.2s;
}
header {
  width: 100vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: $g-header-height;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #eee;
}
.wrapper-layout {
  position: relative;
  width: 100%;
  height: 100%;
  .sidebar-container {
    position: fixed;
    z-index: 1001;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: $g-sidebar-width;
    transition: 0.3s width ease-in-out, 0.3s padding-left ease-in-out, 0.3s padding-right ease-in-out;
  }
  .sub-sidebar-container {
    background-color: $g-sub-sidebar-bg;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 40px;
    box-shadow: inset -1px 0 0 0 #eee;
    padding-right: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    // firefox隐藏滚动条
    scrollbar-width: none;
    // chrome隐藏滚动条
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin-left: $g-sidebar-width;
    background-color: #f5f7f9;
    transition: 0.3s margin-left ease-in-out, 0.3s padding-left ease-in-out, 0.3s padding-right ease-in-out;
    .main {
      height: 100%;
      flex: auto;
      position: relative;
      overflow: hidden;
    }
  }
}
header + .wrapper-layout {
  .sidebar-container {
    top: $g-header-height;
    .sidebar-logo {
      display: none;
    }
  }
  .main-container {
    .topbar-container {
      top: $g-header-height;
      ::v-deep .user {
        display: none;
      }
    }
  }
}

// 侧边栏收起时的样式
::v-deep .el-menu--collapse {
  .el-menu-item,
  .el-submenu {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .el-tooltip,
    .el-submenu__title {
      padding: 0 15px !important;
      > span,
      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
  .el-submenu.is-active > .el-submenu__title {
    color: $g-sub-sidebar-menu-active-color !important;
    background-color: $g-sub-sidebar-menu-active-bg !important;
  }
}

// 主内容区动画
.main-enter-active {
  transition: all 0.2s;
}
.main-leave-active {
  transition: all 0.15s;
}
.main-enter {
  opacity: 0;
  margin-left: -20px;
}
.main-leave-to {
  opacity: 0;
  margin-left: 20px;
}
</style>
