/*
 * @Author: your name
 * @Date: 2020-11-25 21:09:16
 * @LastEditTime: 2022-03-22 19:55:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/doctor/action.js
 */
import * as DoctorService from '@/services/doctorService'
import {
  GET_DOCTOR_LIST,
  GET_SPEAKER_LIST,
  GET_HOSPITAL_LIST,
  UPDATE_DOCTOR_SIGN_STATUS,
  GET_TAG_COVER_DOCTOR_SUMMARY,
  GET_FACTORY_COVER_DOCTOR_SUMMARY,
  GET_TAG_COVER_DOCTOR_LIST,
  GRADED_TAG_COVER_DOCTOR,
  REJECT_TAG_COVER_DOCTOR,
  GET_TAG_DOCTOR_GRADE_CHART,
  GET_DOCTOR_SCENE_SUMMARY,
  GET_DOCTOR_SCENE_SATA_LIST,
  GET_DOCTOR_LIVE_VIDEO_LIST,
  UPDATE_DOCTOR_CODE_STATUS,
  UPDATE_SPEAKER_CODE_STATUS
} from '../../mutations-types'

export default {
  setDoctorCertifyRule({ commit, state }, req) {
    return DoctorService.setDoctorCertifyRule(req).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getDoctorCertifyRule({ commit, state }, req) {
    return DoctorService.getDoctorCertifyRule(req).then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getAccountVideoList({ commit, state }, req) {
    // return DoctorService.getAccountVideoList(req).then(res => {
    //   commit(GET_ACCOUNT_VIDEO_LIST, res.data)
    //   return Promise.resolve(res)
    // })
  },
  getSpeakerList({ commit, state }, req) {
    return DoctorService.getSpeakerList(req).then(
      res => {
        commit(GET_SPEAKER_LIST, res.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getRepresentOrgList({ commit, state }, req) {
    return DoctorService.getRepresentOrgList(req).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getDoctorList({ commit, state }, pageNo) {
    return DoctorService.getDoctorList(pageNo).then(
      res => {
        commit(GET_DOCTOR_LIST, res.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getHospitalList({ commit, state }, pageNo) {
    return DoctorService.getHospitalList(pageNo).then(
      res => {
        commit(GET_HOSPITAL_LIST, res.data?.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getDoctorAuthCert({ commit, state }, doctorId) {
    return DoctorService.getDoctorAuth(doctorId).then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  inviteDoctorCertify({ commit, state }, doctorId) {
    return DoctorService.inviteDoctorCertify(doctorId).then(
      res => {
        // commit(UPDATE_DOCTOR_LIST, item)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onSignUpDoctor({ commit, state }, item) {
    return DoctorService.onSignUpDoctor(item.id).then(
      res => {
        commit(UPDATE_DOCTOR_SIGN_STATUS, { ...item, speakerSignStatus: 1 })
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  unSignUpDoctor({ commit, state }, doctorId) {
    return DoctorService.unSignUpDoctor(doctorId).then(
      res => {
        commit(UPDATE_DOCTOR_SIGN_STATUS, { id: doctorId, speakerSignStatus: 0 })
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onUploadExcelDoctor({ commit, state }, req) {
    return DoctorService.onUploadExcelDoctor(req).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getDoctorImportRealAuthCount({ commit, state }) {
    return DoctorService.getDoctorImportRealAuthCount().then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.resolve(rea)
      }
    )
  },
  onSetDoctorRealName({ commit, state }, arr) {
    return DoctorService.onSetDoctorRealName(arr).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getDoctorSummary({ commit, state }) {
    return DoctorService.getDoctorSummary().then(
      res => {
        commit(GET_FACTORY_COVER_DOCTOR_SUMMARY, res.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getTagCoverDoctor({ commit, state }, tagId) {
    return DoctorService.getTagCoverDoctor(tagId).then(
      res => {
        commit(GET_TAG_COVER_DOCTOR_SUMMARY, res.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getTagCoverDoctorList({ commit, state }, req) {
    return DoctorService.getTagCoverDoctorList(req).then(
      res => {
        commit(GET_TAG_COVER_DOCTOR_LIST, res.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  setGradeDoctor({ commit, state }, req) {
    return DoctorService.setGradeDoctor(req).then(
      res => {
        if (req.onlyUpdate) {
          commit(GRADED_TAG_COVER_DOCTOR, req)
        } else {
          // 未分级客户进行筛选
          commit(REJECT_TAG_COVER_DOCTOR, req.id)
        }
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getTagCoverDoctorChartData({ commit, state }, tagId) {
    return DoctorService.getTagCoverDoctorChartData(tagId).then(
      res => {
        commit(GET_TAG_DOCTOR_GRADE_CHART, res.data.chartItemList)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getTagDoctorHistory({ commit, state }, doctorId) {
    return DoctorService.getTagDoctorHistory(doctorId).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  // 医生个人数据相关
  getDoctorSceneSummary({ commit, state }, doctorId) {
    return DoctorService.getDoctorSceneSummary(doctorId).then(
      res => {
        commit(GET_DOCTOR_SCENE_SUMMARY, res.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getDoctorSceneStatList({ commit, state }, req) {
    return DoctorService.getDoctorSceneStatList(req).then(
      res => {
        commit(GET_DOCTOR_SCENE_SATA_LIST, res.data.data)
        return Promise.resolve(res.data)
      },
      rea => {
        commit(GET_DOCTOR_SCENE_SATA_LIST, [])
        return Promise.reject(rea)
      }
    )
  },
  getDoctorLiveVideoList({ commit, state }, req) {
    return DoctorService.getDoctorLiveVideoList(req).then(
      res => {
        commit(GET_DOCTOR_LIVE_VIDEO_LIST, res.data)
        return Promise.resolve(res)
      },
      rea => {
        commit(GET_DOCTOR_LIVE_VIDEO_LIST, [])
        return Promise.reject(rea)
      }
    )
  },
  getDoctorMeetingAnswerInfo({ commit, state }, req) {
    return DoctorService.getDoctorMeetingAnswerInfo(req).then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getDoctorLiveAnswerInfo({ commit, state }, req) {
    return DoctorService.getDoctorLiveAnswerInfo(req).then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  departmentQuery(_, data) {
    return DoctorService.departmentQuery(data).then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  saveDoctorCode({ commit, state }, data) {
    return DoctorService.saveDoctorCode(data).then(
      res => {
        commit(UPDATE_DOCTOR_CODE_STATUS, data)
        commit(UPDATE_SPEAKER_CODE_STATUS, data)
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getSpeakerListDown({ commit, state }, req) {
    return DoctorService.getSpeakerListDown(req).then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  }
}
