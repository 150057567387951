import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { dealCache } from './featuresFn'
import { getToken, clearPersonInfo } from '@/utils/auth'
// 水印
import warterMark from '@/utils/warter-mark.js'
import { createDriver } from '@/utils/driver'

Vue.use(VueRouter)

// 基础路由
import Layout from '@/layout'
import other from './module/other'

// 基础路由
const constantRoutes = [{
  path: '/user/login',
  name: 'UserLogin',
  component: () => import(/* webpackChunkName: "BaseRoute" */'@/views/user/UserLogin'),
  meta: {
    requiresAuth: false,
    showWarter: false,
    title: '登录'
  }
},
{
  path: '/user',
  name: 'User',
  component: Layout,
  redirect: '/index',
  meta: {
    title: '用户中心',
    sidebar: false,
    breadcrumb: false
  },
  children: [
    {
      path: '/doctor/certify/set',
      name: 'DoctorCertifySetRule',
      component: () => import(/* webpackChunkName: "User"*/ '@/views/doctor/DoctorCertifySetRule.vue'),
      meta: {
        requiresAuth: true,
        title: '更多设置'
      }
    },
    {
      path: '/safe/center',
      name: 'SafeCenter',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/AccountCenter'),
      meta: {
        requiresAuth: true,
        title: '账号安全'
      }
    }, {
      path: '/change/pwd',
      name: 'ChangePwd',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/ChangePwd'),
      meta: {
        requiresAuth: true,
        title: '修改密码'
      }
    }, {
      path: '/change/mobile',
      name: 'ChangeMobile',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/ChangeMobile'),
      meta: {
        requiresAuth: true,
        title: '更换手机号'
      }
    }, {
      path: '/change/wechat',
      name: 'ChangeWechat',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/ChangeWechat'),
      meta: {
        requiresAuth: true,
        title: '更换微信'
      }
    }, {
      path: '/change/password',
      name: 'ChangePassword',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/ChangePassword'),
      meta: {
        requiresAuth: true,
        title: '通过手机号修改密码'
      }
    }, {
      path: '/select/factory',
      name: 'SelectFactory',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/SelectFactory.vue'),
      meta: {
        requiresAuth: true,
        title: '切换账号'
      }
    }, {
      path: '/bind/mobile',
      name: 'BindMobile',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/BindMobile'),
      meta: {
        requiresAuth: true,
        title: '绑定手机'
      }
    }, {
      path: '/bind/wechat',
      name: 'BindWechat',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/BindWechat'),
      meta: {
        requiresAuth: true,
        title: '绑定微信'
      }
    }, {
      path: '/payment/setting',
      name: 'PaymentSetting',
      component: () => import(/* webpackChunkName: "User" */'@/views/user/PaymentSetting'),
      meta: {
        requiresAuth: true,
        title: '修改支付限额'
      }
    },
    {
      path: '/recoverystation',
      name: 'RecoveryStation',
      component: () => import(/* webpackChunkName: "User" */ '@/views/user/RecoveryStation.vue'),
      meta: {
        title: '回收站'
      }
    },
    {
      path: 'reload',
      name: 'reload',
      component: () => import(/* webpackChunkName: "BaseRoute" */'@/views/reload')
    }]
},
...other]

// TODO: 趣学术
// 直播频道
import visit from './module/visit'
import index from './module/index'
import account from './module/account'
import download from './module/download'
import pangu from './module/pangu'
import customerCRM from './module/customer-crm'
import academicSaas from './module/academic-saas'
import stategic from './module/strategic'
import v3 from './module/v3'

// TODO: 帮助中心
import Help from './help'

const asyncRoutes = [
  {
    meta: {
      title: '趣学术'
    },
    children: [
      index,
      customerCRM,
      academicSaas,
      stategic,
      pangu,
      account,
      download,
      visit,
      ...v3
    ]
  }, {
    meta: {
      title: '帮助中心'
    },
    children: [Help]
  }
]

// 404 页面
const lastRoute = [{
  path: '*',
  component: () => import('@/views/404'),
  meta: {
    title: '404',
    sidebar: false
  }
}]

const router = new VueRouter({
  mode: 'history',
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// 解决路由 push/replace 相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

// 路由守卫
router.beforeEach(async(to, from, next) => {
  store.dispatch('setModuleType', to.name)
  NProgress.start()
  // 已经登录但没有动态注册路由
  if (getToken() && !store.state.menu.isGenerate) {
    /**
     * 重置 matcher
     * https://blog.csdn.net/baidu_28647571/article/details/101711682
     */
    router.matcher = new VueRouter({
      routes: constantRoutes,
      mode: 'history'
    }).matcher

    const accessRoutes =  await store.dispatch('menu/generateRoutes', {
      asyncRoutes,
      currentPath: to.path
    }) || []
    accessRoutes.push(...lastRoute)
    accessRoutes.forEach(route => {
      router.addRoute(route)
    })

    router.history.setupListeners()
    next({ ...to, replace: true })
  }

  // 处理缓存
  await dealCache(to, from, next, store)

  if (getToken()) {
    if (to.name) {
      if (to.matched.length !== 0) {
        // 如果已登录状态下，进入登录页面会强制跳转到控制台页面
        if (to.name === 'UserLogin') {
          next({ path: '/index', replace: true })
        }
      } else {
        // 如果是通过 name 跳转，并且 name 对应的路由没有权限时，需要手动指向到 404 页面
        next({ path: '/404' })
      }
    }
  } else {
    if (to.name !== 'UserLogin') {
      clearPersonInfo('login')
      next({
        path: '/user/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }
  next()
})

router.afterEach((to, form) => {
  if (form.meta.showWarter !== false) {    // 判断是否为登录页面
    warterMark.set(`${store.state.user.userInfo.login}`)
  } else {
    warterMark.set('')
  }
  setTimeout(() => {
    if (to.meta.driverObj) {
      const driver = createDriver(to.meta.driverObj)
      driver?.drive()
    }
  }, 500)
  NProgress.done()
})

export default router
