<template>
  <div class="tag-layout">
    <template v-for="v in tagList">
      <el-tag :key="v.tagId" :closable="!disabledIds.includes(v.tagId)" @close="onDelTag(v)">
        {{ v.tagName }}
      </el-tag>
      <template v-for="item in v.knowledgePointInfos">
        <el-tooltip
          :key="item.knowledgePointId"
          effect="dark"
          :content="v.tagName + ': ' + item.knowledgePointName"
          placement="top"
        >
          <el-tag
            :closable="!disabledIds.includes(item.knowledgePointId)"
            class="tag-name"
            @close="onDelTagKnowledge(v, item)"
          >
            <span>{{ v.tagName }}: {{ item.knowledgePointName }}</span>
          </el-tag>
        </el-tooltip>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProductLabelKnowledge',
  props: {
    disabledIds: {
      type: Array,
      default: () => []
    },
    selectedTagList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    tagList: {
      get() {
        return [...this.selectedTagList]
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    onDelTag(item) {
      if (this.disabledIds.includes(item.tagId))
        return this.$message.error('已经添加的标签不支持删除')
      this.tagList = this.tagList.filter(v => v.tagId != item.tagId)
    },
    onDelTagKnowledge(tag, knowledge) {
      for (const item of this.tagList) {
        if (item.tagId === tag.tagId) {
          item.knowledgePointIds = item.knowledgePointIds?.filter(
            v => v != knowledge.knowledgePointId
          )
          item.knowledgePointInfos = item.knowledgePointInfos?.filter(
            v => v.knowledgePointId != knowledge.knowledgePointId
          )
        }
      }
      this.$emit('change', this.tagList)
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
  line-height: 28px;
  padding: 7px 0;
  .tag-name {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    vertical-align: middle;
  }
}
</style>
