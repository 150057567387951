<template>
  <el-input v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'KshInput',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  created() {},
  methods: {}
}
</script>

<style scoped lang="scss">
// scss
    ::v-deep input,::v-deep textarea {
        border-radius: 1px;
        font-size: 12px;
        &::placeholder {
          color:#B4B4B4;
          font-weight: normal;
        }
    }
    ::v-deep .el-input--small {
        border-radius: 1px;
        height: 30px;
        line-height: 30px;

    }
    ::v-deep .el-input__inner, ::v-deep .el-textarea__inner {

      color: #0F0F0F;
      font-weight: bold;
    }
</style>
