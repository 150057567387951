// 数据导出模块
import request from '@/utils/request'
import API from '@/apis/api-types'
export function getDownloadFileList(pageNo = 1, pageSize = 20) {
  return request({
    method: 'get',
    url: API.getDownloadFileList,
    params: {pageNo, pageSize}
  })
}

export function exportMeetingData(data) {
  return request({
    method: 'post',
    url: API.exportMeetingData,
    data: data
  })
}
export function clientExe() {
  return request({
    method: 'get',
    url: API.clientExe
  })
}
