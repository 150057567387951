/*
 * @Author: your name
 * @Date: 2020-11-25 21:09:16
 * @LastEditTime: 2021-01-12 10:20:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/doctor/action.js
 */

import * as UserService from '@/services/userService'
import * as DownloadService from '@/services/downloadService'
import * as Types from '../../mutations-types'
import { setUid } from '@/utils/ali-log'

export default {
  getWxCodeImage ({commit, state},params) {
    return UserService.getWxCodeImage(params).then(res => {
      commit(Types.GET_WX_CODE_IMAGE,res?.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  checkWxCodeResult ({commit, state}, params) {
    return UserService.checkWxCodeResult(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  userLogin ({commit, state}, params) {
    return UserService.userLogin(params).then(res => {
			commit(Types.SET_USER_INFO,res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  userSetUserInfo({commit},data){
    commit(Types.SET_USER_INFO,data)
  },
  userMobileLogin ({commit, state}, params) {
    return UserService.userMobileLogin(params).then(res => {
      let {data} = res
      if(data.orgLoginVo){
        commit(Types.SET_USER_INFO,data.userInfo)
      }
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  getPermission ({commit, state}) {
    return UserService.getPermission().then(res => {
      let data = res?.data||[]
      commit(Types.SET_USER_PERMISSION,data)
      return Promise.resolve(data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  getSmsCode ({commit, state},params) {
    return UserService.getSmsCode(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  getFactoryAccountList ({commit, state},params) {
    return UserService.getFactoryAccountList(params).then(res => {
      commit(Types.SET_FACTORY_ACCOUNT_LIST,res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  onCheckFactoryAccount ({commit, state},item) {
		commit(Types.ON_CHECK_FACTORY_ACCOUNT,item)
	},
  setNormalAccount ({commit, state},params) {
		return UserService.setNormalAccount(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  getPaymentCodeImage ({commit, state},params) {
		return UserService.getWxCodeImage(params).then(res => {
      commit(Types.CHECK_PAYMENT_CODE_IMAGE,res.data)
      return Promise.resolve(res.data)
    }, rea => {
      commit(Types.CHECK_PAYMENT_CODE_IMAGE, [])
      return Promise.reject(rea)
    })
	},
	checkPaymentCodeResult ({commit, state}, params) {
    return UserService.checkWxCodeResult(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
	getAccountInfo ({commit, state}, params) {
    return UserService.getAccountInfo(params).then(res => {
      commit(Types.SET_ACCOUNT_INFO,res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
	getAccountScore ({commit, state}, params) {
    return UserService.getAccountScore(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
	getLoginRecord ({commit, state}, params) {
    return UserService.getLoginRecord(params).then(res => {
      commit(Types.SET_LOGIN_RECORD,res.data.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
	getSensitiveRecord ({commit, state}, params) {
    return UserService.getSensitiveRecord(params).then(res => {
      commit(Types.SET_SENSITIVE_RECORD,res.data.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
	getAccountsRoles ({commit, state}) {
    return UserService.getAccountsRoles().then(res => {
      res.data = res.data.map(item => {
        return {
          id: item.roleId,
          name: item.roleName
        }
      })
      commit(Types.SET_PERMISSION_ROLES,res.data)
    })
  },
  bindMobile ({commit, state}, params) {
    return UserService.bindMobile(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  changeMobile ({commit, state}, params) {
    return UserService.changeMobile(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  changePwd ({commit, state}, params) {
    return UserService.changePwd(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  setNewPassword ({commit, state}, params) {
    return UserService.setNewPassword(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  paymentCheckValue ({commit, state}, params) {
    return UserService.paymentCheckValue(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  paymentLimit ({commit, state}, params) {
    return UserService.paymentLimit(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
	},
  checkCaptcha ({commit, state}, params) {
    return UserService.checkCaptcha(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  bindWechat ({commit, state}, params) {
    return UserService.bindWechat(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getClientExe ({commit, state}, ) {
    return DownloadService.clientExe().then(res => {
      commit(Types.SET_SPEAKERR_EXE,res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  logout({commit},place) {
      commit('REMOVE_USER_DATA',place)
      commit('menu/invalidRoutes', null, {root: true})
      setUid('')
  }
}
