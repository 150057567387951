import Vue from 'vue'
import ElementUI, { Message } from 'element-ui'
import '@/styles/element-variables.scss'
Vue.use(ElementUI, {
  size: 'mini'
})

const throttleMessage = (() => {
  const lastInvocations = new Map()

  return (type, options) => {
    if (!options) return
    const msg = typeof options === 'string' ? options : options.message
    const key = JSON.stringify({ message: msg, type })
    const now = Date.now()

    // 默认节流时间为2秒
    const throttleTime = 2000

    if (!lastInvocations.has(key) || now - (lastInvocations.get(key) || 0) > throttleTime) {
      lastInvocations.set(key, now)
      if (typeof options === 'string') {
        Message[type]({ message: options })
      } else {
        // 直接将options对象传递给AntMessage
        Message[type](options)
      }
    }
  }
})()

// 定义useMessage函数
export function useMessage() {
  const showMessage = {
    open: options => {
      throttleMessage('open', options)
    },
    info: options => throttleMessage('info', options),
    success: options => throttleMessage('success', options),
    error: options => throttleMessage('error', options),
    warning: options => throttleMessage('warning', options),
    loading: options => throttleMessage('loading', options)
  }

  return showMessage
}

// 将$message挂载到this上
Vue.prototype.$message = Message
Vue.prototype.$msg = useMessage()
