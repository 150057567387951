/*
 * @Author: your name
 * @Date: 2020-12-10 23:26:38
 * @LastEditTime: 2021-12-09 18:38:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/common/accout/index.js
 */
import actions from './action'
import mutations from './mutation'
const state = {
  accountUserList: [], // 账户用户列表
  accountUserSummaryData: {},
  accountLivePayData: [],// 账户直播详情数据
  accountLiveList: [], // 账户直播列表
  accountLiveSummaryData: {},
  accountUserTransferList:[],
  accountLiveTransferList:[],
  orgAccountTreeList: [],
  accountBusinessList: [],
  // ------------------------ 收支明细 邀请码 --------------------------
  inviteCodeTable: [],  // 进行中的邀请码，table数据
  withdrawCodeTable: [],  // 培训进行中的邀请码，table数据
  callBackCodeDetail: {}, // 撤回邀请码 统计数据
  withdrawMoney: {}, // 撤回邀请码 统计数据
  recallCodeTable: [],  // 撤回邀请码，table数据
  recallDialogList: [],  // 撤回邀请码，邀请码面额list
  recallDialogTable: [],  // 撤回邀请码，撤回table数据
  cashDetailTable:[], // 积分账户明细Table
  // ------------------------ 收支明细 任务 end ----------------------

}
const getters = {
  orgAccountTreeList: () => state.orgAccountTreeList,
  accountLiveList: () => state.accountLiveList,
  accountLiveSummaryData: () => state.accountLiveSummaryData,
  accountUserList: () => state.accountUserList,
  accountUserSummaryData: ()=> state.accountUserSummaryData,
  accountUserTransferList: ()=>state.accountUserTransferList,
  accountLiveTransferList: () => state.accountLiveTransferList,
  accountBusinessList: () =>state.accountBusinessList,
  accountLivePayData: () => state.accountLivePayData,
  // ------------------------ 收支明细 邀请码 --------------------------
  inviteCodeTable: (state) => state.inviteCodeTable,  // 进行中的邀请码，table数据
  withdrawCodeTable: (state) => state.withdrawCodeTable,  // 培训进行中的邀请码，table数据
  callBackCodeDetail: (state) => state.callBackCodeDetail, // 撤回邀请码 统计数据
  withdrawMoney: (state) => state.withdrawMoney, // 撤回邀请码 统计数据
  recallCodeTable: (state) => state.recallCodeTable,  // 撤回邀请码，table数据
  recallDialogList: (state) => state.recallDialogList,  // 撤回邀请码，邀请码面额list
  recallDialogTable: (state) => state.recallDialogTable,  // 撤回邀请码，撤回table数据
  cashDetailTable: (state) => state.cashDetailTable,  // 积分账户明细Table
  // ------------------------ 收支明细 任务 end ----------------------
}
export default {
  state,
  getters,
  actions,
  mutations
}
