import Service from '@/utils/request'
import Api from './api-types'
import { Message } from 'element-ui'

function dataOperation(data, outData, dataMaps, isFile = false) {
  const obj = !isFile ? {} : new FormData()
  try {
    const outDataKeys = Object.keys(outData)
    Object.keys(data).forEach(key => {
      let res = null
      if (typeof dataMaps[key] === 'function') {
        res = dataMaps[key](outData)
      } else if (typeof dataMaps[key] === 'object') {
        const { name, fun } = dataMaps[key] || {}
        const mapKey = name || key
        const val = fun ? fun(outData[mapKey], outData) : outData[mapKey]
        res = outDataKeys.includes(mapKey) ? val : data[key]
      } else {
        const mapKey = dataMaps[key] || key
        res = outDataKeys.includes(mapKey) ? outData[mapKey] : data[key]
      }
      if (!isFile) {
        obj[key] = res
      } else {
        obj.append(key, res)
      }
    })
  } catch {
    Message.error('请检查接口参数格式是否正确')
  }
  return obj
}
/**
 * options: 接口配置信息{
 *                      apiPath: 接口URL
 *                      api:接口-字符串，
 *                      method:请求方式,
 *                      data: 请求体参数; 函数类型: obj: 外部传入数据 => { ... return newObj},
 *                      params: url参数；函数类型: obj: 外部传入数据 => { ... return newObj},
 *                      dataMaps: 参数映射
 *                        类型1对象: {接口参数key: 外部传入key1}
 *                        类型2对象: {
 *                                接口参数key: {
 *                                    name: 外部传入key1
 *                                    fun: val:外部传入key1的值 => { ... return newVal}
 *                                }
 *                              }
 *                      ,
 *                      result: 返回额外结果信息-对象,
 *                      headers:请求头-对象,
 *                      file: 文件对象
 *                      resultFns: 传入的结果处理函数对象{success:成功处理，error:失败处理}
 *                    }
 * outData: 传入参数对象/数组
 *
 *
 * 返回: promise
 * */
export function requireApi(options, outData) {
  const reqConfig = []
  const {
    apiPath,
    api,
    method = 'get',
    headers,
    data,
    file,
    params,
    dataMaps = {},
    result = {},
    resultFns
  } = options
  try {
    if (outData) {
      if (data) {
        let reqData = null
        if (Object.prototype.toString.call(data) === '[object Function]') {
          reqData = data(outData)
        } else if (Object.prototype.toString.call(data) === '[object Array]') {
          reqData = outData
        } else {
          reqData = dataOperation(data, outData, dataMaps)
        }
        reqConfig[0] = reqData
      }
      if (params) {
        const ind = method === 'get' ? 0 : 1
        if (Object.prototype.toString.call(params) === '[object Function]') {
          reqConfig[ind] = params(outData)
        } else {
          reqConfig[ind] = {params: dataOperation(params, outData, dataMaps)}
        }
      }
      if (file) {
        reqConfig[0] = dataOperation(data, outData, dataMaps, true)
      }
    }
    if (headers) {
      reqConfig[1].headers = headers
    }
    return Service[method](Api[api] || apiPath, ...reqConfig).then(res => {
      const resVal = { ...res, ...result }
      resultFns?.success && resultFns.success.call(this, resVal)
      return Promise.resolve(resVal)
    }, rea => {
      const reaVal = {...rea, ...result}
      resultFns?.error && resultFns.error.call(this, reaVal)
      return Promise.reject(reaVal)
    })
  } catch {
    Message.error('请检查接口配置对象是否正确')
  }

}
