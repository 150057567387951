<template>
  <el-tabs
    v-show="isShow"
    :ref="initItem.ref"
    v-model="value"
    :class="initItem.class"
    :style="initItem.style"
    :type="initItem.type"

    :tab-position="initItem.tabPosition"
    @tab-click="activate($event, 'tabClick')"
  >
    <template v-for="it in initItem.tabList">
      <el-tab-pane
        :key="it.name"
        :label="it.label"
        :name="it.name"
        :disabled="initItem.disabled"
      >
        <template v-for="tabItem in it.slot">
          <BaseIndexVue
            :key="tabItem.id"
            :item="{...tabItem,parentData: it}"
            :root="root"
          />
        </template>
      </el-tab-pane>
    </template>
  </el-tabs>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    root: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      initVal: null,
      keyMap: {
        tabClick: 'tabClick'
      }
    }
  },
  computed: {
    value: {
      get() {
        if (this.initVal === '') {
          return this.initVal
        }
        return this.initVal  || this.initItem.value
      },
      set(val) {
        this.initVal = val
      }
    },
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  watch: {
    ['initItem.value']() {
      this.value = this.initItem.value
    }
  },
  methods: {
    activate(event, type = 'tabClick') {
      this.$emit('onActivate', {
        value: this.value,
        key: this.item.key || this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    }
  }

}
</script>

