import Layout from '@/layout'

export default {
  path: '/visit',
  name: 'Visit',
  redirect: '/visit/list',
  component: Layout,
  meta: {
    title: '拜访管理',
    icon: 'baifang',
    auth: ['visitNav']
  },
  children: [
    {
      path: 'list',
      name: 'VisitList',
      component: () => import(/* webpackChunkName: "Visit" */ '@/views/visit/VisitList.vue'),
      meta: {
        title: '拜访管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/visit',
        cache: ['VisitItemDetail', 'LiveApprovalDetail']
      }
    },
    {
      path: 'detail/:id',
      name: 'VisitItemDetail',
      component: () => import(/* webpackChunkName: "Visit" */ '@/views/visit/VisitDetail.vue'),
      meta: {
        title: '拜访详情',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/visit'
      }
    },
    {
      path: 'setting',
      name: 'VisitSetting',
      component: () => import(/* webpackChunkName: "Visit" */ '@/views/visit/VisitSetting.vue'),
      meta: {
        title: '拜访设置',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/visit'
      }
    }

  ]
}
