<template>
  <div ref="data-chart-scatter-simple" class="data-chart-scatter-simple" />
</template>

<script>
import mixinsChart from '../../mixins/charts.js'
export default {
  name: 'DataChartScatterSimple',
  mixins: [mixinsChart],
  computed: {
    chartDOM() {
      return this.$refs['data-chart-scatter-simple']
    },
    chartOptions() {
      const { colDesc, xGroupByDesc, modelName, data } = this.chartData
      const config = {
        title: {
          text: modelName
        },
        tooltip: {
          formatter: function(params) {
            if (params.value.length > 1) {
              return (
                `${params.seriesName
                } :<br/>${
                  params.value[0]
                } ${
                  params.value[1]
                } `
              )
            } else {
              return (
                `${params.seriesName
                } :<br/>${
                  params.name
                } : ${
                  params.value
                } `
              )
            }
          }
        },
        series: colDesc.map(colDescItem => {
          return {
            symbolSize: 20,
            name: colDescItem,
            data: data.map(dataItem => {
              return [dataItem[colDescItem], dataItem[xGroupByDesc.colDesc]]
            }),
            type: 'scatter'
          }
        })
      }
      return  this.$parent.merge(config)
    }
  }
}
</script>
