<template>
  <el-input-number class="ksh-input-number" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'KshInputNumber',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
// scss
.ksh-input-number {
    ::v-deep input {
        border-radius: 1px;
    }
    &.el-input--small {
        ::v-deep input {
            border-radius: 1px;
            height: 38px;
            line-height: 38px;
        }
    }
}
</style>
