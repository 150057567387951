import Layout from '@/layout'
import {
  originUrl
  // panguUrl
} from '@/utils/domain-yundata.js'
import { getUserInfo } from '@/utils/auth'

const getRoleId = () => getUserInfo().roleId === 13

export default {
  path: originUrl + (getRoleId() ? '/train/project' : ''),
  component: Layout,
  name: 'pangu',
  meta: {
    title: '数据云脑',
    icon: 'pangu',
    sidebar: true,
    auth: ['dataPopNav']
  }
}

// export default {
//   path: '/pangu',
//   component: Layout,
//   meta: {
//     title: '数据云脑',
//     icon: 'pangu'
//   },
//   children: [
//     {
//       path: originUrl + (getRoleId() ? '/train/project' : ''),
//       component: Layout,
//       name: 'pangu',
//       meta: {
//         title: '盘古云脑',
//         // icon: 'pangu',
//         sidebar: false,
//         auth: ['dataPopNav']
//       }
//     },
//     {
//       path: `${panguUrl}`,
//       component: Layout,
//       name: 'pangu2.0',
//       meta: {
//         title: '盘古云脑2.0',
//         // icon: 'pangu',
//         sidebar: true,
//         auth: ['dataPopNav']
//       }
//     }
//   ]
// }
