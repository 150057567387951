import Layout from '@/layout'
// import EmptyLayout from '@/layout/empty'

export default {
  path: '/',
  component: Layout,
  redirect: '/index',
  meta: {
    title: '首页',
    icon: 'index'
  },
  children: [
    {
      path: 'index',
      name: 'Index',
      component: () => import(/* webpackChunkName: "index" */ '@/views/index/Index.vue'),
      meta: {
        title: '首页',
        activeMenu: '/',
        sidebar: false,
        breadcrumb: false
      }
    }, {
      path: 'report-library',
      name: 'ReportLibrary',
      component: () => import(/* webpackChunkName: "index" */ '@/views/index/ReportLibrary.vue'),
      meta: {
        title: '报表库',
        activeMenu: '/',
        sidebar: false,
        breadcrumb: false
      }
    }, {
      path: 'reporting-activity',
      name: 'ReportingActivity',
      component: () => import(/* webpackChunkName: "index" */ '@/views/index/ReportingActivity.vue'),
      meta: {
        title: '报表详情',
        activeMenu: '/',
        sidebar: false,
        breadcrumb: false
      }
    },
    {
      path: 'report-preview',
      name: 'ReportPreview',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/index/ReportPreview.vue'),
      meta: {
        requiresAuth: true,
        title: '报告预览',
        sidebar: false,
        showWarter: false
      }
    }
  ]
}
