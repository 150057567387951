<template>
  <el-submenu index="1">
    <template slot="title">
      <svg-icon name="avatar" />
      <span slot="title">用户中心</span>
    </template>
    <el-menu-item-group v-for="item in userMenu" :key="item.path" class="menu-group">
      <template v-if="item.show">
        <el-menu-item :title="item.meta.title" :index="item.path" @click.native.stop="handleSelectMenu(item.path)">
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </template>
    </el-menu-item-group>
  </el-submenu>
</template>

<script>
import { toLogin } from '@/utils/request'
export default {
  name: 'SidebarUserMenu',
  inject: ['reload'],
  data() {
    return {
    }
  },
  computed: {
    personInfo() {
      return {
        ...this.$store.state.user.metadataUserInfo,
        orgLoginVo: {
          ...this.$store.state.user.metadataUserInfo?.orgLoginVo
        }
      }
    },
    userMenu() {
      return [
        {
          path: '/safe/center',
          meta: {
            title: '账号与安全'
          },
          show: true
        },
        {
          path: '/select/factory',
          meta: {
            title: '切换账号'
          },
          show: this.personInfo.accountNum
        },
        {
          path: '/doctor/certify/set',
          meta: {
            title: '更多设置'
          },
          show: this.personInfo.orgLoginVo.isTopOrg
        },
        {
          path: '/recoverystation',
          meta: {
            title: '回收站'
          },
          show: true
        },
        {
          path: '/user/login',
          meta: {
            title: '退出登录'
          },
          show: true
        }
      ]
    }
  },
  methods: {
    handleSelectMenu(routePath) {
      if (routePath === '/user/login') {
        toLogin()
      } else {
        this.$router.push({
          path: routePath
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-item.is-active,
.el-submenu .el-menu--inline .el-menu-item.is-active {
  background-color: $g-sub-sidebar-menu-active-bg !important;
  span {
    color: $g-main-sidebar-menu-color !important;
  }
}
.el-menu-item,
.el-menu-item span,
.el-submenu__title,
.el-submenu__title div {
  vertical-align: inherit;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
::v-deep {
  .el-menu-item,
  .el-submenu__title {
    vertical-align: middle;
    height: 40px !important;
    line-height: 40px;
    font-size: $font-size-small;
    text-align: justify;
    text-align-last: justify;
    text-justify: inter-ideograph;
    color: #a3a3a3;
    span {
      min-width: 50px;
      display: inline-block;
      position: relative;
      text-align: justify;
      color: #787878;
      &::after {
        content: '';
        width: 50px;
        height: 1px;
        background-color: #eee;
        position: absolute;
        left: 0;
        bottom: 1px;
      }
    }
  }
}
.el-submenu {
  .el-menu--inline {
    .el-submenu > .el-submenu__title,
    .el-menu-item {
      line-height: 40px;
      padding-left: 45px !important;
      color: #a3a3a3;
      >span {
        &::after {
          height: 0;
        }
      }
      &:hover {
        transform: all 0.3s;
        background-color: $g-main-sidebar-menu-active-bg;
      }
    }
  }
}
.el-submenu,
.el-menu-item {
  min-width: inherit !important;
  color: #a3a3a3;
  >span {
    color: #787878;
  }
  .svg-icon {
    font-size: 16px;
    margin-right: 10px;
    vertical-align: -0.25em;
  }
}
a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.menu-group ::v-deep .el-menu-item-group__title {
  padding: 0 !important;
}
</style>
