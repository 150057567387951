/*
 * @Author: your name
 * @Date: 2021-07-27 11:32:26
 * @LastEditTime: 2021-08-26 03:28:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/material/action.js
 */
import * as MaterialService from '@/services/materialService'
import * as Types from "@/store/mutations-types";
import {GET_KNOWLEDGE_POINT_LIST} from "@/store/mutations-types";
export default {
  getArticleList ({commit,state},req) {
    return MaterialService.getArticleList(req).then(res=>{
      return Promise.resolve(res)
    },rea=>{
      return Promise.reject(rea)
    })
  },
  getMaterialExamList ({commit,state}, req) {
    return MaterialService.getMaterialExamList(req).then(res=>{
      return Promise.resolve(res)
    },rea=>{
      return Promise.reject(rea)
    })
  },
  getVideoPlayUrl({ commit }, data) {
    // 获取单个视频播放地址
    return MaterialService.getVideoPlayUrl(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getVideoUrl({ commit }, data) {
    // 获取单个视频播放地址
    return MaterialService.getVideoUrl(data).then(
      res => {
        return Promise.resolve(res.data)
      },
      rea => {
        return Promise.reject(rea.message)
      }
    )
  },
  getExamDetail({ commit }, data) {
    // 问题详情
    return MaterialService.getExamDetail(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  }
}
