/*
 * @Author: your name
 * @Date: 2021-07-20 10:23:45
 * @LastEditTime: 2021-09-02 11:28:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/academic/mutation.js
 */
import {GET_ACADEMIC_SCENE_LIST,GET_DOWN_ORG_LIST,DELETE_ACADEMIC_SCENE_ITEM,UPDATE_ACADEMIC_SCENE_ITEM,ADD_ACADEMIC_SCENE_ITEM,ADD_ACADEMIC_SCENE_CODE_COUNT,RECALL_ACADEMIC_SCENE_CODE_COUNT,UPDATE_ACADEMIC_SCENE_TYPE,UPDATE_ACADEMIC_SOURCE_TYPE} from '../../mutations-types'
export default {
  [GET_ACADEMIC_SCENE_LIST] (state,list) {
    state.academicSceneList = list
  },
  [GET_DOWN_ORG_LIST] (state,list) {
    state.downOrgList = list
  },
  [DELETE_ACADEMIC_SCENE_ITEM] (state, sceneId) {
    state.academicSceneList = [...state.academicSceneList].filter(v=>v.id !== sceneId)
  },
  [UPDATE_ACADEMIC_SCENE_ITEM] (state, current) {
    state.academicSceneList = [...state.academicSceneList].map(v=>{
      if (v.id === current.id) {
        v = {...v,...current}
      }
      return v
    })
  },
  [ADD_ACADEMIC_SCENE_ITEM] (state, current) {
    state.academicSceneList = [current,...state.academicSceneList]
  },
  [ADD_ACADEMIC_SCENE_CODE_COUNT] (state, current) {
      state.academicSceneList = [...state.academicSceneList].map(v=>{
        if (v.id === current.id) {
          v = {...v,totalCount: v.totalCount + current.allotCount,usedCount: v.usedCount + current.allotCount}
        }
        return v
      })
  },
  [RECALL_ACADEMIC_SCENE_CODE_COUNT] (state, current) {
    state.academicSceneList = [...state.academicSceneList].map(v=>{
      if (v.id === current.id) {
        console.log(current)
        v = {...v,recallCount: v.recallCount + current.recallCount}
      }
      return v
    })
  },
  [UPDATE_ACADEMIC_SCENE_TYPE] (state, newVal) {
    state.academicSceneType = newVal
  },
  [UPDATE_ACADEMIC_SOURCE_TYPE] (state, newVal) {
    state.academicSourceType = newVal
  }
}
