import { TIM } from '@/views/academic/tim/config/tim-config'
import eventBus from '@/utils/eventBus'

const getDefaultState = () => {
  return {
    GRP_AVCHATROOM: '', // 直播群聊
    conversationType: TIM.TYPES.CONV_GROUP, // 当前会话类型
    conversationID: '',
    to: '', // 发送到谁
    scrollTopCount: 0, // 当前聊天对话框，距离顶部的距离
    scrollDirection: 'bottom', // 当前滚动方向
    specialUser: [],
    conversation: [], // 消息列表
    previewImgList: []
  }
}

const state = getDefaultState()
const getters = {
  getScrollTopCount: state => state.scrollTopCount,

  /**
   * 更新消息列表中图片数组
   * 调用时机：点击预览图片时
   * @param {Object} state
   * @returns
   */
  previewImgList: state => {
    return state.conversation
      .filter(item => item.type === TIM.TYPES.MSG_IMAGE && !item.isRevoked && item.payload.imageInfoArray)
      .map(item => item.payload.imageInfoArray && item.payload.imageInfoArray[0].url)
  }
}
const mutations = {
  // 重置当前store
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setConversationID(state, data) {
    state.conversationID = data
  },
  setConversationType(state, data) {
    state.conversationType = data
  },
  setTo(state, data) {
    state.to = data
  },
  setScrollTopCount(state, topVal) {
    state.scrollTopCount = topVal
  },
  setScrollDirection(state, direction) {
    state.scrollDirection = direction
  },
  updateSpecialUser(state, data) {
    state.specialUser = [...state.specialUser, ...data]
  },
  /**
   * 设置或更新房间群聊消息
   * 调用时机：获取消息列表
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  setConversation(state, data) {
    const { message } = data
    const arr = message.filter(item => item)
    // const sotrArr = _.uniqWith(arr, 'ID')
    state.conversation = arr
    eventBus.$emit('scrollToBottom')

  },
  /**
   * 更新消息列表
   * 调用时机：收/发消息事件触发时 回顶
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  sendMsg(state, data) {
    const { message } = data
    state.conversation.push(message)
    state.conversation = state.conversation.filter(item => item)
    if (message.type !== TIM.TYPES.MSG_CUSTOM) {
      eventBus.$emit('scrollToBottom')
    } 
  },
  /**
   * 重新发送
   * 调用时机：点击重新发送
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  resendMsg(state, data) {
    const { message } = data
    state.conversation = state.conversation.map(item => {
      if (item.ID === message.ID) {
        return message
      }
      return item
    })
  },
  /**
   * 删除某条消息
   * 调用时机：点击删除消息
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  deleteMsg(state, data) {
    const { message } = data
    const arr = state.conversation.filter(item => item.ID !== message.ID)
    state.conversation = arr
  },
  /**
   * 更新消息列表
   * 调用时机：消息撤回时
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  revocation(state, data) {
    const { message } = data
    const currentList = state.conversation
    for (let i = 0; i < currentList.length; i++) {
      const item = currentList[i]
      if (item.ID === message.ID) {
        currentList[i] = { ...message }
      }
    }
  },

  refresh(state, data) {
    const { copyMessage, message} = data
    state.conversation = state.conversation.map(item => {
      if (item.ID === message.ID) {
        return copyMessage
      }
      return item
    })
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
