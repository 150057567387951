import {domainConfig, domainDataConfig} from './domain-config'
export function postMessage(data, origrin) {
  const json = JSON.stringify(data)
  if (window.parent) {
    window.parent.postMessage(json, origrin)
  }
}
export const vueFunc = {
  install(Vue) {
    Vue.prototype.$qxsDataPageJump = (path = '') => {
      const url = domainDataConfig[window.location.hostname] + path
      window.open(url)
    }
    // Vue.prototype.$qxsPageJump = (path = '') => {
    //   postMessage({ type: 'jump', data: '' })
    //   setTimeout(()=>{
    //     let url = domainConfig[window.location.hostname] + path
    //     window.open(url)
    //   },10)
    // }
    // Vue.prototype.$postMessage = postMessage
    Vue.prototype.$receiveMessage = (func, isValid = true) => {
      if (window.attachEvent) {
        window.attachEvent('onmessage', event => {
          const origin = event.origin || event.originalEvent.origin
          if (isValid && origin !== domainConfig[window.location.hostname]) {
            // 验证域名
            return
          }
          const data = JSON.parse(event.data)
          func(data)
        })
      } else {
        window.addEventListener('message', event => {
          const origin = event.origin || event.originalEvent.origin
          if (isValid && origin !== domainConfig[window.location.hostname]) {
            // 验证域名
            return
          }
          const data = JSON.parse(event.data)
          func(data)
        })
      }
    }
  }
}
