<template>
  <el-date-picker v-bind="$attrs" class="ksh-date-time-picker" v-on="$listeners">ksh-date-time-picker</el-date-picker>
</template>

<script>
export default {
  name: 'KshDatePicker'
}
</script>

<style scoped lang="scss">
// scss
.ksh-date-time-picker {
    ::v-deep input{
        border-radius: 1px;
    }
    &.el-input--small{
        ::v-deep input{
            height: 38px
        }
    }
}
</style>
