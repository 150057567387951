const storagePrefix = 'qxs_'

const storage = {
  local: {
    has: key => {
      return Object.prototype.hasOwnProperty.call(localStorage, `${storagePrefix}${key}`)
    },
    get: key => {
      return localStorage.getItem(`${storagePrefix}${key}`)
    },
    set: (key, value) => {
      localStorage.setItem(`${storagePrefix}${key}`, value)
    },
    remove: key => {
      localStorage.removeItem(`${storagePrefix}${key}`)
    },
    clear: () => {
      localStorage.clear()
    }
  },
  session: {
    has: key => {
      return Object.prototype.hasOwnProperty.call(sessionStorage, `${storagePrefix}${key}`)
    },
    get: key => {
      return sessionStorage.getItem(`${storagePrefix}${key}`)
    },
    set: (key, value) => {
      sessionStorage.setItem(`${storagePrefix}${key}`, value)
    },
    remove: key => {
      sessionStorage.removeItem(`${storagePrefix}${key}`)
    },
    clear: () => {
      sessionStorage.clear()
    }
  },
  cookie: {
    has: key => {
      return Object.prototype.hasOwnProperty.call(document.cookie, `${storagePrefix}${key}`)
    },
    get: key => {
      const arr = document.cookie.match(new RegExp(`(^| )${storagePrefix}${key}=([^;]*)(;|$)`))
      if (arr != null) {
        return unescape(arr[2])
      }
      return null
    },
    set: (key, value, expires = 0, path = '/') => {
      let exp = expires
      if (typeof exp === 'number') {
        const d = new Date()
        d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000)
        exp = d
      }
      const expiresStr = exp instanceof Date ? exp.toUTCString() : ''
      document.cookie = `${storagePrefix}${key}=${escape(value)};expires=${expiresStr};path=${path}`
    },
    remove: key => {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      const cval = storage.cookie.get(key)
      if (cval != null) {
        document.cookie = `${storagePrefix}${key}=${cval};expires=${exp.toUTCString()}`
      }
    },
    clear: () => {
      const keys = document.cookie.match(/[^ =;]+(?==)/g)
      if (keys) {
        keys.forEach(key => {
          document.cookie = `${storagePrefix}${key}=0;expires=${new Date(0).toUTCString()}`
        })
      }
    }
  }
}

export default storage
