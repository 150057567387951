import store from '@/store'
export function filterSpeakerIdentity(arr) {
  if (!arr) return
  return arr.map(item => {
    if (formatSpeaker().includes(item.from)) {
      item.identity = 'speaker'
    }
    return item
  })
}

const formatSpeaker = () => {
  const arr = store.state.tim.user.speakerList.map(item => `${item.userType}_${item.userId}`)
  return arr
}
