<template>
  <router-link
    v-slot="{ navigate }"
    custom
    :to="to"
    class="title is-link"
    :title="title"
    tag="div"
  >
    <div @click="navigate">
      <svg-icon v-if="showLogo&&collapse === 'expand'" name="logo" class="logo" />
      <svg-icon v-else style="font-size: 30px;" name="logo-icon" />
      <!-- <span v-if="showTitle">{{ title }}</span> -->
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    showLogo: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    collapse: {
      type: String,
      require: true
    },
    linkToName: String
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      logo: require('@/assets/nav/logo.png')
    }
  },
  computed: {
    to() {
      const rtn = {}
      rtn.name = this.linkToName || 'Index'
      return rtn
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: fixed;
  z-index: 1000;
  top: 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $g-sidebar-width;
  height: $g-header-height;
  text-align: center;
  overflow: hidden;
  text-decoration: none;
  transition: 0.3s width ease-in-out, 0.3s padding-left ease-in-out, 0.3s padding-right ease-in-out;
  color: $color-primary;
  &.is-link {
    cursor: pointer;
  }
  .logo {
    height: $g-sidebar-logo-height;
    font-size: 90px;
    & + span {
      margin-left: 10px;
    }
  }
  span {
    display: block;
    font-weight: bold;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
