<template>
  <div>
    <div
      id="actionbar"
      :class="{
        actionbar: true,
        shadow: !isBottom
      }"
    >
      <slot />
    </div>
    <div :style="`height: ${height}px`" />
  </div>
</template>

<script>
export default {
  name: 'FixedActionBar',
  data() {
    return {
      isBottom: true,
      resizeObserver: null,
      height: 60
    }
  },
  mounted() {
    this.onScroll()
    window.addEventListener('scroll', this.onScroll)

    this.initResize()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)

    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    initResize() {
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          this.height = entry.target.offsetHeight
        }
      })
      this.resizeObserver.observe(document.querySelector('#actionbar'))
    },
    onScroll() {
      // 变量scrollTop是滚动条滚动时，滚动条上端距离顶部的距离
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度（当前可滚动的页面的总高度）
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部
      if (Math.ceil(scrollTop + windowHeight) >= scrollHeight) {
        this.isBottom = true
      } else {
        this.isBottom = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.actionbar {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  left: 50%;
  width: calc(100% - #{$g-sidebar-width} - 2 * #{$g-main-wrap-margin});
  transform: translateX(calc(-50% + #{$g-sidebar-width} * 0.5));
  padding: 15px 20px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 15px);
  text-align: center;
  background-color: #fff;
  transition: all 0.3s, box-shadow 0.5s;
  border-top: 1px solid #f7f7f7;
  // &.shadow {
  // box-shadow: 0 -10px 10px -10px #ccc;
  // }
  @media screen and (max-width: 768px) {
    width: 100%;
    transform: translateX(-50%);
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>
