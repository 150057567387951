/*
 * @Author: your name
 * @Date: 2020-10-28 17:44:35
 * @LastEditTime: 2020-12-08 18:37:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/help/mutation.js
 */
import {
  GET_HELP_NAV_LIST,
  INIT_HELP_MESSAGE_LIST,
  GET_HELP_MESSAGE_LIST,
  ON_HATE_MESSAGE_DETAIL,
  ON_READ_HELP_MESSAGE,
  ON_LIKE_MESSAGE_DETAIL,
  GET_HELP_MESSAGE_DETAIL
  } from '../../mutations-types'
  
  export default {
    [GET_HELP_NAV_LIST] (state, list) {
      state.helpNavList = list
    },
    [INIT_HELP_MESSAGE_LIST] (state, list) {
      state.helpList = list
    },
    [GET_HELP_MESSAGE_LIST] (state, list) {
      state.helpList = [...state.helpList,...list]
    },
    [ON_READ_HELP_MESSAGE] (state, id) {
      state.helpList = [...state.helpList].map(v=>{
        if (v.id === id) {
          v = {...v,hasRead: true}
        }
        return v
      })
      let list = state.indexHelpList || []
      state.indexHelpList = [...list].map(v=>{
        if (v.id === id) {
          v = {...v,hasRead: true}
        }
        return v
      })
    },
    [GET_HELP_MESSAGE_DETAIL] (state, res) {
      state.helpDetail = res
      state.helpMessageTitle = res.title
      state.publishAt = res.publishAt
      state.viewCount = res.browseCount
      state.hateCount = res.hateCount
      state.likeCount = res.likeCount
      state.like = res.like
      state.hate = res.hate
      state.helpMessageContent = res.content
    },
    [ON_LIKE_MESSAGE_DETAIL] (state, res) {
      state.like = res.like
      state.likeCount = res.likeCount
      state.hateCount = res.hateCount
      if (state.hate && res.like) {
        state.hate = false
      }
    },
    [ON_HATE_MESSAGE_DETAIL] (state, res) {
      state.hate = res.hate
      state.likeCount = res.likeCount
      state.hateCount = res.hateCount
      if (state.like && res.hate) {
        state.like = false
      }
    }
  }