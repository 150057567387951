import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import storage from './storage'
import { md5 } from './util'

const defaultConfig = {
  nextBtnText: '下一步',
  prevBtnText: '上一步',
  doneBtnText: '完成',
  disableActiveInteraction: true,
  allowClose: false,
  overlayOpacity: 0.5,
  stageRadius: 8,
  stagePadding: 8,
  popoverOffset: 50,
  showProgress: false,
  progressText: '{{current}}/{{total}}',
  onPopoverRender: (popover, { config, state }) => {
    popover.arrow.style.setProperty('--popover-offset', `${(config.popoverOffset || 0) - 2}px`)
    const nextButton = popover.footerButtons.querySelector('.driver-popover-next-btn')
    const progressText = state.activeStep?.popover?.progressText
    if (nextButton && progressText && !(/\(\s*\d+\s*\/\s*\d+\s*\)/.test(nextButton.innerHTML))) {
      nextButton.innerHTML += ` (${progressText})`
    }
  }
}
export function createDriver(obj) {
  if (obj.onPopoverRender) {
    // 存储原始的 onPopoverRender 函数
    const originalOnPopoverRender = obj.onPopoverRender
    obj.onPopoverRender = (popover, { config, state }) => {
      originalOnPopoverRender(popover, { config, state })
      defaultConfig.onPopoverRender(popover, { config, state })
    }
  }
  const config = { ...defaultConfig, ...obj }
  config.onDestroyed = (...e) => {
    const { record } = handleDriverRecord(config)
    record()
    obj.onDestroyed && obj.onDestroyed(...e)
  }
  const {key, driverJson} = handleDriverRecord(config)
  if (driverJson[md5(key)]) {
    return
  }
  const driverObj = driver(config)

  return driverObj
}

// 保存记录
export function handleDriverRecord(config) {
  const key = config ? JSON.stringify(config) : ''
  const md5Key = key ? md5(key) : ''
  const driverJson = JSON.parse(storage.local.get('driver') || '{}')
  function record(driverJsonkey = md5Key) {
    driverJson[driverJsonkey] = (driverJson[driverJsonkey] || 0) + 1
    storage.local.set('driver', JSON.stringify(driverJson))
  }
  function remove(driverJsonkey = md5Key) {
    delete driverJson[driverJsonkey]
    storage.local.set('driver', JSON.stringify(driverJson))
  }
  return {
    key,
    driverJson,
    record,
    remove
  }
}
