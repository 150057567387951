/*
 * @Author: your name
 * @Date: 2020-11-25 21:09:09
 * @LastEditTime: 2021-01-25 15:20:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/doctor/index.js
 */

import actions from './action'
import mutations from './mutation'
const state = {
  accountVideoList: [],//医生管理下的直播列表,
  doctorList: [],//医生管理下医生列表,
  hospitalList: [],//医生管理下医院列表,
  speakerList: [],//医生管理下 播客列表
  tagCoverDoctor: 0,//产品分类下覆盖医生
  tagCoverLevelDoctor: 0,
  tagWillCoverLevelDoctor: 0,
  tagCoverDoctorList: [],
  factoryCoverDoctorSummaryData: {}, // 医生汇总信息
  tagCoverDoctorChartData: [],// 产品分类下图表信息
  // ------------- 医生个人数据 -------------
  doctorSceneData: [], // 医生学习数据
  doctorSceneCodeSummary: {},
  doctorLiveVideoList: [], //医生直播视频列表

}
const getters = {
  accountVideoList: () => state.accountVideoList,
  doctorList: () => state.doctorList,
  hospitalList: () => state.hospitalList,
  speakerList: () => state.speakerList,
  tagCoverDoctor: () => state.tagCoverDoctor,
  tagCoverDoctorList: () => state.tagCoverDoctorList,
  tagCoverLevelDoctor: () => state.tagCoverLevelDoctor,
  tagCoverDoctorChartData: () => state.tagCoverDoctorChartData,
  tagWillCoverLevelDoctor: () => state.tagWillCoverLevelDoctor,
  factoryCoverDoctorSummaryData: ()=> state.factoryCoverDoctorSummaryData,
  doctorSceneCodeSummary: () => state.doctorSceneCodeSummary,
  doctorSceneData: () => state.doctorSceneData,
  doctorLiveVideoList: () => state.doctorLiveVideoList
}
export default {
  state,
  getters,
  actions,
  mutations
}
