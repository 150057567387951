import CryptoJS from 'crypto-js'
import XLSX from 'xlsx'
import { getToken } from './auth'
/**
 * 页面滚动到指定element ID处
 * @param targetId: 页面元素的ID
 * @return null
 */
export function scrollToElementId(targetId) {
  if (window && document) {
    document.getElementById(targetId).scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }
}
/**
 * int 分单位转换为元单位
 * @param fen(int):
 * @return (int)
 */
export const fenToYuan = fen => {
  const num = `${fen  }`
  const intNum = num.slice(0, -2)
  const dotNum = num.slice(-2)
  const lastNum = num.slice(-1)
  if (Number(dotNum) > 0) {
    if (Number(lastNum) === 0) {
      return `${intNum  }.${  dotNum.slice(0, 1)}`
    }
    return `${intNum  }.${  dotNum}`
  }
  return Number(intNum)
}
/**
 * int 分单位转换为整数的元单位（int）
 * @param fen(int):
 * @return (int)
 */
export const fenToYuanInt = fen => {
  const num = `${fen  }`
  const intNum = num.slice(0, -2) // 整数位
  return Number(intNum)
}
/**
 * int 元转分
 * @param yuan(int):
 * @return (int)
 */
export const YuanToFen = yuan => {
  return yuan * 100
}
/**
 * 某个对象是否在数组里,并返回index
 * @param arr(array):
 * @param key(string):
 * @param val:
 * @return index (int)
 */
export const objIndexOf = (arr, key, val) => {
  let index = -1
  arr.forEach((item, ind) => {
    if (item[key] === val) {
      index = ind
    }
  })
  return index
}
/**
 * 从url中获取参数
 * @param variable string:
 * @return string
 */
export function getQueryVariable(variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return (false)
}
/**
 * 返回顶级域名
 * @return string
 */
export const getTopDomain = () => {
  return document.domain.split('.').slice(-2).join('.')
}
/**
 * 人际验证注入js
 */
export function initTCaptcha() {
  return new Promise((resolve, reject) => {
    if (window.TencentCaptcha) {
      resolve(true)
    } else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
      script.onerror = reject
      script.onload = function() {
        resolve(true)
      }
      document.head.appendChild(script)
      return
    }
  })
}
export function initTcPlayerJs() {
  return new Promise((resolve, reject) => {
    if (window.TcPlayer) {
      resolve(true)
    } else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = '//imgcache.qq.com/open/qcloud/video/vcplayer/TcPlayer-2.3.3.js'
      script.onerror = reject
      script.onload = function() {
        resolve(true)
      }
      document.head.appendChild(script)
      return
    }
  })
}
export function setGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}
/** *
 * 任务类型
 * @params type string: meeting->科会；questionnaire->调研；live->直播；
 * @return string
 *
 **/
const sceneTypeMap = new Map([
  [1, [1, '科会', 'meeting']],
  ['1', [1, '科会', 'meeting']],
  ['meeting', [1, '科会', 'meeting']],
  ['科会', [1, '科会', 'meeting']],
  [4, [4, '调研', 'questionnaire']],
  ['4', [4, '调研', 'questionnaire']],
  ['survey', [4, '调研', 'questionnaire']],
  ['questionnaire', [4, '调研', 'questionnaire']],
  ['调研', [4, '调研', 'questionnaire']],
  [5, [5, '直播', 'live']],
  ['5', [5, '直播', 'live']],
  ['live', [5, '直播', 'live']],
  ['直播', [5, '直播', 'live']],
  [6, [6, '会议', 'webinar']],
  ['6', [6, '会议', 'webinar']],
  ['webinar', [6, '会议', 'webinar']],
  ['会议', [6, '会议', 'webinar']],
  [8, [8, '拜访', 'academicVisit']],
  ['8', [8, '拜访', 'academicVisit']],
  ['academicVisit', [8, '拜访', 'academicVisit']],
  ['拜访', [8, '拜访', 'academicVisit']],
  [7, [7, '培训', 'training']],
  ['7', [7, '培训', 'training']],
  ['training', [7, '培训', 'training']],
  ['培训', [7, '培训', 'training']],
  [12, [12, '播客', 'MEDICAL_PODCAST']],
  ['12', [12, '播客', 'MEDICAL_PODCAST']],
  ['MEDICAL_PODCAST', [12, '播客', 'MEDICAL_PODCAST']],
  ['播客', [12, '播客', 'MEDICAL_PODCAST']]
])
export const inviteCodeType = type => {
  if (!sceneTypeMap.get(type)) {
    return sceneTypeMap.get(1)
  }
  return sceneTypeMap.get(type)
}
/**
 * 检查密码强度
 * @param {String} val 密码
 * @return Object {value,key}
 */
export function checkPassWordStrength(val) {
  // 密码安全度，高：包含大写字母、小写字母、数字和特殊符号；中：仅包含高中的三种；低：包含不到三种；高危：只有一种；
  let modes = 0
  const obj = {
    1: { value: '高危', key: 0 },
    2: { value: '安全度低', key: 1 },
    3: { value: '安全度中', key: 2 },
    4: { value: '安全度高', key: 3 }
  }
  if (/\d/.test(val)) modes++ // 数字
  if (/[a-z]/.test(val)) modes++ // 小写
  if (/[A-Z]/.test(val)) modes++ // 大写
  if (/\W/.test(val)) modes++ // 特殊字符
  return obj[modes] || {}
}
/**
 * AES加密
 * @param {any} word 加密数据
 */
export function cryptoEncrypt(word) {
  if (!word) return ''
  const config = {
    AES_KEY: 'QuXueShu20201230',
    AES_IV: 'QuXueShu20201230'
  }
  const key = CryptoJS.enc.Utf8.parse(config.AES_KEY)
  const iv = CryptoJS.enc.Utf8.parse(config.AES_IV)
  let encrypted = ''
  let srcs = ''
  if (typeof (word) == 'string') {
    srcs = word
  } else if (typeof (word) == 'object') { // 对象格式的转成json字符串
    srcs = CryptoJS.enc.Utf8.parse(word)
  }
  encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

/**
 * md5 加密
 */
export function md5(word) {
  if (!word) return ''
  return CryptoJS.MD5(word).toString().toLowerCase()
}
/**
 * 导出为excel
 */
export function JsonToExcel(title = '', json = null, merge) {
  try {
    if (!json) return
    // 创建worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(json)
    if (merge) {
      worksheet['!merges'] = merge
    }
    // 新建空workbook，加入worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, '')
    XLSX.writeFile(workbook, `${title}.xlsx`)
  } catch (e) {
    Promise.reject('数据导出失败')
  }
}
export function excelToJson(data, cb, start) {
  const workbook = XLSX.read(data, {type: 'binary'})
  // 遍历每张表读取
  const json = []
  for (const sheet in workbook.Sheets) {
    // eslint-disable-next-line no-prototype-builtins
    if (workbook.Sheets.hasOwnProperty(sheet)) {
      let arr = []
      const fromTo = workbook.Sheets[sheet]['!ref']
      const end = fromTo.split(':')
      const space = start + end[1].toString()
      // json = json.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
      let data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {header: 1, range: start ? space : fromTo})
      data = data.filter(row => row.some(cell => cell != null && cell !== ''))
      arr = arr.concat(data)
      const titleArr = arr[0]
      arr.shift()
      arr = arr.map(item => {
        const obj = {}
        titleArr.forEach((titleItem, index) => {
          obj[titleItem] = item[index]
        })
        return obj
      })
      json.push(...arr)
    }
  }
  cb && cb(json)
}
// 存储时间筛选
export function getLocalSceneTimeDate(sceneKey = '') {
  let keyBob = window.sessionStorage.getItem(`${getToken()}_scene_time`)
  try {
    keyBob = keyBob ? JSON.parse(keyBob) : {}
    if (!sceneKey) {
      return keyBob
    } else {
      return keyBob[sceneKey] || {
        startDate: '2018-05-08',
        endDate: new Date()
      }
    }
  } catch (e) {
    return {
      startDate: '2018-05-08',
      endDate: new Date()
    }
  }
}
export function removeLocalSceneTimeData() {
  window.sessionStorage.removeItem(`${getToken()}_scene_time`)
}
export function saveLocalSceneTimeData(sceneKey = '', sceneData = {}) {
  const keyBob = getLocalSceneTimeDate() || {}
  keyBob[sceneKey] = sceneData
  window.sessionStorage.setItem(`${getToken()}_scene_time`, JSON.stringify(keyBob))
}
export const saveLiveExamGuide = () => {
  window.localStorage.setItem('live_exam_guide', true)
}
export const hadLiveExamGuide = () => {
  return window.localStorage.getItem('live_exam_guide')
}

// 全角转半角
export const CtoH = str => {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) == 12288) {
      result += String.fromCharCode(str.charCodeAt(i) - 12256)
      continue
    }
    if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) result += String.fromCharCode(str.charCodeAt(i) - 65248)
    else result += String.fromCharCode(str.charCodeAt(i))
  }
  return result
}

// 深拷贝
export function deepClone(target) {
  // 定义一个变量
  let result
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
  // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = [] // 将result赋值为一个数组，并且执行遍历
      for (const i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {}
      for (const i in target) {
        result[i] = deepClone(target[i])
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }
  // 返回最终结果
  return result
}

// 获取地图数据（GeoJson)
export function getGeoJsonData(options) {
  return new Promise(resolve => {
    // 实例化DistrictSearch
    const opt = {
      showbiz: false,
      extensions: 'all',
      ...options
    }
    const district = new AMap.DistrictSearch(opt)
    // 行政区查询
    district.search(options.adcode, function(status, result) {
      window.AMapUI.loadUI(['geo/DistrictExplorer'], function(DistrictExplorer) {
        // 创建一个实例
        const districtExplorer = new DistrictExplorer()
        districtExplorer.loadAreaNode(result.districtList[0].adcode, function(error, areaNode) {
          if (error) {
            console.error(error)
            return
          }
          const mapJson = {}
          // geoJson必须这种格式
          mapJson.features = areaNode.getSubFeatures()
          resolve(mapJson)
        })
      })
    })
  })
}
// 判断是移动端还是pc端
export function isMobile() {
  const userAgentInfo = navigator.userAgent
  const Agents = ['Android', 'iPhone',
    'SymbianOS', 'Windows Phone',
    'iPad', 'iPod']
  let flag = false
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = true
      break
    }
  }
  return flag
}
// 判断是不是横屏
export function isLandscape() {
  return window.innerWidth > window.innerHeight
}
/**
 * 是否为方法格式
 * @param func: 待判断的方法
 * @return boolean
 */
export const isFunc = func => typeof func === 'function'

export const getLocalUrl = () => window.location.href.split('#')[0]
