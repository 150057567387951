import actions from './action'
import mutations from './mutation'

const state = {
  list: []
}
const getters = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
