import { auth, authAll } from './auth'
import emojiRegex from 'emoji-regex'  // npm install emoji-regex
import DOMPurify from 'dompurify' // npm install dompurify

const camelToHyphen = str => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}
export default {
  install(Vue) {
    // dom 会先渲染后删除
    Vue.prototype.$auth = auth
    Vue.prototype.$authAll = authAll
    // 注册 v-auth 和 v-auth-all 指令
    Vue.directive('auth', {
      inserted: (el, binding) => {
        if (!auth(binding.value)) {
          el.remove()
        }
      }
    })
    Vue.directive('auth-all', {
      inserted: (el, binding) => {
        if (!authAll(binding.value)) {
          el.remove()
        }
      }
    })
    /** *
    * 防抖 单位时间只触发最后一次
    *  @param {?Number|300} time - 间隔时间
    *  @param {Function} fn - 执行事件
    *  @param {?String|"click"} event - 事件类型 例："click"
    *  @param {Array} binding.value - [fn,event,time]
    *  例：<el-button v-debounce="[reset,`click`,300]">刷新</el-button>
    *  也可简写成：<el-button v-debounce="[reset]">刷新</el-button>
    *  例：<el-input v-debounce="[reset,`input`,300]"></el-button>
    */
    Vue.directive('debounce', {
      inserted: function(el, binding) {
        const [fn, event = 'click', time = 300] = binding.value
        let timer
        let flag = true// 输入法标记
        if (event === 'input') {
          el.addEventListener('compositionstart', () => {
            flag = false
          })
          el.addEventListener('compositionend', () => {
            flag = true
          })
        }
        el.addEventListener(event, () => {
          timer && clearTimeout(timer)
          timer = setTimeout(() => {
            if (flag) fn()
          }, time)
        })
      }
    })
    /**
     * 清除输入框中的表情符号
     * 调用时机：输入框输入表情时
     */
    Vue.directive('clear-emoij', {
      bind(el) {
        const regex = emojiRegex()
        const obj = el.querySelectorAll('.el-input__inner,.el-textarea__inner')[0]
        const zclearNoNum = function(e) {
          if (e.target.composing) return
          const match = regex.exec(obj.value) // 也可以直接用正则表达式判断
          if (match) {
            /* for (let i=0;i<match.length;i++) {
              obj.value = obj.value.replace(match[i],"");
            }*/
            obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '')
            zclearNoNum(e)
          }
          // 触发v-model的更新
          obj.dispatchEvent(new Event('input'))
        }
        const zblur = function(e) {
          zclearNoNum(e)
          setTimeout(() => {
            const match = regex.exec(obj.value)
            if (match) {
              obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '')
              zclearNoNum(e)
            }
            // 触发v-model的更新
            obj.dispatchEvent(new Event('change'))
          }, 50)
        }
        obj.onkeyup = zclearNoNum
        obj.onblur = zblur

        function onCompositionStart(e) {
          e.target.composing = true
        }
        function onCompositionEnd(e) {
          console.log('按回车将字输入', e.target.value)
          e.target.composing = false
          obj.dispatchEvent(new Event('change'))
          setTimeout(() => {
            if (obj.value) {
              obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '')
              zclearNoNum(e)
            }
            // 触发v-model的更新
            obj.dispatchEvent(new Event('change'))
          }, 50)
        }
        obj.addEventListener('compositionstart', onCompositionStart)
        obj.addEventListener('compositionend', onCompositionEnd)
      }
    })
    /**
     * 注册v-safe-html指令
     * 防止xss攻击
     */
    Vue.directive('safe-html', (el, binding) => {
      el.innerHTML = DOMPurify.sanitize(binding.value)
    })
    Vue.directive('highlight', (el, binding) => {
      const { value } = binding
      if (value && typeof value === 'object') {
        const { hWord, word } = value
        let { style = 'background: yellow' } = value

        if (Object.prototype.toString.call(style) === '[object Object]') {
          const copyStyle = {}
          for (const key in style) {
            copyStyle[camelToHyphen(key)] = style[key]
          }
          style = JSON.stringify(copyStyle).replace(/{|}|"/g, '').replace(',', ';')
        }
        el.innerHTML = word.replace(new RegExp(hWord, 'ig'), a => {
          return `<span style="${style}">${a}</span>`
        })
      }
    })
  }
}
