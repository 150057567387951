<template>
  <el-table
    v-show="isShow"
    v-if="isMounted"
    :ref="initItem.ref"
    :class="initItem.class"
    :style="initItem.style"
    :data="initItem.tableData"
    :height="initItem.height"
    :border="initItem.border"
    :row-class-name="initItem.tableRowClassName"
    :max-height="initItem.maxHeight"
    @selection-change="activate($event,'selectionChange')"
  >
    <el-table-column
      v-if="initItem.selection"
      type="selection"
      width="55"
      align="center"
    />
    <template v-for="it in initItem.tableColumn">
      <el-table-column
        v-show="it.isShow"
        :key="it.label + it.prop"
        :label="it.label"
        :prop="it.prop"
        :fixed="it.fixed"
        :width="it.width"
        :align="it.align"
        :show-overflow-tooltip="it.showOverflowTooltip"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot="header" slot-scope="{row}">
          <template v-if="it.slot && it.slot.header">
            <template v-for="columnItem in it.slot.header">
              <BaseIndexVue
                :key="columnItem.id"
                :item="{...columnItem,row: row,parentData: item}"
                :root="root"
              />
            </template>
          </template>
          <span v-else>{{ it.label }}</span>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot-scope="{row}">
          <template v-if="it.slot&&it.slot.column">
            <template v-for="columnItem in it.slot.column">
              <BaseIndexVue
                :key="columnItem.id"
                :item="{...columnItem,row: row,parentData: item}"
                :root="root"
              />
            </template>
          </template>
          <span v-else>{{ row[it.prop] }}</span>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>

export default {
  name: 'BaseTable',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    root: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      keyMap: {
        selectionChange: 'select'
      }
    }
  },
  computed: {
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    },
    isMounted() {
      return typeof this.resetItem.isMounted === 'undefined' ? true : this.resetItem.isMounted
    }
  },
  methods: {
    activate(event, type = 'selectionChange') {
      this.$emit('onActivate', {
        value: event,
        key: this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs[this.item.ref].toggleRowSelection(row)
        })
      } else {
        this.$refs[this.item.ref].clearSelection()
      }
    }
  }

}
</script>

