<template>
  <div
    v-show="isShow"
    :ref="initItem.ref"
    class="base-row-box"
    :class="initItem.class"
    :style="initItem.style"
  >
    <template v-for="(it,index) in initItem.colCount">
      <div :key="it" class="col-box" :style="initItem.colStyle && initItem.colStyle[index]">
        <template v-for="columnIt in initItem.childSchemeList.filter(v => v.columnIndex === it)">
          <BaseIndexVue
            :key="columnIt.id"
            :item="columnIt"
            :root="root"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseRow',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    root: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  }
}
</script>

<style lang="scss" scoped>
.base-row-box {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}
.col-box {
  flex: 1;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
    div {
      margin-bottom: 5px;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
</style>
