import Layout from '@/layout'
// import EmptyLayout from '@/layout/empty'

export default {
  path: '/download',
  component: Layout,
  redirect: '/download/center',
  meta: {
    title: '下载中心',
    icon: 'download'
  },
  children: [
    {
      path: 'center',
      name: 'DownloadCenter',
      component: () => import(/* webpackChunkName: "download" */ '@/views/download/DownloadCenter'),
      meta: {
        requiresAuth: true,
        title: '下载中心',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/download'
      }
    }]
}
