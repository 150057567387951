<template>
  <div class="report-entry">
    <slot :reportList="reportList" :checkReport="checkReport" />
    <el-dialog
      title="数据报告"
      :visible.sync="reportDialogVisible"
      width="30%"
      :before-close="selectedRow = null"
    >
      <el-table
        v-loading="reportLoading"
        :row-key="(row) => row.reportId"
        :data="reportList"
        style="width: 100%;"
        highlight-current-row
        @row-click="(row) => (selectedRow = row)"
      >
        <el-table-column width="55" label="选择" align="center">
          <template slot-scope="scoped">
            <el-radio class="radio-none-lable" :value="selectedRow ? selectedRow.reportId : ''" :label="scoped.row.reportId" />
          </template>
        </el-table-column>
        <el-table-column prop="reportName" label="报告名称" />
        <el-table-column prop="updatedAt" label="更新时间" width="150" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reportDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toViewReport">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ReportEntry',
  props: {
    pushLocation: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      reportList: [],
      selectedRow: null, // 存放选中的行数据
      reportLoading: false,
      reportDialogVisible: false,
      query: {}
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 查看数据报告
    viewDataReport(row) {
      this.$router.push({
        name: 'ReportPreview',
        query: {
          reportId: row.reportId,
          ...this.query
        }
      })
    },
    // 获取报告
    async getDataList() {
      try {
        this.reportLoading = true
        const {
          data
        } = await this.$axios.get(this.$API.queryReportPush, { params: {
          pushLocation: this.pushLocation
        } })
        this.reportList = data?.reportVOS || []
      } catch (error) {
        console.log('error: ', error)
      } finally {
        this.reportLoading = false
      }
    },
    // 跳转报告预览
    toViewReport() {
      if (!this.selectedRow) {
        this.$message.error('请选择报告')
        return
      }
      this.viewDataReport(this.selectedRow)
      this.reportDialogVisible = false
    },
    checkReport(query) {
      this.query = query
      if (this.reportList.length === 1) {
        this.viewDataReport(this.reportList[0])
      } else {
        this.reportDialogVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.report-entry {
  display: inline-block;
}
.el-button + .report-entry {
  margin-left: 10px;
}
.report-entry:first-child {
  margin-left: 0;
}
</style>
