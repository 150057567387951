/*
 * @Author: your name
 * @Date: 2020-10-28 17:44:35
 * @LastEditTime: 2023-03-06 10:17:02
 * @LastEditors: fangteng fangteng@yaomaitong.cn
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/store/module/common/mutation.js
 */
import {
  GET_USER_GUIDE_CONFIG,
  GET_TAG_LIST,
  GET_TAG_GROUP_LIST,
  GET_HOSPITAL_DEPARTMENT_LIST,
  GET_JOB_TITLE_LIST,
  GET_PROVINCE_LIST,
  GET_HOSPITAL_LEVEL_LIST,
  ADD_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  UPDATE_HOSPITAL_EDIT,
  MODULE_TYPE
} from '../../mutations-types'

export default {
  [GET_USER_GUIDE_CONFIG] (state, res) {
    state.userGuideConfig = res
  },
  [GET_TAG_LIST] (state,res) {
    state.tagList = res
  },
  [GET_TAG_GROUP_LIST] (state, res) {
    state.tagGroupList = res
  },
  [ADD_TAG](state, res) {
    state.tagList = [res,...state.tagList]
  },
  [DELETE_TAG] (state, tagId) {
    state.tagList = [...state.tagList].filter(v=>v.tagId!==tagId)
  },
  [UPDATE_TAG] (state, tag) {
    state.tagList = [...state.tagList].map(v=>{
      if (tag.tagId === v.tagId) {
        v = {...v,tagName: tag.tagName}
      }
      return v
    })
  },
  [GET_HOSPITAL_DEPARTMENT_LIST] (state, res) {
    state.hospitalDepartmentList = res.map(item => {
      if(item.childes.length === 0) {
        delete item.childes
      }else {
        item.childes = item.childes.map(child => {
          if(child.childes.length === 0){
            delete child.childes
          }
          return child
        })
      }
      return item
    })
  },
  [GET_JOB_TITLE_LIST] (state, res) {
    state.jobTitleList = res
  },
  [GET_PROVINCE_LIST] (state, res) {
    state.provinceList = res
  },
  [GET_HOSPITAL_LEVEL_LIST] (state, res) {
    state.hospitalLevelList = res
  },
  [UPDATE_HOSPITAL_EDIT] (state, res) {
    state.hospitalEdit = res
  },
  [MODULE_TYPE](state, value) {
    state.moduleType = value
  },

}
