
import flvjs from 'flv.js'
export default {
  data() {
    return {
      flvPlayer: null
    }
  },
  methods: {
    /**
         * 初始化 flv 播放器
         */
    initFlvPlayer(defaultOptions = this.defaultOptions, fun) {
      defaultOptions.video.type = 'customFlv'
      defaultOptions.video.url = this.filterUrl('.flv')
      defaultOptions.video.customType = {
        customFlv: video => {
          this.flvPlayer = flvjs.createPlayer(
            {
              type: 'flv',
              isLive: true,
              url: video.src
            },
            {
              autoCleanupSourceBuffer: true, // 对SourceBuffer进行自动清理
              autoCleanupMaxBackwardDuration: 12, //    当向后缓冲区持续时间超过此值（以秒为单位）时，请对SourceBuffer进行自动清理
              autoCleanupMinBackwardDuration: 8, // 指示进行自动清除时为反向缓冲区保留的持续时间（以秒为单位）。
              enableStashBuffer: false, // 关闭IO隐藏缓冲区
              isLive: true,
              lazyLoad: false
            }
          )
          this.flvPlayer.attachMediaElement(video)
          this.flvPlayer.load()
        }
      }
      fun()
      this.registerFlvPlayerEvent()
    },
    /**
         * 注册 flv 播放器事件
         */
    registerFlvPlayerEvent() {
      this.flvPlayer.on(flvjs.Events.ERROR, () => {
        console.log('视频错误信息回调')
        this.$emit('error')
        this.loadError = true
        clearInterval(this.timer)
        this.timer = null
        this.timer = setTimeout(() => {
          this.flvPlayer.unload()
          this.flvPlayer.load()
        }, 3000)
      })
    },
    /**
         * 卸载 flv 播放器事件
         */
    unInstallFlvPlayerEvent() {
      this.flvPlayer.pause()
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()
      this.flvPlayer.destroy()
      this.flvPlayer = null
    }
  }
}