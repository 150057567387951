// -------------------------------- react 工程 -------------------------------------
export const domainConfig = {
  localhost: 'http://localhost:8000',
  // 'localhost': 'http://localhost:8081',
  'home-dev.yaomaitong.net': 'https://factory-dev.quxueshu.com',
  'home-dev2.yaomaitong.net': 'https://factory-dev2.quxueshu.com',
  'home-dev3.yaomaitong.net': 'https://factory-dev3.quxueshu.com',
  'home-dev4.yaomaitong.net': 'https://factory-dev4.quxueshu.com',
  'home.yaomaitong.net': 'https://factory.quxueshu.com',
  'home.yaomaitong-pre.cn': 'https://factory.yaomaitong-pre.cn',
  'home.quxueshu.com': 'https://factory.quxueshu.com'
}
// ----------------------------------- 云脑 -------------------------------------
export const domainDataConfig = {
  'localhost': 'http://localhost:8081',
  'factory-dev.quxueshu.com': 'https://datax-dev.quxueshu.com',
  'factory-dev2.quxueshu.com': 'https://datax-dev.quxueshu.com',
  'factory-dev3.quxueshu.com': 'https://datax-dev.quxueshu.com',
  'factory-dev4.quxueshu.com': 'https://datax-dev.quxueshu.com',
  'factory.yaomaitong-pre.cn': 'https://datax.yaomaitong-pre.cn',
  'factory-pre.quxueshu.com': 'https://datax-pre.quxueshu.com',
  'factory.quxueshu.com': 'https://datax.quxueshu.com'
}
// ----------------------------------- 云脑2.0 -------------------------------------
export const panguConfig = {
  'localhost': 'http://localhost:5173',
  'factory-dev.quxueshu.com': 'http://cloudbrain-dev.quxueshu.com',
  'factory-dev2.quxueshu.com': 'https://cloudbrain-dev.quxueshu.com',
  'factory-dev3.quxueshu.com': 'https://cloudbrain-dev.quxueshu.com',
  'factory-dev4.quxueshu.com': 'https://cloudbrain-dev.quxueshu.com',
  'factory.yaomaitong-pre.cn': 'https://cloudbrain.yaomaitong-pre.cn',
  'factory-pre.quxueshu.com': 'https://cloudbrain-pre.quxueshu.com',
  'factory.quxueshu.com': 'https://cloudbrain.quxueshu.com'
}
// ---------------------------------- 代表端 ----------------------------------
export const domainMrConfig = {
  'localhost': 'http://localhost:8080',
  'factory-dev.quxueshu.com': 'https://kshmr-dev.yaomaitong.net',
  'factory-dev2.quxueshu.com': 'https://kshmr-dev2.yaomaitong.net',
  'factory-dev3.quxueshu.com': 'https://kshmr-dev3.yaomaitong.net',
  'factory-dev4.quxueshu.com': 'https://kshmr-dev4.yaomaitong.net',
  'factory-pre.quxueshu.com': 'https://kshmr.yaomaitong-pre.cn',
  'factory.quxueshu.com': 'https://kshmr.yaomaitong.cn'
}
// ---------------------------------- 医生端 ----------------------------------
export const domainDocConfig = {
  'localhost': 'http://localhost:8080',
  'factory-dev.quxueshu.com': 'https://kshdoctor-dev.yaomaitong.net',
  'factory-dev2.quxueshu.com': 'https://kshdoctor-dev2.yaomaitong.net',
  'factory-dev3.quxueshu.com': 'https://kshdoctor-dev3.yaomaitong.net',
  'factory-dev4.quxueshu.com': 'https://kshdoctor-dev4.yaomaitong.net',
  'factory-pre.quxueshu.com': 'https://kshdoctor.yaomaitong-pre.cn',
  'factory.quxueshu.com': 'https://kshdoctor.yaomaitong.cn'
}

const mapJson = {
  microAppConfig: {
    'localhost': 'http://localhost:5173',
    'factory-dev.quxueshu.com': 'https://factory-dev.quxueshu.com',
    'factory-dev2.quxueshu.com': 'https://factory-dev2.quxueshu.com',
    'factory-dev3.quxueshu.com': 'https://factory-dev3.quxueshu.com',
    'factory-dev4.quxueshu.com': 'https://factory-dev4.quxueshu.com',
    'factory-pre.quxueshu.com': 'https://factory-pre.quxueshu.com',
    'factory.quxueshu.com': 'https://factory.quxueshu.com'
  }
}

// 翻译链接
export function i18nUrl(type, host = location.host) {
  host = host.split(':')[0]
  return mapJson[type][host]
}
