import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入element
import './utils/elm-import'

import Moment from 'moment'
Vue.prototype.$moment = Moment

import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

// 轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// 配置基础组件
import BaseIndexVue from '@/baseComponents/commonBase/index.vue'
Vue.component('BaseIndexVue', BaseIndexVue)

import EventBus from '@/utils/eventBus'
Vue.prototype.$EventBus = EventBus // 创建实例

// table 无限滚动指令
import elTableInfiniteScroll from 'el-table-infinite-scroll'
Vue.use(elTableInfiniteScroll)

// 一键 copy
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// vue 无限滚动加载
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import { vueFunc } from './utils/vue-func'
Vue.use(vueFunc)

// 自动注册全局组件
import '@/components/autoRegister'

// 自动加载 svg 图标
const req = require.context('@/assets/svgicons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

// 自定义全局指令注册
import directive from '@/utils/directive.js'
Vue.use(directive)

// 初始化im
import { _tim } from '@/views/academic/tim/config/create-tim.js'
Vue.prototype.$tim = _tim

import { nanoid } from 'nanoid'
Vue.prototype.$nanoid = nanoid

// 引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader'
Vue.prototype.$AMap = AMapLoader.load({
  key: '2e820fac2a528b016a4cb95bb463e69e',
  version: '1.4.15',
  plugins: ['AMap.DistrictSearch'],
  AMapUI: {
    version: '1.1',
    plugins: ['geo/DistrictExplorer']
  }
})

// axios
import API from '@/apis/api-types.js'
import axios from '@/utils/request.js'
import { requireApi } from '@/apis/api-require.js'
Vue.prototype.$API = API
Vue.prototype.$axios = axios
Vue.prototype.$requireApi = requireApi

if (['publish'].includes(process.env.VUE_APP_BUILD_ENV)) {
  // ARMS 监控
  import('@/utils/ali-log.js').then(res => {
    const { loadARMS, parseUid, setUid } = res
    loadARMS()
    setUid(parseUid())
  })

  // 30分钟自动退出
  import('@/utils/astrict').then(res => {
    window.onload = res.default
  })
}

// 判断是否是移动端
import { isMobile, md5 } from '@/utils/util.js'
Vue.prototype.$md5 = md5
if (isMobile()) {
  // 动态导入vconsole
  import('@/utils/vconsole').then(({ initVconsole }) => {
    initVconsole(false)
  })
}
import '@/utils/micro-app.js'
Vue.config.ignoredElements = ['micro-app']

Vue.config.productionTip = false
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
