/*
 * @Author: your name
 * @Date: 2020-10-28 17:44:35
 * @LastEditTime: 2020-11-10 17:38:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/help/mutation.js
 */
import {
  GET_DOWNLOAD_FILE_LIST,
  READ_MESSAGE_STATUS,
  GET_MESSAGE_LIST,
  INIT_MESSAGE_LIST,
  GET_INDEX_HELP_MESSAGE_LIST,
  GET_INDEX_TOP_MESSAGE
  } from '../../mutations-types'
  
  export default {
    [GET_INDEX_TOP_MESSAGE] (state, list) {
      state.indexTopMessage = list
    },
    [GET_INDEX_HELP_MESSAGE_LIST] (state, list) {
      state.indexHelpList = list
    },
    [GET_DOWNLOAD_FILE_LIST] (state, list = []) {
      state.downLoadFileList = list
    },
    [READ_MESSAGE_STATUS] (state, itemId) {
      state.messageList = [...state.messageList].map(v => {
        if (v.messageId === itemId) {
          v = {...v, read: true}
        }
        return v
      })
    },
    [GET_MESSAGE_LIST] (state, list) {
      state.messageList = [...state.messageList,...list]
    },
    [INIT_MESSAGE_LIST] (state, list) {
      state.messageList = [...list]
    }
  }