import request from '@/utils/request'
import API from '@/apis/api-types'
export function createTag(req) {
  return request({
    url: API.createTag,
    method: 'post',
    data: req
  })
}
export function editTag({tagId, tagName}) {
  return request({
    url: API.editTag,
    method: 'post',
    params: {
      tagName,
      tagId
    }
  })
}
export function deleteTag(tagId = '') {
  return request({
    url: API.deleteTag,
    method: 'post',
    params: {
      tagId
    }
  })
}
export function uploadImage({file, bucketType}) {
  const params = new FormData()
  params.append('image', file)
  return request({
    url: `${API.uploadImage}?bucketType=${bucketType}`,
    method: 'post',
    data: params
  })
}
export function getUserGuide(location = 1, needContent = true) {
  return request({
    url: API.getUserGuide,
    method: 'get',
    params: {location, needContent}
  })
}
export function getTagList(params) {
  return request({
    url: API.getTagList,
    method: 'get',
    params
  })
}
export function getTagGroup() {
  return request({
    url: API.getTagGroup,
    method: 'get'
  })
}
export function getJobTitle() {
  return request({
    url: API.getJobTitle,
    method: 'get'
  })
}
export function getProvince() {
  return request({
    url: API.getProvince,
    method: 'get'
  })
}
export function getHospitalLevel() {
  return request({
    url: API.getHospitalLevel,
    method: 'get'
  })
}

export function getDepartmentList() {
  return request({
    url: API.getDepartmentList,
    method: 'get'
  })
}
export function updateHospitalEdit({factoryHospitalCode = '', hospitalId = ''}) {
  return request({
    url: API.updateHospitalEdit,
    method: 'post',
    params: {
      factoryHospitalCode,
      hospitalId
    }
  })
}
export function getAttributeDoctorFile(req) {
  return request({
    url: API.getAttributeDoctorFile,
    method: 'post',
    data: req
  })
}
export function getAttributeRepresentFile(req) {
  return request({
    url: API.getAttributeRepresentFile,
    method: 'post',
    data: req
  })
}
export function batchImportKnowledgePointModelFile(req) {
  return request({
    url: API.batchImportKnowledgePointModelFile,
    method: 'post',
    data: req
  })
}
