import Hls from 'hls.js'

window.Hls = Hls
export default {
  data() {
    return {
      hls: null,
      isOnce: 1,
      oldCurrentTime: 0
    }
  },
  methods: {
    /**
         * 初始化 hls 播放器
         */
    initHls(defaultOptions = this.defaultOptions, fun) {
      defaultOptions.video.type = 'hls'
      defaultOptions.video.url = this.filterUrl('.m3u8')
      defaultOptions.pluginOptions.hls = {
        debug: false,
        levelLoadingMaxRetry: 1000000,
        levelLoadingMaxRetryTimeout: 2000,
        nudgeMaxRetry: 0
      }
      fun()
      this.hls = this.dp.plugins.hls
      this.registerHlsEvent()
    },
    /**
         * 注册 hls 播放器事件
         */
    registerHlsEvent() {
      // 监听拉流状态
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              clearInterval(this.timer)
              this.timer = null
              this.timer = setTimeout(() => {
                this.hls.loadSource(this.filterUrl('.m3u8'))
              }, 2000)
              break
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hls.recoverMediaError()
              break
          }
        } else {
          const currentTime = parseInt(this.dp.video.currentTime)
          if (!this.dp.video.paused) {
            if (currentTime === this.oldCurrentTime) {
              console.count('直播没有暂停，且上次错误时间等于这次错误播放的时间')
              this.loadError = true
              this.hls.detachMedia()
              this.hls.loadSource(this.filterUrl('.m3u8'))
              this.hls.attachMedia(this.dp.video)
              this.$emit('error')
            } else {
              this.loadError = false
            }
          }
          this.oldCurrentTime = currentTime
        }
      })
    },
    /**
         * 卸载 hls 播放器
         */
    unInstallHlsEvent() {
      this.hls.destroy()
    }
  }
}