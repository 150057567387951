/*
 * @Author: your name
 * @Date: 2020-12-10 23:26:44
 * @LastEditTime: 2021-12-09 18:34:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/common/accout/actions.js
 */
import * as AccountService from "@/services/accountService";
import * as SceneService from "@/services/sceneService";
import * as Type from "../../mutations-types";
export default {
  getOrgAccountAmount({ commit, state }, params) {
    return AccountService.getOrgAccountAmount(params).then(
      res => {
        return Promise.resolve(res.data);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getOrgAccountList({ commit, state }, params) {
    return AccountService.getOrgAccountList(params).then(
      res => {
        commit(Type.GET_ORG_ACCOUNT_TREE, [res.data || {}]);
        return Promise.resolve(res.data);
      },
      rea => {
        return Promise.reject(rea.message);
      }
    );
  },
  getOrgAccountChildrenList({ commit, state }, params) {
    return AccountService.getOrgAccountChildrenList(params).then(
      res => {
        return Promise.resolve(res.data || []);
      },
      rea => {
        return Promise.reject(rea.message);
      }
    );
  },
  getAccountLiveSummary({ commit, state }, params) {
    return AccountService.getAccountLiveSummary(params).then(
      res => {
        commit(Type.GET_ACCOUNT_LIVE_SUMMARY, res.data);
        return Promise.resolve(res.data);
      },
      rea => {
        return Promise.reject(rea.message);
      }
    );
  },
  getAccountUserSummary({ commit, state }, params) {
    return AccountService.getAccountUserSummary(params).then(
      res => {
        commit(Type.GET_ACCOUNT_USER_SUMMARY, res.data);
      },
      rea => {
        commit(Type.GET_ACCOUNT_USER_SUMMARY, {});
        return Promise.reject(rea.message);
      }
    );
  },
  onRecallUserAccount({ commit, state }, params) {
    return AccountService.onRecallUserAccount(params).then(
      res => {
        commit(Type.RECALL_USER_ACCOUNT, params);
        return Promise.resolve(res.data);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  onRecallLiveAccount({ commit, state }, params) {
    return AccountService.onRecallLiveAccount(params).then(
      res => {
        commit(Type.RECALL_LIVE_ACCOUNT, params);
        return Promise.resolve(res.data);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  accountUserSummary() {
    return AccountService.getAccountUserSummary().then(
      res => {
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea.message);
      }
    );
  },
  getAccountBusinessList({ commit, state }, params) {
    return AccountService.getAccountBusinessList(params).then(
      res => {
        commit(Type.GET_ACCOUNT_BUSINESS_LIST, res.data);
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getAccountLiveList({ commit, state }, params) {
    return AccountService.getAccountLiveList(params).then(
      res => {
        commit(Type.GET_ACCOUNT_LIVE_LIST, [res.data || {}]);
      },
      rea => {
        commit(Type.GET_ACCOUNT_LIVE_LIST, []);
        return Promise.reject(rea);
      }
    );
  },
  getAccountUserList({ commit, state }, params) {
    return AccountService.getAccountUserList(params).then(
      res => {
        commit(Type.GET_ACCOUNT_USER_LIST, [res.data]);
      },
      rea => {
        console.log(rea);
        commit(Type.GET_ACCOUNT_USER_LIST, []);
        return Promise.reject(rea);
      }
    );
  },
  getAccountUserChildrenList({ commit, state }, params) {
    return AccountService.getAccountUserChildrenList(params).then(
      res => {
        return Promise.resolve(res.data || {});
      },
      rea => {
        commit(Type.GET_ACCOUNT_USER_LIST, []);
        return Promise.reject(rea);
      }
    );
  },
  downloadAccountCollectList({ commit, state }, params) {
    return AccountService.downloadAccountCollectList(params).then(
      res => {
        return Promise.resolve(res.data || "");
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  downloadAllDetailList({ commit, state }, params) {
    return AccountService.downloadAllDetailList(params).then(
      res => {
        return Promise.resolve(res.data || "");
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  downloadDelegationAllCollectList({ commit, state }, params) {
    return AccountService.downloadDelegationAllCollectList(params).then(
      res => {
        return Promise.resolve(res.data || "");
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  onDownAccountData({ commit, state }, req) {
    return AccountService.onDownAccountData(req).then(
      res => {
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  onTransferAccountUser({ commit, state }, data) {
    //总账户转账
    return AccountService.onTransferAccountUser(data).then(
      res => {
        commit(Type.TRANSFER_USER_ACCOUNT, data.data);
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  onTransferAccountLive({ commit, state }, req) {
    //直播转账
    return AccountService.onTransferAccountLive(req).then(
      res => {
        commit(Type.TRANSFER_LIVE_ACCOUNT, req);
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  onExportAccountLivePayData({ commit, state }, req) {
    return AccountService.onExportAccountLivePayData(req).then(
      res => {
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  onReviewLiveAccount({ commit, state }, req) {
    return AccountService.onReviewLiveAccount(req).then(
      res => {
        commit(Type.ACCOUNT_LIVE_REVIEW, req);
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  onPayLiveAccount({ commit, state }, req) {
    return AccountService.onPayLiveAccount(req).then(
      res => {
        commit(Type.PAY_LIVE_ACCOUNT, req);
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getAccountLivePayDetail({ commit, state }, req) {
    return AccountService.getAccountLivePayDetail(req).then(
      res => {
        commit(Type.GET_ACCOUNT_LIVE_PAYMENT_DETAIL, res.data.data);
        return Promise.resolve(res.data);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getTrainCode({ commit }, data) {
    return AccountService.getTrainCode(data).then(
      res => {
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getTrainDetail({ commit }, data) {
    return AccountService.getTrainDetail(data).then(
      res => {
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getWithdrawList({ commit }, data) {
    return AccountService.getWithdrawList(data).then(
      res => {
        commit(Type.WITHDRAW_CODE_TABLE, res.data.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        commit(Type.WITHDRAW_CODE_TABLE, []);
        return Promise.reject(rea);
      }
    );
  },

  getWithdrawMoney({ commit }) {
    return AccountService.getWithdrawMoney().then(
      res => {
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getInviteCodeTable({ commit }, data) {
    // 进行中的任务，table数据
    return AccountService.accountCodeList(data).then(
      res => {
        commit(Type.INVITE_CODE_TABLE, res.data.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        commit(Type.INVITE_CODE_TABLE, []);
        return Promise.reject(rea);
      }
    );
  },
  getCallBackCodeDetail({ commit }, data) {
    // 撤回任务，统计数据
    return AccountService.callBackCodeDetail(data).then(
      res => {
        commit(Type.SET_CALL_BACK_CODE_DETAIL, res.data || {});
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getRecallCodeTable({ commit }, data) {
    // 撤回任务，table数据
    return AccountService.callBackCodeList(data).then(
      res => {
        commit(Type.RECALL_CODE_TABLE, res.data.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        commit(Type.RECALL_CODE_TABLE, []);
        return Promise.reject(rea);
      }
    );
  },
  getRecallDialogList({ commit }, data) {
    // 撤回任务，任务面额list
    return AccountService.sceneCodeOverview(data).then(
      res => {
        commit(Type.RECALL_DIALOG_LIST, res.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        commit(Type.RECALL_DIALOG_LIST, []);
        return Promise.reject(rea);
      }
    );
  },
  getFactoryAllotUserList({ commit }, data) {
    // 撤回任务，撤回弹窗table数据--代表Tab
    return SceneService.getFactoryAllotUserList(data).then(
      res => {
        commit(Type.RECALL_DIALOG_TABLE, res.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getDoctorAllotUserList({ commit }, data) {
    // 撤回任务，撤回弹窗table数据--代表Tab
    return SceneService.getDoctorAllotUserList(data).then(
      res => {
        commit(Type.RECALL_DIALOG_TABLE, res.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getRecallDialogTableFactory({ commit }, data) {
    // 撤回任务，撤回弹窗table数据--厂家Tab
    return SceneService.getCanRecallAllotDepList(data).then(
      res => {
        commit(Type.RECALL_DIALOG_TABLE, res.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getWithdrawRepresentList({ commit }, data) {
    // 撤回邀请码，撤回弹窗table数据--厂家Tab
    return SceneService.getWithdrawRepresentList(data).then(
      res => {
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  postRecallCodeFactory({ commit }, data) {
    // 撤回任务提交，--厂家Tab
    return SceneService.recallCodeFactory(data).then(
      res => {
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  postCommonConvertCode({ commit }, data) {
    // 撤回任务提交 ---- 代表、厂家Tab
    return AccountService.commonConvertCode(data).then(
      res => {
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  trainWithdrawCode({ commit }, data) {
    // 撤回邀请码提交 ---- 代表、厂家Tab
    return AccountService.trainWithdrawCode(data).then(
      res => {
        commit(Type.UPDATE_TRAIN_PROGRAM_ITEM, { ...data, count: data.count });
        return Promise.resolve(res || []);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  getCashDetailTable({ commit }, data) {
    //积分账户明细Table
    return AccountService.cashDetailList(data).then(
      res => {
        commit(Type.CASH_DETAIl_TABlE, res.data.data || []);
        return Promise.resolve(res || []);
      },
      rea => {
        commit(Type.CASH_DETAIl_TABlE, []);
        return Promise.reject(rea);
      }
    );
  },
  // ------------------------ 收支明细 任务 end ----------------------
  getIsOrgSetAdmin({ commit }, data) {
    return AccountService.getIsOrgSetAdmin(data).then(
      res => {
        return Promise.resolve(res.data || false);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },

  getExcelResultData({ commit }, data) {
    return SceneService.getExcelResultData(data).then(
      res => {
        return Promise.resolve(res.data || false);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  downBatchPayLiveFee({}, data) {
    return AccountService.downBatchPayLiveFee(data).then(
      res => {
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  confirmBatchPayLiveFee({}, data) {
    return AccountService.confirmBatchPayLiveFee(data).then(
      res => {
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  downBatchSceneTaskAudit({}, data) {
    return SceneService.downBatchSceneTaskAudit(data).then(
      res => {
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  },
  confirmBatchSceneTaskAudit({}, data) {
    return SceneService.confirmBatchSceneTaskAudit(data).then(
      res => {
        return Promise.resolve(res);
      },
      rea => {
        return Promise.reject(rea);
      }
    );
  }
};
