<template>
  <div id="steps" class="steps" :style="`--height: ${height}px;--width: ${width}px; width: calc(100% + ${(list.length - 1) * height*0.20}px)`">
    <div
      v-for="(item, index) in list"
      :key="index"
      :style="`transform: translateX(${index * -(height*0.20)}px);`"
      class="step-item"
      :class="{act: activeItem - 1 >= index}"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  props: {
    value: Number,
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      height: 30,
      width: 300
    }
  },
  computed: {
    activeItem: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initResize()
    })
  },
  methods: {
    initResize() {
      const dom = document.querySelector('.step-item')
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          this.height = entry.target.offsetHeight
          this.width = entry.target.offsetWidth
        }
      })
      this.resizeObserver.observe(dom, {
        box: 'border-box',
        contentBoxSize: true,
        contentRect: true,
        borderBoxSize: true,
        childList: false // 关闭子元素监听
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
$scal: 0.35;
$angle: calc(var(--width) - var(--height) * #{$scal});
.steps {
  display: flex;
  height: 100%;
  overflow: hidden;
  .step-item {
    padding: 8px calc(var(--height) * #{$scal});
    background-color: #f5f5f5;
    position: relative;
    flex: 1;
    flex-shrink: 0;
    word-break: break-all;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon($angle 0%, 100% 50%, $angle 100%, 0% 100%, calc(var(--height) * #{$scal}) 50%, 0% 0%);
    &:nth-child(1) {
      clip-path: polygon($angle 0%, 100% 50%, $angle 100%, 0% 100%, 0 50%, 0% 0%);
    }
    &:nth-last-child(1) {
      clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, calc(var(--height) * #{$scal}) 50%, 0% 0%);
    }
  }
  .act {
    background-color: rgba(61, 97, 227, 0.15);
    color: $color-primary;
  }
}
</style>
