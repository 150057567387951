/*
 * @Author: your name
 * @Date: 2020-12-10 23:27:13
 * @LastEditTime: 2021-12-09 19:28:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/accout/mutation.js
 */
import {
  GET_ACCOUNT_LIVE_SUMMARY,
  GET_ACCOUNT_USER_SUMMARY,
  GET_ACCOUNT_LIVE_LIST,
  GET_ACCOUNT_USER_LIST,
  GET_ACCOUNT_LIVE_TRANSFER_LIST,
  GET_ACCOUNT_USER_TRANSFER_LIST,
  TRANSFER_USER_ACCOUNT,
  TRANSFER_LIVE_ACCOUNT,
  GET_ACCOUNT_LIVE_PAYMENT_DETAIL,
  GET_ACCOUNT_BUSINESS_LIST,
  PAY_LIVE_ACCOUNT,
  ACCOUNT_LIVE_REVIEW,
  RECALL_USER_ACCOUNT,
  // ------------------------ 收支明细 任务 --------------------------
  INVITE_CODE_TABLE,
  WITHDRAW_CODE_TABLE,
  SET_CALL_BACK_CODE_DETAIL,
  GET_WITHDRAW_MONEY,
  RECALL_CODE_TABLE,
  RECALL_DIALOG_LIST,
  RECALL_DIALOG_TABLE,
  CASH_DETAIl_TABlE,
  GET_ORG_ACCOUNT_TREE,
  UPDATE_ORG_USER_ACCOUNT,
  UPDATE_ORG_LIVE_ACCOUNT,
  // ------------------------ 收支明细 任务 end ----------------------
} from '../../mutations-types'

export default {
  [UPDATE_ORG_USER_ACCOUNT] (state, obj) {
    let call = (list)=>{
      return list.map(v=>{
        if (obj[v.orgId] !== undefined) {
          v = {...v, giftCount: obj[v.orgId]}
        }
        if (v.child) {
          v.child = call(v.child)
        }
        return v
      })
    }
    state.orgAccountTreeList = call(state.orgAccountTreeList)
  },
  [UPDATE_ORG_LIVE_ACCOUNT] (state, obj) {
    let call = (list)=>{
      return list.map(v=>{
        if (obj[v.orgId] !== undefined) {
          v = { ...v, giftCount: obj[v.orgId] };
        }
        if (v.child) {
          v.child = call(v.child)
        }
        return v
      })
    }
    state.orgAccountTreeList = call(state.orgAccountTreeList)
  },
  [GET_ORG_ACCOUNT_TREE] (state,res) {
    // 组织结构转义
    state.orgAccountTreeList = res
  },
  [GET_ACCOUNT_USER_SUMMARY] (state, res) {
    state.accountUserSummaryData = res
  },
  [GET_ACCOUNT_LIVE_SUMMARY] (state, res) {
    state.accountLiveSummaryData = res
  },
  [GET_ACCOUNT_LIVE_LIST] (state, res) {
    state.accountLiveList = res
  },
  [GET_ACCOUNT_USER_LIST] (state, res) {
    state.accountUserList = res
  },
  [GET_ACCOUNT_USER_TRANSFER_LIST] (state, res) {
    state.accountUserTransferList = res
  },
  [GET_ACCOUNT_LIVE_TRANSFER_LIST] (state, res) {
    state.accountLiveTransferList = res
  },
  [TRANSFER_USER_ACCOUNT] (state, item) {
    state.accountUserTransferList = [...state.accountUserTransferList].map(v=>{
      if (v.id === item.id) {
        v = {...v,money:v.money+item.money}
      }
      return v
    })
  },
  [RECALL_USER_ACCOUNT] (state, item) {
    state.accountUserTransferList = [...state.accountUserTransferList].map(v=>{
      if (v.id === item.id) {
        v = {...v,money:v.money+item.money}
      }
      return v
    })
  },
  [TRANSFER_LIVE_ACCOUNT] (state, item) {
    state.accountLiveTransferList = [...state.accountLiveTransferList].map(v=>{
      if (v.liveId === item.id) {
        v = {...v,money:v.money+item.money}
      }
      return v
    })
  },
  [GET_ACCOUNT_LIVE_PAYMENT_DETAIL] (state, list) {
    state.accountLivePayData = list
  },
  [ACCOUNT_LIVE_REVIEW] (state, item) {
    state.accountLivePayData = [...state.accountLivePayData].map(v=>{
      if (v.liveId === item.liveId && item.doctorId === v.doctor.id) {
        v = {...v, reviewStatus:1}
      }
      return v
    })
  },
  [PAY_LIVE_ACCOUNT] (state, item) {
    state.accountLivePayData = [...state.accountLivePayData].map(v=>{
      if (v.liveId === item.liveId && item.doctorId === v.doctor.id) {
        v = {...v, reviewStatus:4}
      }
      return v
    })
  },
    [GET_ACCOUNT_BUSINESS_LIST](state,list) {
    state.accountBusinessList = list.data
  },
  [INVITE_CODE_TABLE](state, data) {  // 进行中的任务，table数据
    state.inviteCodeTable = data;
  },
  [WITHDRAW_CODE_TABLE](state, data) {  // 培训进行中的邀请码，table数据
    state.withdrawCodeTable = data;
  },
  [SET_CALL_BACK_CODE_DETAIL](state,data) { // 撤回邀请码 统计数据
    state.callBackCodeDetail = data;
  },
  [GET_WITHDRAW_MONEY](state,data) { // 撤回邀请码 统计数据
    state.withdrawMoney = data;
  },
  [RECALL_CODE_TABLE](state, data) {  // 撤回任务，table数据
    state.recallCodeTable = data;
  },
  [RECALL_DIALOG_LIST](state, data) { // 撤回任务，任务面额list
    state.recallDialogList = data;
  },
  [RECALL_DIALOG_TABLE](state, data) {  // 撤回任务，撤回table数据
    state.recallDialogTable = data;
  },
  [CASH_DETAIl_TABlE](state, data) {  // 积分账户明细Table
    state.cashDetailTable = data;
  },
  // ------------------------ 收支明细 邀请码 end ----------------------
}
