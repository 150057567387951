<!--
 * @Author: your name
 * @Date: 2020-11-26 14:08:08
 * @LastEditTime: 2021-09-03 10:02:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/baseComponents/CheckTag.vue
-->
<template>
  <div class="tag" @click.stop="onSelect" v-bind:class="{active: (checked || selected === value),disabled:disabled}">{{text}}</div>
</template>
<script>
export default {
  name: 'CheckTag',
  props: {
    text: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
    disabled: {
      type:Boolean,
      default:false
    },
    checked: {
      type: Boolean,
      default: false
    },
    selected: {
      type: null,
      default: ''
    }
  },
  methods: {
    onSelect () {
    if (this.disabled) return
    this.$emit('onSelect', this.value)
    }
  }
}
</script>
<style lang="scss" scoped>
  .tag {
    display: inline-block;
    color: $color-info;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    min-width: 100px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;;
    font-weight: 500;
    text-align: center;
    transition: all 100ms;
    &.disabled {
      cursor: not-allowed;
      color: #ddd;
    }
    &.active,&:hover {
      color: $color-white;
      background-color: $color-primary;
    }
  }
    // height 32px
    // line-height 30px
    // min-width 100px
    // padding-left 20px
    // padding-right 20px
    // text-align center
    // &.active
    //   color $color-white
</style>
