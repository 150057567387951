/*
 * @Author: your name
 * @Date: 2020-12-23 19:19:28
 * @LastEditTime: 2020-12-23 19:23:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/live/index.js
 */
import actions from './action'
import mutations from './mutation'
const state = {
  liveQrCodePreviewData:{},//直播预览二维码对象
  liveDefaultReviewSec: 10, // 直播会议最低观看时长
}
const getters = {
  liveQrCodePreviewData: () => state.liveQrCodePreviewData
}
export default {
  state,
  getters,
  actions,
  mutations
}
