import wx from 'weixin-js-sdk'
import request from '@/utils/request'
import { getLocalUrl, isFunc } from '@/utils/util.js'
import { getToken } from '@/utils/auth'
import API from '@/apis/api-types'
/**
 * wxsdk初始化
 * @param {Object} data
 *  {Object} config 补充wx.config参数，可配置jsApiList，openTagList，debug等
 *  {Function} success 成功回调
 *  {Function} failed 失败回调
 *  {String} url 接口参数地址 默认window.location.href
 */
export const initWxConfig = (data = {}) => {
  if (!getToken()) return
  const { config, success, failed, url } = data
  request({
    method: 'get',
    url: API.wxConfig,
    params: {
      uType: 'FACTORY',
      url: decodeURIComponent(window.__wxjs_is_wkwebview ? window.wxShareLink : url)
    }
  }).then(res => {
    wx.config({
      debug: false,
      appId: res.data.appId,
      timestamp: res.data.timestamp,
      nonceStr: res.data.nonceStr,
      signature: res.data.signature,
      ...config
    })
    wx.error(msg => {
      console.log('ConfigFailed:', msg)
    })
    isFunc(success) && success()
  })['catch'](failed)
}
/**
 * 设置分享
 * @param {Object} data {url:链接 ,title:标题 ,image:图片 ,content:内容 ,func:成功回调 ,wxConfig:(默认可不传)微信config配置,是否debug等}
 */
export const onWxShare = (data = {}) => {
  if (!window.__wxjs_is_wkwebview) {
    setTimeout(() => {
      init(data)
    }, 500)
  } else {
    init(data)
  }
}

function init(data) {
  const {
    url = getLocalUrl(),
    title = '趣学术SaaS系统-厂家端',
    image = '',
    content = '',
    func,
    wxConfig
  } = data
  const config = {
    jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData', 'showOptionMenu', 'onMenuShareTimeline', 'onMenuShareAppMessage'],
    ...wxConfig
  }
  initWxConfig({ config, success, failed, url })

  function success() {
    wx.ready(() => {
      wx.error(msg => {
        console.log('shareFailed:', msg)
      })
      setShowMenuOptions()
      setWxTimeLine({
        title: title,
        url: url,
        image: image,
        desc: content,
        func: func
      })
      setWxMessageData({
        title: title,
        url: url,
        image: image,
        desc: content,
        func: func
      })
    })
  }

  function failed() {
  }
}

/**
 * 隐藏菜单
 * @param {Object} data {allowShare:是否允许分享 ,wxConfig:(默认可不传)微信config配置,是否debug等}
 */
export const wxHideOptionMenu = (allowShare = false, wxConfig = {}) => {
  if (allowShare) return
  const config = {
    jsApiList: ['hideOptionMenu', 'showOptionMenu'],
    ...wxConfig
  }
  initWxConfig({ config, success, failed, url: getLocalUrl()})

  function success() {
    wx.ready(() => {
      setHideMenuOptions()
    })
  }

  function failed() {
  }
}

/**
 * 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容(注：不可单独始使用方法，需使用onWxShare先，如有需要再次改变分享内容，可使用此方法)
 * @param {Object} param0 {title：标题， link:链接 ,imgUrl:封面 ,desc:描述 ,func:成功回调}
 */
export const setWxTimeLine = (data = {}) => {
  const { title, url, image, desc = '', func } = data
  wx.updateTimelineShareData({
    title: title, // 分享标题
    link: url, // 分享链接
    imgUrl: image, // 分享图标
    desc: desc,
    success: function() {
      isFunc(func) && func()
    }
  })
  wx.onMenuShareTimeline({
    title: title, // 分享标题
    link: url, // 分享链接
    imgUrl: image, // 分享图标
    success: function() {
      isFunc(func) && func()
    }
  })
}
/**
 * 自定义“分享给朋友”及“分享到QQ”按钮的分享内容(注：不可单独始使用方法，需使用onWxShare先，如有需要再次改变分享内容，可使用此方法)
 * @param {Object} param0 {title：标题， link:链接 ,imgUrl:封面 ,desc:描述 ,func:成功回调}
 */
export const setWxMessageData = (data = {}) => {
  const { title, url, image, desc = '', func } = data
  wx.updateAppMessageShareData({
    title: title, // 分享标题
    link: url, // 分享链接
    imgUrl: image, // 分享图标
    desc: desc,
    success: function() {
      isFunc(func) && func()
    }
  })
  wx.onMenuShareAppMessage({
    title: title, // 分享标题
    link: url, // 分享链接
    imgUrl: image, // 分享图标
    desc: desc,
    success: function() {
      isFunc(func) && func()
    }
  })
}
/**
 * 设置菜单开（注：由于每个页面已经调用wxHideOptionMenu方法，jsApiList已经注入config,可单独使用）
 */
export const setShowMenuOptions = () => {
  wx.showOptionMenu()
}
/**
 * 设置菜单关（注：由于每个页面已经调用wxHideOptionMenu方法，jsApiList已经注入config,可单独使用）
 */
export const setHideMenuOptions = () => {
  wx.hideOptionMenu({
    success: () => {
    },
    fail: rea => {
      console.log('hide失败', rea)
      // setHideMenuOptions()
    }
  })
}
/**
 *  wx.config 的封装
 *  // configObj wx.config()  固定必填属性
 *  @param configObj:object {
 *		beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
 *		debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
 *		appId: '', // 必填，企业微信的corpID
 *		timestamp: , // 必填，生成签名的时间戳
 *		nonceStr: '', // 必填，生成签名的随机串
 *		signature: '',// 必填，签名，见 附录-JS-SDK使用权限签名算法
 *
 * 	}
 *
 *  // changeAttribute   wx.config() 可定义的必填和可选属性
 *  @param changeAttribute: object  {
 * 		jsApiList: [] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
 * 		openTagList: ["wx-open-launch-weapp"]
 * 	}
 *  @param callBack: function; wx.config()成功后触发
 *  @param errBack: function; wx.config()失败后触发 err错误信息
 *  @return null
 *
 */
export const wxConfigFun = (configObj, changAttribute, callBack, errBack) => {
  const { beta = true, debug = false, appId, timestamp, nonceStr, signature } = configObj
  const obj = Object.assign(
    {},
    { beta, debug, appId, timestamp, nonceStr, signature },
    changAttribute
  )
  wx.config(obj)
  wx.ready(() => {
    callBack && callBack()
  })
  wx.error(err => {
    errBack && errBack(err)
  })
}

