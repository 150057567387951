/*
 * @Author: your name
 * @Date: 2020-10-28 17:46:02
 * @LastEditTime: 2023-03-15 18:18:34
 * @LastEditors: fangteng fangteng@yaomaitong.cn
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/store/module/common/index.js
 */
import actions from './action'
import mutations from './mutation'
const state = {
  sceneTypeList: [{name: '线上科室会', value: '1'}, {name: '调研', value: '4'}], // 场景列表 后续加上 {name: '问卷', value: '2'}, {name: '拜访', value: '3'},
  teamList: [], // 部门下团队列表
  representList: [], // 代表列表
  hospitalDepartmentList: [], // 医院科室列表
  jobTitleList: [], // 医生职称列表
  provinceList: [], // 医院省份
  hospitalEdit: [], // 医院编码
  hospitalLevelList: [], // 医院等级
  certifyStatusList:[
    {title:'所有状态',value:''},
    {title:'未认证',value:0},
    {title:'工作证明审核通过',value:1},
    {title:'工作证明审核中',value:2},
    {title:'工作证明未通过',value:3},
    {title:'身份认证完成',value:11},
    {title:'实名验证审核中',value:13},
    {title:'实名验证未通过',value:12},
    {title:'播客认证完成',value:21},
    {title:'播客认证审核中',value:20},
    {title:'播客认证未通过',value:22},
    {title:'手机认证通过',value:23},
    {title:'手机认证未通过',value:24}
  ],
  tagList: [],//标签列表
  tagGroupList:[], // 标签组列表
  userGuideConfig: {},
  bindStatusList: [
    {title: '暂无代表绑定服务', value: '0'},
    {title: '有代表绑定服务', value: '1'},
    {title: '暂未满足绑定条件', value: '2'}
  ],
  moduleMenuObj: {
    'OrginazationList': {
      url: '/help/list/11?name=组织管理',
      name: '组织管理'
    },
    'Doctor': {
      url: '/help/list/12?name=医生管理',
      name: '医生管理'
    },
    'AcademicList': {
      url: '/help/list/13?name=活动管理',
      name: '活动管理'
    },
    'MaterialList': {
      url: '/help/list/14?name=素材管理',
      name: '素材管理'
    },
    'Account': {
      url: '/help/list/15?name=财务管理',
      name: '财务管理'
    },
    'CourseManagement': {
      url: '/help/list/20?name=课程管理',
      name: '课程管理'
    },
    'TrainPrograms': {
      url: '/help/list/20?name=培训活动',
      name: '培训活动'
    },
    'ApproverList': {
      url: '/help/list/21?name=我的审批',
      name: '我的审批'
    },
    'CommonList': {
      url: '/help/detail/e5abc17dd4567d08305e8a533b6374c5',
      name: '公共资源库'
    },
  },
  noviceMenu: ['Index','DownloadCenter'],     // 新手指导
  moduleType: null,      // 侧边栏导航模块，对应的帮助中心模块
}
const getters = {
  sceneOptionList: () => [{name: '所有场景', value: ''}, ...state.sceneTypeList],
  representList: () => state.representList,
  userGuideConfig: () => state.userGuideConfig,
  hospitalDepartmentList: () => [{label: '所有科室', id: ''},...state.hospitalDepartmentList],
  jobTitleList: () => [{label: '所有职称', id: ''},...state.jobTitleList],
  provinceList: () => [{ provinceName: '所在省份', provinceId: '' }, ...state.provinceList],
  hospitalLevelList: () => [{content: '医院等级', id: ''},...state.hospitalLevelList],
  certifyStatusList: () => state.certifyStatusList,
  hospitalEdit: () => state.hospitalEdit,
  tagGroupList: () => {
    let list = []
    state.tagGroupList.forEach(v => {
      if (v.groupCode === 'PRODUCT_TAG_GROUP') {
        list[0] = v
      } else if (v.groupCode === 'SPREAD_DEPARTMENT_GROUP') {
        list[1] = v
      } else if (v.groupCode === 'CUSTOMIZE_TAG_GROUP') {
        list[2] = v
      }
    })
    return list
  },
  tagList: ()=>state.tagList,
  bindStatusList: () => state.bindStatusList,
  moduleType: state => state.moduleType,
  moduleMenuObj: state => state.moduleMenuObj,
}
export default {
  state,
  getters,
  actions,
  mutations
}
