/*
 * @Author: your name
 * @Date: 2020-12-10 17:33:12
 * @LastEditTime: 2022-03-23 16:49:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/org/index.js
 */

import actions from './action'
import mutations from './mutation'
import { getUserInfo} from '@/utils/auth'
const state = {
  factoryId: "", // 当前厂家ID
  orgList: [], // 部门组织层级
  factoryName: "",
  isTopOrg: "",
  orgManageList: [],
};
const getters = {
  factoryId: () => state.factoryId,
  factoryName: () => state.factoryName,
  orgList: () =>  state.orgList,
  orgManageList: () =>  state.orgManageList,
  isTopOrg: state => {
    let obj = getUserInfo() || {};
    return obj.isTopOrg;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
