import * as Types from '../../mutations-types';

export default {
  [Types.SET_TRAIN_CLASSIFY_LIST](state, data) {    // 代表培训内容分类
    state.trainClassifyList = data;
  },
  [Types.CREAT_TRAIN_CLASSIFY_LIST](state, data) {    // 创建代表培训内容分类
    state.trainClassifyList = [data,...state.trainClassifyList]
  },
  [Types.REMOVE_TRAIN_CLASSIFY_LIST](state, classifyId) {    // 删除代表培训内容分类
    state.trainClassifyList = [...state.trainClassifyList].filter(v=>v.id!==classifyId)
  },
  [Types.SET_TRAIN_COURSE_LIST](state, list) {      // 厂家端课程列表
    state.trainCourseList = list;
  },
  [Types.ADD_TRAIN_COURSE_ITEM](state, current) {      // 厂家端课程列表
    state.trainCourseList = [current,...state.trainCourseList];
  },
  [Types.DELETE_TRAIN_COURSE_ITEM](state, id) {
    state.trainCourseList = [...state.trainCourseList].filter(v=>v.id !== id)
  },
  [Types.UPDATE_TRAIN_COURSE_ITEM](state, current) {      // 厂家端课程列表
    state.trainCourseList = [...state.trainCourseList].map(v=>{
      if (v.id === current.id) {
        v = {...v,...current}
      }
      return v
    })
  },
  [Types.SET_TRAIN_PROGRAM_LIST](state, list) {
    state.trainProgramList = list;
  },
  [Types.ADD_TRAIN_PROGRAM_ITEM](state, current) {
    state.trainProgramList = [current,...state.trainProgramList];
  },
  [Types.DELETE_TRAIN_PROGRAM_ITEM](state, current) {
    state.trainProgramList =[...state.trainProgramList].filter(v=>v.id !== current.programId)
  },
  [Types.UPDATE_TRAIN_PROGRAM_ITEM](state, current) {
    state.trainProgramList = [...state.trainProgramList].map(v=>{
      if (v.id === current.sceneId) {
        v = {...v,...current}
      }
      return v
    })
  },
  [Types.UPDATE_TRAIN_PROGRAM_LIST](state, current) {
    state.trainProgramList = [...state.trainProgramList].map(v => {
      if (v.id === current.id) {
        v = { ...v, count: v.count + current.allotCount }
      }
      return v
    })
  }
}
