// 不需要侧边栏的页面
export default [
  {
    path: '/material/article/preview/:id',
    name: 'ArticlePreview',
    component: () => import(/* webpackChunkName: "Material" */ '@/views/material/TabKnowledgePreview'),
    meta: {
      title: '素材预览',
      sidebar: false
    }
  }
]
