import request from '@/utils/request'
import API from '@/apis/api-types'

export function getFactoryAllotUserList(options) {
  // 已经分配任务的代表列表
  return request({
    url: API.getFactoryAllotUserList,
    method: 'post',
    data: options
  })
}
export function getDoctorAllotUserList(options) {
  // 已经分配任务的代表列表
  return request({
    url: API.getDoctorAllotUserList,
    method: 'post',
    data: options
  })
}
export function getCanRecallAllotDepList(req) {
  // 已经分配任务的厂家列表
  return request({
    url: API.getCanRecallAllotDepList,
    method: 'get',
    params: req
  })
}
export function getWithdrawRepresentList(req) {
  return request({
    url: API.getWithdrawRepresentList,
    method: 'get',
    params: req
  })
}

export function getFactoryAllotDepList(req) {
  // 已经分配任务的子厂家列表
  return request({
    url: API.getFactoryAllotDepList,
    method: 'get',
    params: req
  })
}

export function recallCodeFactory(options) {
  // 撤回厂家任务
  return request({
    url: API.recallCodeFactory,
    method: 'post',
    data: options
  })
}
export function getExcelResultData(options) {
  // 分配表格
  return request({
    url: API.getExcelResultData,
    method: 'get',
    params: options
  })
}
export function downBatchSceneTaskAudit(options) {
  return request({
    url: API.downBatchSceneTaskAudit,
    method: 'post',
    data: options
  })
}
export function confirmBatchSceneTaskAudit(options) {
  return request({
    url: API.confirmBatchSceneTaskAudit,
    method: 'get',
    params: options
  })
}

