import Cookies from 'js-cookie'
import { getTopDomain } from './util'
import moment from 'moment'
import store from '@/store'

export function getToken() {
  // 先获取personInfo的token
  return getUserInfo().token || ''
}

export function getPersonToken() {
  // 先获取personInfo的personToken
  return getPersonInfo().personToken || ''
}

export const getUserInfo = () => {
  // 只通过personInfo获取info
  const personInfo = getCookie('_personInfo')
  const orgLoginVo = personInfo ? JSON.parse(personInfo).orgLoginVo : {}
  return orgLoginVo
}

export const setPersonInfo = (cvalue = {}, cname = '_personInfo', exdays) => {
  if (!exdays) {
    if (process.env.VUE_APP_BUILD_ENV !== 'development') {
      exdays = new Date(moment().add('30', 'm').format('YYYY-MM-DD HH:mm:ss'))
    }
  }
  store.commit('SET_METADATA_USER_INFO', cvalue)
  cvalue = JSON.stringify(cvalue)
  setCookie(cvalue, cname, exdays)
}

export const getPersonInfo = (cname = '_personInfo') => {
  const userInfo = getCookie(cname)
  return userInfo ? JSON.parse(userInfo) : {}
}

export const clearPersonInfo = () => {
  deleteCookie('_personInfo')
}

export const setCookie = (cvalue = '', cname = '', exdays = 30) => {
  Cookies.set(cname, cvalue, {expires: exdays, path: '/', domain: getTopDomain()})
}

export const getCookie = cname => {
  return Cookies.get(cname)
}

export const deleteCookie = cname => {
  Cookies.remove(cname, {path: '/', domain: getTopDomain()})
}

function hasPermission(permission) {
  return store.state.user.permission?.some(v => {
    return v === permission
  })
}
export function auth(value) {
  let auth
  if (typeof value === 'string') {
    auth = hasPermission(value)
  } else {
    auth = value.some(item => {
      return hasPermission(item)
    })
  }
  return auth
}

export function authAll(value) {
  const auth = value.every(item => {
    return hasPermission(item)
  })
  return auth
}
