import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  data() {
    return {
      erd: null,
      _ChartResizeTimmer: null, // 定时器
      wait: 200,
      count: 0, // 第一次加载取消resize
      emptyImg: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA2NCA0MSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbFJ1bGU9ImV2ZW5vZGQiPg0KICAgIDxlbGxpcHNlIGZpbGw9IiNkZGQiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3IiAvPg0KICAgIDxnIGZpbGxSdWxlPSJub256ZXJvIiBzdHJva2U9IiM5OTkiPg0KICAgICAgPHBhdGgNCiAgICAgICAgZD0iTTU1IDEyLjc2TDQ0Ljg1NCAxLjI1OEM0NC4zNjcuNDc0IDQzLjY1NiAwIDQyLjkwNyAwSDIxLjA5M2MtLjc0OSAwLTEuNDYuNDc0LTEuOTQ3IDEuMjU3TDkgMTIuNzYxVjIyaDQ2di05LjI0eiIgLz4NCiAgICAgIDxwYXRoDQogICAgICAgIGQ9Ik00MS42MTMgMTUuOTMxYzAtMS42MDUuOTk0LTIuOTMgMi4yMjctMi45MzFINTV2MTguMTM3QzU1IDMzLjI2IDUzLjY4IDM1IDUyLjA1IDM1aC00MC4xQzEwLjMyIDM1IDkgMzMuMjU5IDkgMzEuMTM3VjEzaDExLjE2YzEuMjMzIDAgMi4yMjcgMS4zMjMgMi4yMjcgMi45Mjh2LjAyMmMwIDEuNjA1IDEuMDA1IDIuOTAxIDIuMjM3IDIuOTAxaDE0Ljc1MmMxLjIzMiAwIDIuMjM3LTEuMzA4IDIuMjM3LTIuOTEzdi0uMDA3eiINCiAgICAgICAgZmlsbD0iI2UxZTFlMSIgLz4NCiAgICA8L2c+DQogIDwvZz4NCjwvc3ZnPg=='
    }
  },
  async mounted() {
    if (this.$parent['autoResize']) {
      this.count = 0
      this._ChartResizeOnResize()
      window.addEventListener('resize', this.windowListen)
    }
  },
  methods: {
    // 图表自适应
    _ChartResizeOnResize() {
      this.erd = elementResizeDetectorMaker()
      this.erd.listenTo(this.$el, this.listenToResize)
    },
    listenToResize(ele) {
      if (!this.myCharts) return
      const width = ele.offsetWidth
      const height = ele.offsetHeight
      if (width === this.recordOldValue?.width && height === this.recordOldValue?.height) return
      if (this._ChartResizeTimmer) clearTimeout(this._ChartResizeTimmer)
      this._ChartResizeTimmer = setTimeout(() => {
        this.count++
        if (this.count === 1) return
        this.myCharts && this.myCharts.resize()
      }, this.wait)
      this.recordOldValue = { width, height }
    },
    windowListen() {
      this.listenToResize(document.getElementById('app'))
    }
  },
  beforeDestroy() {
    if (this.$attrs['auto-resize']) {
      window.removeEventListener('resize', this.windowListen)
      this.erd.removeListener(this.$el, this.listenToResize)
    }
  }
}
