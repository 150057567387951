<template>
  <el-pagination
    v-show="isShow"
    :ref="initItem.ref"
    :class="initItem.class"
    :style="initItem.style"
    :total="initItem.total"
    :current-page="initItem.currentPage"
    :page-size="initItem.pageSize"
    :page-sizes="initItem.pageSizes"
    :page-count="initItem.pageCount"
    :background="initItem.background"
    :small="initItem.small"
    :layout="initItem.layout"
    @current-change="activate($event,'currentChange')"
    @size-change="activate($event,'sizeChange')"
  />
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      keyMap: {
        currentChange: 'CurrentPage',
        sizeChange: 'pageSizes'
      }
    }
  },
  computed: {
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  methods: {
    activate(event, type = 'currentChange') {
      this.$emit('onActivate', {
        value: event,
        key: this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    }
  }

}
</script>

