<template>
  <div
    v-show="isShow"
    :ref="initItem.ref"
    class="base-vue-box"
    :class="initItem.class"
    :style="initItem.style"
  >
    <div v-if="initItem.layoutLeft || initItem.leftText">
      <span :class="initItem.leftClass" :style="initItem.leftStyle">{{ initItem.leftText }}</span>
    </div>
    <el-date-picker
      :ref="initItem.ref"
      v-model="value"
      v-debounce="[activate, 'input']"
      :type="initItem.type"
      class="flex"
      :class="initItem.dateClass"
      :style="initItem.dateStyle"
      :clearable="initItem.clearable"
      :disabled="initItem.disabled"
      :range-separator="initItem.rangeSeparator"
      :unlink-panels="initItem.unlinkPanels"
      :align="initItem.align"
      :picker-options="initItem.pickerOptions"
      :format="initItem.format"
      :value-format="initItem.valueFormat"
      :start-placeholder="initItem.startPlaceholder"
      :end-placeholder="initItem.endPlaceholder"
      :placeholder="initItem.placeholder"
      :default-time="initItem.defaultTime"
      :editable="false"
      @change="activate($event,'change')"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseDatePicker',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resetItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      initVal: null,
      keyMap: {
        change: 'datePicker'
      }
    }
  },
  computed: {
    value: {
      get() {
        if (this.initVal === '') {
          return this.initVal
        }
        return this.initVal  || this.initItem.value
      },
      set(val) {
        this.initVal = val || ''
      }
    },
    initItem() {
      return {...this.item, ...this.resetItem}
    },
    isShow() {
      return typeof this.resetItem.isShow === 'undefined' ? true : this.resetItem.isShow
    }
  },
  methods: {
    activate(event, type = 'change') {
      this.initVal = null
      this.$emit('onActivate', {
        value: event,
        key: this.item.key || this.keyMap[type],
        methodType: type,
        item: this.item,
        currenRef: this
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.base-vue-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .flex {
    flex: 1;
    background: #fafafa;
    ::v-deep .el-range-input {
      background: #fafafa;
    }
  }
}
</style>
