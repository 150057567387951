<template>
  <components :is="`data-chart-${showTypeName}`" class="data-chart" :chart-data="formatPublicData" />
</template>

<script>
import { cloneDeep, merge, uniq, orderBy} from 'lodash-es'
import { defaultChartOption } from './utils/config'
import { JsonToExcel } from '@/utils/util'

export default {
  name: 'DataChart',
  props: {
    showTypeName: {
      type: String,
      default: 'table'
    },
    data: {
      type: Object,
      default: () => {
        return {
          desc: { colDesc: [], groupByDesc: [] },
          data: [],
          modelName: ''
        }
      }
    },
    formChartOption: {
      type: Object,
      default: () => ({})
    },
    jsCodeSnippet: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      loadsh: {
        cloneDeep,
        uniq,
        orderBy
      }
    }
  },
  computed: {
    formatPublicData() {
      const { desc: { colDesc = [], groupByDesc = [] } = {}, data = [], modelName = '' } = this.data

      // 处理 X 轴数据
      let xValue = []
      let xGroupByDesc = {}
      xValue = groupByDesc?.map(groupByDescItem => {
        if (groupByDescItem.colValues) {
          groupByDescItem.colValuesDesc = {}
          groupByDescItem.colValues.split(',')?.forEach(item => {
            const [key, value] = item.split(':')
            groupByDescItem.colValuesDesc[key] = value
          })
        }
        if (groupByDescItem.xaxis) xGroupByDesc = groupByDescItem
        return groupByDescItem
      })

      let dataValue = Object.keys(xGroupByDesc).length ? data?.filter(item => item && item[xGroupByDesc.colDesc]) : data
      if (xGroupByDesc?.colValuesDesc && Object.keys(xGroupByDesc?.colValuesDesc).length) {
        const { colDesc: colDescItem, colValuesDesc } = xGroupByDesc
        dataValue = dataValue.map(item => {
          if (typeof item[colDescItem] === 'number') {
            item[colDescItem] = colValuesDesc[item[colDescItem]]
          }
          return item
        })
      }

      return {
        colDesc,
        modelName,
        xGroupByDesc,
        groupByDesc: xValue,
        data: dataValue
      }
    },
    defaultChartOption() {
      return cloneDeep(defaultChartOption[this.showTypeName])
    },
    autoResize() {
      return this.$attrs.autoResize || this.showTypeName !== 'table'
    }
  },
  methods: {
    // 导出数据
    exportExal() {
      const { colDesc, groupByDesc, data, modelName } = this.formatPublicData
      if (data.length === 0) {
        this.$message.error('暂无数据')
        return false
      }
      const header = [...groupByDesc.map(item => item.colDesc), ...colDesc]
      const json = data.map(item => {
        return header.map(headerItem => {
          return item[headerItem]
        })
      })
      JsonToExcel(modelName, [header, ...json])
    },
    merge(config) {
      const mergeOpt = merge({}, config, this.defaultChartOption, this.formChartOption)
      let conf = mergeOpt || {}
      try {
        const dealFun = mergeOpt => {
          // 不可处理数据，仅处理样式
          console.log('可用变量：mergeOpt')
          eval(this.jsCodeSnippet)
          return mergeOpt
        }
        conf = dealFun(conf)
      } catch (error) {
        console.log(error)
      }
      return conf
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-chart {
  height: 100%;
  background-color: #fff;
}
</style>
