import HelpLayout from '@/layout/views/help'
export default {
  path: '/help',
  name: 'HelpLayout',
  component: HelpLayout,
  children: [{
    path: 'list/:id',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/help/HelpList'),
    meta: {
      requiresAuth: true,
      title: '趣学术帮助中心',
      key: 'list'
    }
  },
  {
    path: 'list',
    name: 'HelpList',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/help/HelpList'),
    meta: {
      requiresAuth: true,
      title: '趣学术帮助中心',
      key: 'list-d'
    }
  },
  {
    path: 'detail/:id',
    name: 'HelpDetail',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/help/HelpDetail'),
    meta: {
      requiresAuth: true,
      title: ''
    }
  },
  {
    path: 'feedback',
    name: 'HelpFeedBack',
    component: () => import(/* webpackChunkName: "Index" */'@/views/help/HelpFeedBack'),
    meta: {
      requiresAuth: true,
      title: '问题反馈'
    }
  }]
}
