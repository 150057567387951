/*
 * @Author: your name
 * @Date: 2020-10-28 17:45:55
 * @LastEditTime: 2023-03-15 16:03:45
 * @LastEditors: fangteng fangteng@yaomaitong.cn
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/store/module/common/action.js
 */
import * as CommonService from '@/services/commonService'
import * as Types from '../../mutations-types'
export default {
  onUploadImage ({commit, state}, params) {
    return CommonService.uploadImage(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  getUserGuideConfig ({commit, state}) {
    return CommonService.getUserGuide().then(res => {
      commit(Types.GET_USER_GUIDE_CONFIG, res.data)
      return Promise.resolve(res)
    })
  },
  getTagGroup({commit, state}) {
    return CommonService.getTagGroup().then(res=> {
      commit(Types.GET_TAG_GROUP_LIST,res.data)
      return Promise.resolve(res.data)
    },rea=>{
      return Promise.reject(rea.message)
    })
  },
  getTagList({commit, state},params) {
    return CommonService.getTagList(params).then(res => {
      commit(Types.GET_TAG_LIST,res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  onEditTag ({commit,state}, req) {
    return CommonService.editTag(req).then(res=>{
      commit(Types.UPDATE_TAG,req)
      return Promise.resolve(res)
    },rea=>{
      return Promise.reject(rea.message)
    })
  },
  onCreateTag ({commit,state},req) {
    return CommonService.createTag(req).then(res=>{
      commit(Types.ADD_TAG,{...req,tagId: res.data})
      return Promise.resolve(res)
    },rea=>{
      return Promise.reject(rea.message)
    })
  },
  onDelTag ({commit,state},tagId) {
    return CommonService.deleteTag(tagId).then(res=>{
      commit(Types.DELETE_TAG,tagId)
      return Promise.resolve(res)
    },rea=>{
      return Promise.reject(rea.message)
    })
  },
  getJobTitleList({commit, state}) {
    return CommonService.getJobTitle().then(res=> {
      commit(Types.GET_JOB_TITLE_LIST,res.data)
      return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getProvinceList({commit, state}) {
    return CommonService.getProvince().then(res => {
      commit(Types.GET_PROVINCE_LIST,res.data)
      return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getHospitalLevelList({commit, state}) {
    return CommonService.getHospitalLevel().then(res=> {
      commit(Types.GET_HOSPITAL_LEVEL_LIST,res.data)
      return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getHospitalDepartmentList({commit, state}) {
    return CommonService.getDepartmentList().then(res=> {
      commit(Types.GET_HOSPITAL_DEPARTMENT_LIST,res.data)
      // return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  updateHospitalEdit({commit, state},req) {
    return CommonService.updateHospitalEdit(req).then(res=> {
      commit(Types.UPDATE_HOSPITAL_EDIT,res.data)
      return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getAttributeDoctorFile({commit, state},req) {
    return CommonService.getAttributeDoctorFile(req).then(res=> {
      return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  // 下载模版
  batchImportKnowledgePointModelFile({commit, state},req) {
    return CommonService.batchImportKnowledgePointModelFile(req).then(res => {
      return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getAttributeRepresentFile({commit, state},req) {
    return CommonService.getAttributeRepresentFile(req).then(res=> {
      return Promise.resolve(res)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  setModuleType({ commit, state }, value) {
    if (Object.keys(state.moduleMenuObj).includes(value)) {
      commit('MODULE_TYPE', value)
    } else if(!value || state.noviceMenu.includes(value)) {
      commit('MODULE_TYPE', null)
    }
  }
}
