<!--
 * @Author: your name
 * @Date: 2021-08-11 16:28:43
 * @LastEditTime: 2022-01-05 11:08:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/child/ExamScale.vue
-->
<template>
  <div class="exam-wrapper" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
    <div>
      <el-row type="flex">
        <span v-if="isEdit" class="little-title">题目：</span>
        <ksh-input
          v-if="isEdit"
          v-model.trim="title"
          :disabled="isUsed"
          placeholder="【量表题】请输入问题"
          maxlength="200"
          show-word-limit
          class="input-sze"
          @blur="onBlur"
        />
        <span v-else style="overflow-wrap: anywhere;">{{ index }}. {{ title }} (量表题)</span>
      </el-row>
      <el-row type="flex">
        <span v-if="isEdit && showRichText" class="little-title">富文本：</span>
        <Editor
          v-show="isEdit && showRichText"
          ref="editor"
          :ueditor-id="item.id"
          :init-content="examRichTextContent"
          :height="400"
          class="editor"
        />
      </el-row>
      <div v-if="!isEdit" v-safe-html="item.examRichTextContent" class="rich-content" />
      <el-row v-if="isEdit" class="flex-end">
        <ksh-button
          type="text"
          class="add-rich-text"
          :class="{active:showRichText}"
          @click="onChangeRichText"
        >
          {{ showRichText ? '删除富文本' : '添加富文本' }}
        </ksh-button>
      </el-row>
      <el-row v-if="isEdit" type="flex">
        <div class="little-title">行标题：</div>
        <el-row type="flex">
          <el-input
            v-model="scaleQuestion"
            :disabled="isUsed"
            type="textarea"
            placeholder="请输入行标题"
            class="scale-title"
            :rows="10"
            @change="getScaleQuestion"
          />
        </el-row>
        <div>
          <el-row
            v-for="(v,i) in answers"
            :key="i"
            type="flex"
            align="middle"
            class="exam-answer-wrapper"
          >
            <template>
              <span class="little-title" style="margin-right: 5px;">{{ `${alphabet[i]}. ` }}</span>
              <ksh-input
                v-model.trim="v.title"
                :disabled="isUsed"
                :placeholder="`选项${alphabet[i]}`"
                maxlength="10"
                show-word-limit
                class="answer"
              />
              <el-row type="flex" class="answer-opt-wrapper">
                <i class="el-icon-circle-plus-outline opt-label" :class="{'disable' : answers.length > 4 || isUsed}" @click="onAddAnswer(i)" />
                <i class="el-icon-remove-outline opt-label" :class="{'disable' : answers.length < 3 || isUsed}" @click="onDelAnswer(i)" />
              </el-row>
            </template>
          </el-row>
        </div>
      </el-row>
      <el-row v-else>
        <div class="flex-between pdg10">
          <div class="flex-box align-center" />
          <div
            v-for="(v,i) in answers"
            :key="i"
            class="flex-box flex-center"
          >
            <span>{{ v.title }}</span>
          </div>
        </div>
        <el-row
          v-for="(v,i) in scaleQuestionList"
          :key="i"
          type="flex"
          align="middle"
          class="exam-answer-wrapper flex-between border-bottom"
        >
          <div class="flex-box">{{ v }}</div>
          <div v-for="(t,s) in answers" :key="s" class="flex-center flex-box">
            <div class="answer-item" />
          </div>
        </el-row>
      </el-row>
      <el-row v-if="isEdit" type="flex">
        <span class="little-title">分类：</span>
        <ksh-select
          v-model="categoryId"
          clearable
          placeholder="选择分类"
        >
          <el-option
            v-for="v in optionsList"
            :key="v.categoryId"
            :label="v.title"
            :value="v.categoryId"
          />
        </ksh-select>
      </el-row>
      <el-row type="flex" style="align-items: baseline;">
        <span class="little-title">标签/关键信息：</span>
        <div class="scene-form-content">
          <ProductLabelKnowledge :selected-tag-list="memberTagInfo" :disabled-ids="disabledIds" @change="onTagChange" />
          <div v-if="isEdit">
            <el-button plain type="info" @click="chooseKnowledge">选择<svg-icon name="el-icon-plus" /></el-button>
          </div>
        </div>
      </el-row>
      <el-row
        v-if="!isEdit && (hasImage||hasVideo) && isNeed"
        type="flex"
        justify="start"
        align="middle"
      >
        <i class="el-icon-picture-outline" style="font-size: 16px; padding-right: 5px;" />
        <el-button v-if="hasImage" type="text" class="active-btn">
          查看图片
          <el-image class="image-preview" :src="require('@/assets/common/empty_tb.png')" :preview-src-list="imageList" />
        </el-button>
        <el-button
          v-if="hasVideo"
          type="text"
          class="active-btn"
          @click="onOpenPreview(v)"
        >
          查看视频
        </el-button>
      </el-row>
    </div>
    <div v-if="item.hasSet" class="tips-w"> * 此题设置了跳题逻辑</div>
    <el-row
      v-if="showAction"
      type="flex"
      justify="space-between"
      align="middle"
      class="opt-wrapper"
    >
      <el-row type="flex" align="middle">
        <el-row v-if="hasHover || showPop">
          <el-popover
            placement="bottom"
            trigger="click"
            :value="showPop"
            @show="onShow"
            @hide="onHide"
          >
            <AddOtherExam @addExam="onAddExam" />
            <span slot="reference" class="text-primary-link">
              <i class="el-icon-plus font-weight" />
              <span class="mgn-l5">在此题后加入新题</span>
            </span>
          </el-popover>
          <span v-if="showJump&&isNeed" class="text-primary-link mgn-l20" @click.stop="onJump">{{ item.hasSet ?'编辑跳题逻辑':'设置跳题逻辑' }}</span>
        </el-row>
      </el-row>
      <el-row>
        <el-row v-if="isEdit">
          <el-button
            type="primary"
            plain
            @click="onDel"
          >
            删除
          </el-button>
          <el-button type="primary" @click="onSave">完成编辑</el-button>
        </el-row>
        <exam-opt-action
          v-else-if="!item.hasSet"
          :show-move="isNeed"
          :show-edit="!item.quoted"
          @onDel="onDel"
          @onEdit="onEdit"
          @onMoveUp="onMoveUp"
          @onMoveDown="onMoveDown"
        />
      </el-row>
    </el-row>
    <ProductLabel
      :visible.sync="knowledgeDialog"
      :disabled-ids="disabledIds"
      :selected-tag-list="memberTagInfo"
      :options="{
        'SPREAD_DEPARTMENT_GROUP': {
          isShowTab: false
        },
        'CUSTOMIZE_TAG_GROUP': {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>
<script>
import Editor from '@/baseComponents/Editor'
import AddOtherExam from '@/components/AddOtherExam'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
export default {
  name: 'ExamScale',
  components: {
    Editor,
    AddOtherExam,
    ProductLabel,
    ProductLabelKnowledge
  },
  props: {
    showAction: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    showJump: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isNeed: {   //  素材问卷不需要
      type: Boolean,
      default: false
    },
    optionsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      title: '',
      categoryId: '',
      examRichTextContent: '',
      hasHover: false,
      showPop: false,
      answers: [],
      scaleQuestion: '',
      scaleQuestionList: [],
      message: '',
      showRichText: false,
      knowledgeDialog: false,
      memberTagInfo: [],
      usedIds: [],
      isUsed: false,
      alphabet: Array.from(new Array(26), (ele, index) => {
        return String.fromCharCode(65 + index)
      })
    }
  },
  computed: {
    isEdit() {
      return !this.item.save
    },
    hasImage() {
      return [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 1).length > 0
    },
    fristImage() {
      return this.imageList[0] || ''
    },
    videoUrl() {
      const ot = [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 2).map(k => k.resource)[0] || {}
      return ot.url || ''
    },
    videoId() {
      const ot = [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 2).map(k => k.resource)[0] || {}
      return ot.videoId || ''
    },
    imageList() {
      return [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 1).map(k => k.resource.middle)
    },
    hasVideo() {
      return [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 2).length > 0
    },
    disabledIds() {
      if (!this.isEdit) {
        const arr = []
        this.memberTagInfo?.map(v => {
          arr.push(v.tagId)
          v.knowledgePointInfos?.map(k => {
            arr.push(k.knowledgePointId)
          })
        })
        return arr
      } else {
        return this.usedIds
      }
    }
  },
  mounted() {
    this.title = this.item.title || ''
    this.categoryId = this.item.category ? this.item.category.categoryId : null
    this.memberTagInfo = this.item.tagInfos || []
    this.usedIds = this.item.tagInfos?.map(v => v.tagId)
    this.isUsed = !this.item.isRealCanDel
    this.examRichTextContent = this.item.examRichTextContent || ''
    this.scaleQuestionList = this.item.scaleQuestionList || []
    this.scaleQuestion = this.scaleQuestionList.toString().replace(/,/g, '\n')
    this.showRichText = !!this.item.examRichTextContent
    this.answers = this.item.answers || this.item.questionnaireAnswerBoList || [{title: '', orderIndex: '' }, {title: ''}, {title: ''}, {title: ''}]
  },
  methods: {
    onSubmitTag(selectedList) {
      this.knowledgeDialog = false
      this.memberTagInfo = [...selectedList]
    },
    onTagChange(selectedList) {
      this.memberTagInfo = [...selectedList]
    },
    onOpenVideoPop() {
      if (!this.title) return  this.$message.error('请输入问题标题')
      this.$emit('onEditExamResource', 2, {...this.item, title: this.title})
    },
    onOpenPreview() {
      this.$emit('onVideoPreview', this.videoUrl, this.videoId)
    },
    onOpenImagePop() {
      this.$emit('onEditExamResource', 1, {...this.item})
    },
    onOpenImagePreview(item) {
      console.log(item, '查看图片')
    },
    // 上传图片 end
    onAddExam(type) {
      this.showPop = false
      this.$emit('onAddExam', type)
    },
    onJump() {
      this.$emit('onJump')
    },
    onDelAnswer(currentIndex) {
      if (this.answers.length < 3 || this.isUsed) return
      this.answers = [...this.answers].filter((v, i) => i !== currentIndex)
    },
    onAddAnswer(currentIndex) {
      if (this.answers.length > 4 || this.isUsed) return
      this.answers.splice(currentIndex + 1, 0, {title: ''})
    },
    onMouseOver() {
      this.hasHover = true
      // setTimeout(()=>{
      // },1000)
    },
    onMouseOut() {
      this.hasHover = false
    },
    showMessage(msg) {
      this.$message.error(msg)
    },
    onShow() {
      this.showPop = true
    },
    onHide() {
      this.showPop = false
    },
    chooseKnowledge() {
      this.knowledgeDialog = true
    },
    onValid() {
      if (!this.title) {
        this.showMessage('请输入问题题目')
        return false
      }
      if (!this.scaleQuestion) {
        this.showMessage('请输入行标题')
        return false
      }
      let msg = ''
      this.answers.map((v, i) => {
        v.orderIndex = i + 1
        if (!v.title) {
          msg += `选项${  this.alphabet[i]  }未填写。`
        }
      })
      if (msg) {
        this.showMessage(msg)
        return false
      }
      if (this.memberTagInfo.length === 0) {
        this.showMessage('请选择标签/关键信息')
        return false
      }
      return true
    },
    onSave() {
      if (!this.onValid()) return
      this.examRichTextContent = this.showRichText ? this.$refs.editor.getValue() : ''
      this.$emit('onSave', {
        ...this.item,
        answers: this.answers,
        title: this.title,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        scaleQuestionList: this.scaleQuestionList,
        examRichTextContent: this.showRichText ? this.$refs.editor.getValue() : '',
        isSetCorrectAnswer: false,
        save: true
      })
    },
    onDel() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onDel')
        }, this.item.examId)
        return
      }
      this.$emit('onDel')
    },
    onEdit() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onSave', {
            ...this.item,
            answers: this.answers,
            title: this.title,
            memberTagInfo: this.memberTagInfo,
            categoryId: this.categoryId,
            scaleQuestionList: this.scaleQuestionList,
            examRichTextContent: this.showRichText ? this.$refs.editor.getValue() : '',
            isSetCorrectAnswer: false,
            save: false
          })
        }, this.item.examId)
        return
      }
      this.$emit('onSave', {
        ...this.item,
        answers: this.answers,
        title: this.title,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        scaleQuestionList: this.scaleQuestionList,
        examRichTextContent: this.showRichText ? this.$refs.editor.getValue() : '',
        isSetCorrectAnswer: false,
        save: false
      })
      this.$refs.editor.onSetContent()
    },
    onBlur() {
      if (!this.title) {
        return
      }
      this.$emit('onTigger', {...this.item, title: this.title})
    },
    onMoveUp() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveUp')
        })
        return
      }
      this.$emit('onMoveUp')
    },
    onMoveDown() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveDown')
        })
        return
      }
      this.$emit('onMoveDown')
    },
    onChangeRichText() {
      this.examRichTextContent = this.$refs.editor.clearValue()
      this.showRichText = !this.showRichText
    },
    getScaleQuestion(value) {
      this.scaleQuestionList = []
      const list = value.trim().replace(/\n/g, ',').split(',')
      list.map(v => {
        const patt = /^ [\s]*$/// 以空格开头并且已空格结尾，中间多次或者零次空格
        const pvalue = patt.test(v)// RegExp方法，检索字符串
        if (!pvalue) {
          this.scaleQuestionList.push(v)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .exam-wrapper ::v-deep {
    position: relative;
    padding-top: 20px;
    padding-bottom: 75px;
    .el-input__inner {
      border-radius: 0;
    }
    .input-sze {
      margin-bottom: 10px;
      .el-input__inner {
        height: 50px;
      }
    }
  }
  .exam-answer-wrapper {
    padding-bottom: 6px;
    padding-top: 6px;
    position: relative;
    .answer-opt-wrapper {
      position: absolute;
      left: calc(100% + 10px);
    }
    .opt-label {
      cursor: pointer;
      margin-right: 10px;
      font-size: 20px;
      color: $color-primary;
    }
    .answer-item {
      position: relative;
      color: #7784a1;
      width: 16px;
      height: 16px;
      border: 1px solid #e1e1e1;
      flex-shrink: 0;
      border-radius: 50%;
    }
  }
  .tips-w {
    padding-top: 5px;
    color: #bbb;
  }
  .opt-wrapper {
    position: absolute;
    height: 40px;
    bottom: 20px;
    left: 0;
    right: 0;
  }
  .alert-message {
    z-index: 1000;
    margin-bottom: 10px;
    width: 520px;
  }
  .active-btn {
    color: rgba(25, 140, 255, 1);
    text-decoration: underline;
    position: relative;
    .image-preview {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
  .add-rich-text {
    text-decoration: underline;
    &.active {
      color: #e8312f;
      margin-top: 5px;
    }
  }
  .editor {
    width: 100%;
    line-height: initial;
  }
  .little-title {
    color: #5a5a5a;
    width: 110px;
    text-align: end;
  }
  .button-default ::v-deep {
    margin-top: 10px;
    .el-button--default {
      border: 1px solid #dcdfe6;
      color: #606266;
    }
  }
  .answer {
    min-width: 200px;
  }
  .scale-title ::v-deep {
    .el-textarea__inner {
      margin-bottom: 10px;
    }
  }
  .border-bottom {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  .choose-again {
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px;
    color: #3d61e3;
  }
  .rich-content {
    word-break: break-all;
    ::v-deep {
      img {
        width: 300px;
      }
    }
  }
  .exam-answer-wrapper ::v-deep .disable {
    color: #c0c4cc;
  }
</style>
