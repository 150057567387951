/*
 * @Author: your name
 * @Date: 2020-12-23 19:19:19
 * @LastEditTime: 2020-12-23 19:28:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/live/action.js
 */
import * as LiveService from '@/services/liveService'
import * as Types from '../../mutations-types'
import server from '@/utils/request.js'
import API from '@/apis/api-types.js'
export default {
  getLivePreviewQrCode ({commit,state},liveId) {
    return LiveService.getLivePreviewQrCode(liveId).then(res=>{
      commit(Types.GET_LIVE_PREVIEW_QR_CODE,{liveId:liveId,code:res.data})
      return Promise.resolve(res)
    },rea=>{
      return Promise.reject(rea)
    })
  },
  getCertifyRule({commit, state},sceneType) {
    return server.get(API.certifyRule, {params: {sceneType : sceneType}}).then(res => {
      commit('setLiveDefaultReviewSec',res.data / 60)
      return Promise.resolve(res.data / 60)
    }).catch(error => {
      return Promise.reject(error)
    })
  }
}
