<template>
  <div class="mgn-b10 flex flex-direction-col">
    <div class="font-s18 font-weight mgn-b20">添加题目</div>
    <el-row type="flex" class="flex-box" justify="space-between">
      <el-popover
        v-if="showType.single"
        placement="right"
        trigger="hover"
        :disabled="showPopover"
      >
        <div class="ex-tip-item">
          <el-row type="flex" justify="space-between" align="middle">
            <span class="col272e40 font-s18 font-weight">标题</span>
            <span class="colc8c9ce">示例</span>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-radio :value="false">
              <span class="col272e40 font-s16">选项一</span>
            </el-radio>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-radio :value="false">
              <span class="col272e40 font-s16">选项二</span>
            </el-radio>
          </el-row>
        </div>
        <check-tag
          slot="reference"
          text="单选题"
          class="check-tag"
          value="single"
          :selected="examType"
          @onSelect="onAdd"
        />
      </el-popover>
      <el-popover
        v-if="showType.multiple"
        :disabled="showPopover"
        placement="right"
        trigger="hover"
      >
        <div class="ex-tip-item">
          <el-row type="flex" justify="space-between" align="middle">
            <span class="col272e40 font-s18 font-weight">标题</span>
            <span class="colc8c9ce">示例</span>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-checkbox :value="false">
              <span class="col272e40 font-s16">选项一</span>
            </el-checkbox>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-checkbox :value="false">
              <span class="col272e40 font-s16">选项二</span>
            </el-checkbox>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-checkbox :value="false">
              <span class="col272e40 font-s16">选项三</span>
            </el-checkbox>
          </el-row>
        </div>
        <check-tag
          slot="reference"
          text="多选题"
          value="multiple"
          :selected="examType"
          @onSelect="onAdd"
        />
      </el-popover>
    </el-row>
    <el-row type="flex" class="flex-box" justify="space-between">
      <el-popover
        v-if="showType.blank_fill"
        :disabled="showPopover"
        placement="right"
        trigger="hover"
      >
        <div class="ex-tip-item">
          <div class="font-s18">
            <span class="col272e40">姓名：</span>
            <span class="colc8c9ce"> ____________ </span>
            <span class="col272e40">电话：</span>
            <span class="colc8c9ce"> ____________ </span>
          </div>
          <div class="font-s18">
            <span class="col272e40">年龄：</span>
            <span class="colc8c9ce"> ____________ </span>
            <span class="col272e40">岁</span>
          </div>
        </div>
        <check-tag
          slot="reference"
          text="填空题"
          value="blank_fill"
          :selected="examType"
          @onSelect="onAdd"
        />
      </el-popover>
      <el-popover
        v-if="showType.text_fill"
        :disabled="showPopover"
        placement="right"
        trigger="hover"
      >
        <div class="ex-tip-item">
          <el-row type="flex" justify="space-between" align="middle">
            <span class="col272e40 font-s18 font-weight">问题</span>
            <span class="colc8c9ce">示例</span>
          </el-row>
          <div class="exam-tip-wrapper" />
        </div>
        <check-tag
          slot="reference"
          text="问答题"
          value="text_fill"
          :selected="examType"
          @onSelect="onAdd"
        />
      </el-popover>
    </el-row>
    <el-row type="flex" class="flex-box" justify="space-between">
      <el-popover
        v-if="showType.scale"
        :disabled="showPopover"
        placement="right"
        trigger="hover"
      >
        <div class="ex-tip-item">
          <el-row type="flex" justify="space-between" align="middle">
            <span class="col272e40 font-s18 font-weight">标题</span>
            <span class="colc8c9ce">示例</span>
          </el-row>
          <el-row
            type="flex"
            justify="space-between"
            align="middle"
            class="pdg-t15"
          >
            <span />
            <span>不满意</span>
            <span>一般</span>
            <span>满意</span>
          </el-row>
          <el-row
            type="flex"
            justify="space-between"
            align="middle"
            class="pdg-t15"
          >
            <span style="margin-right: 20px;">问题1</span>
            <el-radio :value="false" />
            <el-radio :value="false" />
            <el-radio :value="false" />
          </el-row>
          <el-row
            type="flex"
            justify="space-between"
            align="middle"
            class="pdg-t15"
          >
            <span style="margin-right: 20px;">问题2</span>
            <el-radio :value="false" />
            <el-radio :value="false" />
            <el-radio :value="false" />
          </el-row>
          <el-row
            type="flex"
            justify="space-between"
            align="middle"
            class="pdg-t15"
          >
            <span style="margin-right: 20px;">问题3</span>
            <el-radio :value="false" />
            <el-radio :value="false" />
            <el-radio :value="false" />
          </el-row>
        </div>
        <check-tag
          slot="reference"
          text="量表题"
          value="scale"
          :selected="examType"
          @onSelect="onAdd"
        />
      </el-popover>
      <el-popover
        v-if="showType.sort"
        :disabled="showPopover"
        placement="right"
        trigger="hover"
      >
        <div class="ex-tip-item">
          <el-row type="flex" justify="space-between" align="middle">
            <span class="col272e40 font-s18 font-weight">标题</span>
            <span class="colc8c9ce">示例</span>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-checkbox :value="false" disabled>
              <span class="col272e40 font-s16">选项一</span>
            </el-checkbox>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-checkbox :value="false" disabled>
              <span class="col272e40 font-s16">选项二</span>
            </el-checkbox>
          </el-row>
          <el-row type="flex" align="middle" class="pdg-t15">
            <el-checkbox :value="false" disabled>
              <span class="col272e40 font-s16">选项三</span>
            </el-checkbox>
          </el-row>
        </div>
        <check-tag
          slot="reference"
          text="排序题"
          value="sort"
          :selected="examType"
          @onSelect="onAdd"
        />
      </el-popover>
    </el-row>
    <div v-if="(showType.rich_text || showType.page_end)" class="font-s18 font-weight mgn-tb25">添加其他</div>
    <el-row type="flex" class="flex-box" justify="space-between">
      <check-tag
        v-if="showType.rich_text"
        text="富文本"
        value="rich_text"
        :selected="examType"
        @onSelect="onAdd"
      />
      <check-tag
        v-if="showType.page_end"
        text="分页器"
        value="page_end"
        :selected="examType"
        @onSelect="onAdd"
      />
    </el-row>
  </div>
</template>

<script>
import CheckTag from '@/baseComponents/CheckTag'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'AddQuestionCard',
  components: {
    CheckTag
  },
  props: {
    showType: {
      type: Object,
      default() {
        return {
          single: false,
          multiple: false,
          blank_fill: false,
          text_fill: false,
          sort: false,
          scale: false,
          rich_text: false,
          page_end: false
        }
      }
    }
  },
  data() {
    return {
      examType: '',
      showPopover: isMobile()
    }
  },
  methods: {
    onAdd(type) {
      this.$emit('onAddExam', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.ex-tip-item {
  width: 360px;
  padding: 10px;
}
.exam-tip-wrapper {
  margin-top: 20px;
  height: 50px;
  border: 1px solid #e9eaeb;
  background: #fff;
}

</style>
