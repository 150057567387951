<template>
  <div style="height: 80px">
    <div class="ksh-footer">
      <div class="left">
        <slot name="left" />
      </div>
      <div class="right">
        <slot name="right">
          <el-button type="primary" :loading="loading" @click="$emit('onSubmit', $event)">保存</el-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KshFooter',
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.ksh-footer {
    width: 100%;
    height: 80px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid #ddd;
    position: fixed;
    bottom: 0;
    z-index: 10;
}
</style>
