<template>
  <div class="user">
    <div class="tools">
      <span class="guide-button item" @click="$emit('open-guide-mode')">
        <el-button type="info" class="item"> <svg-icon name="guide" /> {{ name }} </el-button>
      </span>
      <el-tooltip effect="dark" content="刷新页面" placement="bottom">
        <span class="item" @click="reload(2)">
          <svg-icon name="reload" />
        </span>
      </el-tooltip>
    </div>
    <el-dropdown class="user-container" trigger="click" @command="handleCommand">
      <div class="user-wrapper">
        <el-avatar>
          <svg-icon name="avatar" />
        </el-avatar>
        <div class="user-info">
          <span class="account">{{ personInfo.mobile || personInfo.orgLoginVo.login }}</span>
          <span class="name">{{ personInfo.orgLoginVo.name }}</span>
        </div>
        <i class="el-icon-caret-bottom" />
      </div>
      <el-dropdown-menu slot="dropdown" class="user-dropdown">
        <el-dropdown-item command="account-and-security"><svg-icon class="user-icon-svg" name="account-and-security" /><span>账户和安全</span></el-dropdown-item>
        <el-dropdown-item v-if="personInfo.accountNum" command="multiple-account-switching"><svg-icon class="user-icon-svg" name="multiple-account-switching" /><span>切换账号</span></el-dropdown-item>
        <el-dropdown-item v-if="personInfo.orgLoginVo.isTopOrg" command="more-setting"><svg-icon class="user-icon-svg" name="more-setting" /><span>更多设置</span></el-dropdown-item>
        <el-dropdown-item command="recovery-station"><svg-icon class="user-icon-svg" name="del" /><span>回收站</span></el-dropdown-item>
        <el-dropdown-item command="logout"><svg-icon class="user-icon-svg" name="logout" /><span>退出登录</span></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {toLogin} from '@/utils/request'
export default {
  name: 'UserMenu',
  inject: ['reload'],
  props: {
    name: {
      type: String,
      default: '新手引导攻略'
    }
  },
  computed: {
    personInfo() {
      return {
        ...this.$store.state.user.metadataUserInfo,
        orgLoginVo: {
          ...this.$store.state.user.metadataUserInfo?.orgLoginVo
        }
      }
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'account-and-security':
          this.$router.push({
            name: 'SafeCenter'
          })
          break
        case 'multiple-account-switching':
          this.$router.push({
            name: 'SelectFactory'
          })
          break
        case 'more-setting':
          this.$router.push({
            name: 'DoctorCertifySetRule'
          })
          break
        case 'recovery-station':
          this.$router.push({
            name: 'RecoveryStation'
          })
          break
        case 'logout':
          toLogin()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  display: flex;
  align-items: center;
  padding: 0 20px;
  white-space: nowrap;
}
.tools {
  margin-right: 10px;
  display: flex;
  align-items: center;
  .guide-button {
    display: flex;
    align-items: center;
    ::v-deep .el-button {
      background-color: #fb9c35;
      border: none;
      color: #fff;
    }
    img {
      width: 50px;
      margin-left: 10px;
    }
  }
  .item {
    padding: 6px 8px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
}
.user-container {
  display: inline-block;
  cursor: pointer;
  .user-wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    .user-info {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 12px;
      > .account {
        font-weight: 600;
        color: #0f0f0f;
      }
      > .name {
        font-weight: 400;
        color: #787878;
        margin-top: 4px;
      }
    }
    .el-avatar {
      width: 30px;
      height: 30px;
      line-height: 30px;
      background: #eee;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="scss">
.user-dropdown {
  width: 155px;
  .user-icon-svg {
    margin-right: 13px;
    font-size: 14px;
  }
  .el-dropdown-menu__item:not(.is-disabled) {
    color: #a3a3a3;
    >span {
      color: #5a5a5a default;
    }
  }
  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    >span {
      color: $color-primary;
    }
  }
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    line-height: 36px !important;
    padding-left: 33px !important;
  }
}
</style>
