/*
 * @Author: your name
 * @Date: 2020-10-28 17:44:28
 * @LastEditTime: 2020-11-12 18:25:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/help/index.js
 */
import actions from './action'
import mutations from './mutation'
const state = {
  helpNavList: [],
  helpList: [],
// 帮助中心详情
  helpMessageTitle: '',
  publishAt: '',
  viewCount: 0,
  helpMessageContent: '',
  likeCount: 0,
  like:false,
  hate:false,
  hateCount: 0
}

const getters = {
  helpNavList: () => state.helpNavList,
  helpList: () => state.helpList,
  helpMessageTitle: () => state.helpMessageTitle,
  publishAt: () => state.publishAt,
  viewCount: () => state.viewCount,
  helpMessageContent: () => state.helpMessageContent,
  likeCount: () => state.likeCount,
  hateCount: () => state.hateCount,
  hate: () => state.hate,
  like: () => state.like
}
export default {
  state,
  getters,
  actions,
  mutations
}