<template>
  <el-cascader v-bind="$attrs" v-on="$listeners"></el-cascader>
</template>

<script>
export default {
  name: 'KshCascader',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
// scss
::v-deep input {
  border-radius: 1px;

  &::placeholder {
     color: red;
  }
}
::v-deep .el-input--samll {
  height: 38px;
}
</style>
