/*
 * @Author: your name
 * @Date: 2020-10-28 17:44:22
 * @LastEditTime: 2020-11-30 14:48:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/store/module/help/action.js
 */
import * as HelpService from '@/services/helpService'
import {
  GET_HELP_NAV_LIST,
  INIT_HELP_MESSAGE_LIST,
  GET_HELP_MESSAGE_LIST,
  GET_HELP_MESSAGE_DETAIL,
  ON_READ_HELP_MESSAGE,
  ON_HATE_MESSAGE_DETAIL,
  ON_LIKE_MESSAGE_DETAIL,
} from '../../mutations-types'
export default {
  initHelpNav ({commit, state}, id) {
    return HelpService.getHelpNavList (id).then(res=>{
      commit(GET_HELP_NAV_LIST, res.data)
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  initHelpMessageList ({commit, state}, params) {
    return HelpService.getHelpMessageList (params).then(res=>{
      commit(INIT_HELP_MESSAGE_LIST, res.data.data)
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getHelpMessageList ({commit, state}, params) {
    return HelpService.getHelpMessageList (params).then(res=>{
      commit(GET_HELP_MESSAGE_LIST, res.data.data)
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  getHelpDetail ({commit, state}, id) {
    return HelpService.getHelpMessageDetail (id).then(res=>{
      commit(GET_HELP_MESSAGE_DETAIL, res.data)
      return Promise.resolve(res.data)
    },rea => {
      return Promise.reject(rea.message)
    })
  },
  onReadHelp ({commit, state}, id) {
    commit(ON_READ_HELP_MESSAGE, id)
  },
  onLikeHelp ({commit, state},params) {
    return HelpService.onHelpMessageMark (params).then(res=> {
      commit(ON_LIKE_MESSAGE_DETAIL, {...res.data, like: params.like})
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  onHateHelp ({commit, state},params) {
    return HelpService.onHelpMessageMark (params).then(res=> {
      commit(ON_HATE_MESSAGE_DETAIL, {...res.data, hate: params.hate})
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  },
  onFeedBack ({commit, state},params) {
    return HelpService.platformFeedback(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea.message)
    })
  }
}