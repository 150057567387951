<template>
  <el-dialog
    class="customize-el-dialog"
    :visible.sync="isShow"
    title="选择标签"
    width="60%"
    @open="handleOpen"
  >
    <div class="content-box">
      <div class="row-between">
        <div class="title-box">
          <div class="label-box">
            <span style="color: #e8312f;">*</span>
            <span>标签内容：</span>
          </div>
          <el-radio-group v-model="tabValue">
            <template v-for="item in tabList">
              <el-radio-button v-if="item.isShow" :key="item.value" :label="item.value">
                {{ item.label }}
              </el-radio-button>
            </template>
          </el-radio-group>
        </div>
        <el-input
          v-model="keyWord"
          class="search-input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="搜索标签关键词"
        />
      </div>
      <div class="items-box">
        <template v-if="isRadio">
          <el-radio-group v-model="labelValue" @input="onChangeRadio">
            <template v-for="(item, index) in labelList">
              <el-radio :key="index" :label="item.tagId" :disabled="isDisabled || disabledIds.includes(item.tagId)">
                {{ item.tagName }}
              </el-radio>
            </template>
          </el-radio-group>
        </template>
        <template v-else>
          <el-checkbox-group v-model="labelValue">
            <template v-for="(item, index) in labelList">
              <el-checkbox :key="index" :label="item.tagId" :disabled="isDisabled || disabledIds.includes(item.tagId)">
                {{ item.tagName }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </template>
        <div v-if="labelList.length === 0">暂无数据</div>
      </div>
      <div v-show="isShowKnowledge" class="title-box mg-t20">
        <div class="label-box">
          <span>产品策略：</span>
        </div>
        <el-select
          v-model="strategyVal"
          :disabled="formData.strategyDisabled"
          clearable
          placeholder="请选择策略"
          style="margin-right: 10px; width: 200px;"
          value-key="strategyId"
          @change=";(knowledgePointIds = []), onGetKnowledgeList(labelValue)"
        >
          <template v-for="item in stategicList">
            <el-option :key="item.value" :label="item.strategyName" :value="item" />
          </template>
        </el-select>
      </div>
      <div v-show="isShowKnowledge" class="title-box mg-t20">
        <div class="label-box">
          <span>关键信息：</span>
        </div>
        <el-select
          v-model="knowledgePointIds"
          multiple
          clearable
          placeholder="请选择关键信息"
          style="width: 200px;"
        >
          <template v-for="item in conceptList">
            <el-option :key="item.value" :label="item.knowledgePointName" :value="item.knowledgePointId" />
          </template>
        </el-select>
      </div>
      <div class="mg-t20">备注：若需要使用更多标签，可<span class="blue-text" @click="onJump">点击此处</span></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <ksh-button @click="isShow = false">取 消</ksh-button>
      <ksh-button type="primary" @click="onConfirm">确 定</ksh-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ProductLabel',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    disabledIds: {
      type: Array,
      default: () => []
    },
    selectedTagList: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {
        return {
          PRODUCT_TAG_GROUP: {
            isShowTab: true // 是否显示该tab
          },
          SPREAD_DEPARTMENT_GROUP: {
            isShowTab: true
          },
          CUSTOMIZE_TAG_GROUP: {
            isShowTab: true
          }
        }
      }
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      keyWord: '',
      tabValue: 'PRODUCT_TAG_GROUP',
      conceptList: [],
      initOptions: {
        PRODUCT_TAG_GROUP: {
          name: '产品标签',
          isRadio: true, // 是否单选
          isShowTab: true, // 是否显示该tab
          list: [], // 该tab下的标签列表
          value: '', // 该tab下的标签id
          disabled: false, // 是否禁用该标签下的选项
          knowledgePointIds: [] // 该tab下的关键信息值
        },
        SPREAD_DEPARTMENT_GROUP: {
          name: '推广科室',
          isShow: true,
          isRadio: false,
          list: [],
          value: [],
          disabled: false
        },
        CUSTOMIZE_TAG_GROUP: {
          name: '自定义',
          isShow: true,
          isRadio: false,
          list: [],
          value: [],
          disabled: false
        }
      },
      strategyVal: {
        strategyName: '',
        strategyId: ''
      },
      stategicList: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    selectedList() {
      let list = []
      const keys = Object.keys(this.initOptions)
      keys.forEach(key => {
        const ids = this.initOptions[key].value
        const l = this.initOptions[key].list
        const knowledgePointIds = this.initOptions[key].knowledgePointIds
        list = [...list, ...this.onGetItem(ids, l, knowledgePointIds)]
      })
      return list
    },
    tabList() {
      const list = []
      const keys = Object.keys(this.initOptions)
      keys.forEach(key => {
        list.push({
          label: this.initOptions[key].name,
          value: key,
          isShow: this.initOptions[key].isShowTab
        })
      })
      return list
    },
    knowledgePointIds: {
      get() {
        return this.initOptions[this.tabValue].knowledgePointIds
      },
      set(val) {
        this.initOptions[this.tabValue].knowledgePointIds = val
      }
    },
    isShowKnowledge() {
      return this.tabValue === 'PRODUCT_TAG_GROUP'
    },
    isRadio() {
      return this.initOptions[this.tabValue].isRadio
    },
    labelList() {
      return this.initOptions[this.tabValue].list.filter(item => item.tagName.includes(this.keyWord))
    },
    labelValue: {
      get() {
        return this.initOptions[this.tabValue].value
      },
      set(val) {
        this.initOptions[this.tabValue].value = val
      }
    },
    isDisabled() {
      return this.initOptions[this.tabValue].disabled
    },
    formData: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('update:form', val)
      }
    }
  },
  watch: {
    isShow: {
      handler(val) {
        if (val) {
          this.initData()
          this.onGetTagGroup()
        }
      },
      immediate: true
    },
    labelValue: {
      handler(newVal, oldVal) {
        if (this.isRadio && newVal && newVal !== oldVal) {
          this.getStategicList()
        }
        if (!newVal) {
          this.stategicList = []
        }
      }
    }
  },
  created() {
    this.initData()
  },
  mounted() {
    if (this.isShow) {
      this.onGetTagGroup()
    }
  },
  methods: {
    onChangeRadio(val) {
      this.initOptions[this.tabValue].knowledgePointIds = []
      this.onGetKnowledgeList(val)
      this.strategyVal = {}
    },
    onResetOptions() {
      // 重置initOptions
      for (const item in this.initOptions) {
        this.initOptions[item].value = Array.isArray(this.initOptions[item].value) ? [] : ''
        this.initOptions[item].disabled = false
        this.initOptions[item].knowledgePointIds = []
      }
      // 初始化initOptions数据
      this.selectedTagList.forEach(item => {
        const options = this.initOptions[item.groupCode]
        if (options) {
          if (options.isRadio) {
            options.knowledgePointIds = item.knowledgePointInfos?.map(item => item.knowledgePointId)
            this.onGetKnowledgeList(item.tagId)
            if (this.disabledIds.includes(item.tagId)) {
              options.disabled = true
            }
          } else {
            options.value.push(item.tagId)
          }
        }
      })
    },
    onGetItem(ids, list, knowledgePointIds = []) {
      if (typeof ids === 'string') {
        for (const item of list) {
          if (item.tagId === ids) {
            item.knowledgePointIds = knowledgePointIds
            const list = []
            knowledgePointIds.forEach(it => {
              for (const t of this.conceptList) {
                if (it === t.knowledgePointId) {
                  list.push(t)
                }
              }
            })
            item.knowledgePointInfos = list
            return [item]
          }
        }
        return []
      } else {
        const l = []
        for (const item of list) {
          if (ids.includes(item.tagId)) l.push(item)
        }
        return l
      }
    },
    initData() {
      this.conceptList = []
      this.onMergeOptions()
    },
    onMergeOptions() {
      const keys = Object.keys(this.initOptions)
      keys.forEach(key => {
        const obj = this.initOptions[key]
        const optionsObj = this.options[key] || {}
        this.initOptions[key] = { ...obj, ...optionsObj }
        this.onResetOptions()
      })
    },
    onConfirm() {
      if (this.isRadio) {
        this.$set(this.formData, 'strategyId', this.strategyVal.strategyId)
        this.$set(this.formData, 'strategyName', this.strategyVal.strategyName)
      } else {
        this.$set(this.formData, 'strategyId', '')
        this.$set(this.formData, 'strategyName', '')
      }
      this.$emit('onSubmit', this.selectedList)
    },
    onJump() {
      const url = this.$router.resolve({ path: '/product/label' })
      window.open(url.href, '_blank')
    },
    onGetTagGroup() {
      this.$axios.get(this.$API.getTagGroup).then(res => {
        const { data = [] } = res
        const keys = Object.keys(this.initOptions)
        keys.forEach(item => {
          for (const it of data) {
            if (it.groupCode === item) {
              this.onGetTagList(it.id, item)
              break
            }
          }
        })
      })
    },
    onGetTagList(id, name) {
      this.$axios.get(this.$API.tagList, { params: { tagGroupId: id } }).then(res => {
        const { data = [] } = res
        this.initOptions[name].list = data.map(item => ({
          ...item,
          groupCode: name
        }))
      })
    },
    onGetKnowledgeList(dataId) {
      if (!dataId) return
      this.conceptList = []
      const params = {
        dataId: dataId,
        dataType: 1,
        strategyId: this.strategyVal.strategyId
      }
      this.$axios.get(this.$API.getProductKnowledgePointList, { params }).then(
        res => {
          const { knowledgePointList = [] } = res.data
          this.conceptList = knowledgePointList
          this.initOptions['PRODUCT_TAG_GROUP'].value = dataId
        },
        err => {
          this.$message.error(err.message)
        }
      )
    },
    // 获取策略列表
    async getStategicList() {
      try {
        const params = {
          status: 1,
          pageNo: 1,
          pageSize: 999999,
          tagId: this.labelValue
        }
        const {
          data: { data }
        } = await this.$axios.get(this.$API.strategyList, { params })
        this.stategicList = data ?? []
      } catch (error) {
        console.log('error: ', error)
      }
    },
    handleOpen() {
      // 回显策略
      this.strategyVal = {
        strategyId: this.formData.strategyId,
        strategyName: this.formData.strategyName
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  font-size: 14px;
  color: #606266;
  padding: 15px;
}
.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-input {
  width: 200px;
  ::v-deep .el-input__inner {
    border-radius: 20px !important;
  }
}
.title-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.label-box {
  font-weight: bold;
}
.items-box {
  margin-top: 20px;
  border: 1px solid #e1e1e1;
  padding: 20px;
  min-height: 120px;
  ::v-deep .el-radio-group {
    line-height: 24px;
  }
}
.blue-text {
  color: #3d61e3;
  cursor: pointer;
}
.mg-t20 {
  margin-top: 20px;
}
</style>
