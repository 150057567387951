<template>
  <div class="exam-wrapper" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
    <div>
      <el-row type="flex">
        <span v-if="isEdit" class="little-title">题目：</span>
        <ksh-input
          v-if="isEdit"
          v-model.trim="title"
          :disabled="isUsed"
          placeholder="【问答题】请输入题目"
          maxlength="200"
          show-word-limit
          class="input-sze"
          @blur="onBlur"
        />
        <span v-else class="pdg-b10">{{ index }}. {{ title }}</span>
      </el-row>
      <el-row type="flex">
        <span v-if="isEdit && showRichText" class="little-title">富文本：</span>
        <Editor
          v-show="isEdit && showRichText"
          ref="editor"
          :ueditor-id="item.id"
          :init-content="examRichTextContent"
          :height="400"
          class="editor"
        />
      </el-row>
      <div
        v-if="!isEdit"
        v-safe-html="item.examRichTextContent"
        class="rich-content"
      />
      <el-row v-if="isEdit" class="flex-end">
        <ksh-button
          type="text"
          class="add-rich-text"
          :class="{ active: showRichText }"
          @click="onChangeRichText"
        >
          {{ showRichText ? '删除富文本' : '添加富文本' }}
        </ksh-button>
      </el-row>
      <el-row v-if="isEdit" type="flex" class="mgn-b10">
        <span class="little-title mgn-t5">答题设置：</span>
        <div>
          <span class="setting">
            共答对
            <el-input-number
              v-model="examAnswerSettingBO.keywordCount"
              :max="keyWord.length"
              :min="1"
              size="mini"
              :disabled="isUsed && isPreSet"
            />
            个关键词，算是正确的
          </span>
          <el-checkbox v-model="isNoOrder" :disabled="isUsed && isPreSet">
            答案不分顺序
          </el-checkbox>
          <el-checkbox
            v-model="examAnswerSettingBO.isIgnoreCase"
            :disabled="isUsed && isPreSet"
          >
            忽略大小写
          </el-checkbox>
        </div>
      </el-row>
      <el-row type="flex" class="mgn-b10">
        <span v-if="isEdit" class="little-title">答案：</span>
        <ksh-input
          v-if="isEdit"
          v-model.trim="examExpand"
          :disabled="isUsed && isPreSet"
          placeholder="请输入正确答案"
          maxlength="200"
          type="textarea"
          show-word-limit
        />
        <div v-if="!isEdit" class="exam-fill-wrapper" />
      </el-row>
      <el-row v-if="isEdit" type="flex">
        <span class="little-title" />
        <div class="tips">
          *如遇包含特殊字符的关键词，需添加多个同义词，例：'CO₂'需添加同义词'CO2'
        </div>
      </el-row>
      <el-row
        v-for="(v, i) in keyWord"
        :key="i"
        type="flex"
        align="middle"
        class="exam-answer-wrapper"
        :style="`margin-left: ${isEdit ? '' : '13px'}`"
      >
        <template v-if="isEdit">
          <span
            class="little-title"
            style="padding-right: 5px;"
          >关键词{{ i + 1 }}</span>
          <div class="word-border">
            <el-tag
              v-for="(tag, r) in v.value"
              :key="r"
              type="info"
              :closable="!isUsed && !isPreSet"
              :disable-transitions="false"
              @close="handleClose(v, tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-show="v.isEditor"
              :ref="'saveTagInput' + i"
              v-model="inputValue"
              class="input-new-tag"
              size="small"
              @keyup.enter.native="handleInputConfirm(v)"
              @blur="handleInputConfirm(v)"
            />
            <el-button
              v-show="!v.isEditor && v.value.length < 10"
              class="button-new-tag"
              size="mini"
              :disabled="isUsed && isPreSet"
              @click="showInput(v, i)"
            >
              + 添加{{ v.value.length ? '同义词' : '关键词' }}
            </el-button>
          </div>
          <el-row type="flex" class="answer-opt-wrapper">
            <i
              class="el-icon-circle-plus-outline opt-label"
              :class="{ disable: keyWord.length > 9 || (isUsed && isPreSet) }"
              @click="onAddAnswer(i)"
            />
            <i
              class="el-icon-remove-outline opt-label"
              :class="{ disable: keyWord.length < 2 || (isUsed && isPreSet) }"
              @click="onDelAnswer(i)"
            />
          </el-row>
        </template>
      </el-row>
      <el-row v-if="isEdit" type="flex" class="mgn-t5">
        <span class="little-title">分类：</span>
        <ksh-select v-model="categoryId" clearable placeholder="选择分类">
          <el-option
            v-for="optionsListItem in optionsList"
            :key="optionsListItem.categoryId"
            :label="optionsListItem.title"
            :value="optionsListItem.categoryId"
          />
        </ksh-select>
      </el-row>
      <el-row type="flex" style="align-items: baseline;">
        <span class="little-title">标签/关键信息：</span>
        <div class="scene-form-content">
          <ProductLabelKnowledge
            :selected-tag-list="memberTagInfo"
            :disabled-ids="disabledIds"
            @change="onTagChange"
          />
          <div v-if="isEdit">
            <el-button plain type="info" @click="chooseKnowledge">
              选择<svg-icon name="el-icon-plus" />
            </el-button>
          </div>
        </div>
      </el-row>
      <el-row v-if="isEdit" type="flex">
        <span v-if="isEdit || analysis" class="little-title">解析：</span>
        <ksh-input
          v-if="isEdit"
          v-model.trim="analysis"
          placeholder="请输入题目解析"
          maxlength="200"
          type="textarea"
          show-word-limit
          class="input-sze"
        />
        <span v-if="!isEdit && analysis">{{ analysis }}</span>
      </el-row>
    </div>
    <el-row
      v-if="!isEdit && (hasImage || hasVideo) && isNeed"
      type="flex"
      justify="start"
      align="middle"
    >
      <i
        class="el-icon-picture-outline"
        style="font-size: 16px; padding-right: 5px;"
      />
      <el-button
        v-if="hasImage"
        type="text"
        class="active-btn"
        @click="onOpenImagePreview(item)"
      >
        查看图片
        <el-image
          class="image-preview"
          :src="require('@/assets/common/empty_tb.png')"
          :preview-src-list="imageList"
        />
      </el-button>
      <el-button
        v-if="hasVideo"
        type="text"
        class="active-btn"
        @click="onOpenPreview(item)"
      >
        查看视频
      </el-button>
    </el-row>
    <div v-if="item.hasSet" class="tips-w">* 此题设置了跳题逻辑</div>
    <el-row
      v-if="showAction"
      type="flex"
      justify="space-between"
      align="middle"
      class="opt-wrapper"
    >
      <el-row type="flex" align="middle">
        <el-row v-if="hasHover || showPop">
          <el-popover
            placement="bottom"
            trigger="click"
            :value="showPop"
            @show="onShow"
            @hide="onHide"
          >
            <AddOtherExam @addExam="onAddExam" />
            <span slot="reference" class="text-primary-link">
              <i class="el-icon-plus font-weight" />
              <span class="mgn-l5">在此题后加入新题</span>
            </span>
          </el-popover>
          <span
            v-if="showJump && isNeed"
            class="text-primary-link mgn-l20"
            @click.stop="onJump"
          >{{ item.hasSet ? '编辑跳题逻辑' : '设置跳题逻辑' }}</span>
        </el-row>
      </el-row>
      <el-row>
        <el-row v-if="isEdit">
          <el-button type="primary" plain @click="onDel"> 删除 </el-button>
          <el-button type="primary" @click="onSave">完成编辑</el-button>
        </el-row>
        <exam-opt-action
          v-else-if="!item.hasSet"
          :show-move="isNeed"
          :show-edit="!item.quoted"
          @onDel="onDel"
          @onEdit="onEdit"
          @onMoveUp="onMoveUp"
          @onMoveDown="onMoveDown"
        />
      </el-row>
    </el-row>
    <ProductLabel
      :visible.sync="knowledgeDialog"
      :disabled-ids="disabledIds"
      :selected-tag-list="memberTagInfo"
      :options="{
        SPREAD_DEPARTMENT_GROUP: {
          isShowTab: false
        },
        CUSTOMIZE_TAG_GROUP: {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>
<script>
import Editor from '@/baseComponents/Editor'
import AddOtherExam from '@/components/AddOtherExam'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
export default {
  name: 'ExamFill',
  components: {
    Editor,
    AddOtherExam,
    ProductLabel,
    ProductLabelKnowledge
  },
  props: {
    showAction: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    showJump: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isNeed: {
      //  素材问卷不需要
      type: Boolean,
      default: false
    },
    optionsList: {
      type: Array,
      default: () => []
    },
    suitableType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      title: '',
      hasHover: false,
      showPop: false,
      message: '',
      categoryId: '',
      examRichTextContent: '',
      showRichText: false,
      knowledgeDialog: false,
      keyWord: [],
      count: '',
      inputValue: '',
      analysis: '',
      examAnswerSettingBO: {},
      examExpand: '',
      isNoOrder: true,
      isSetCorrectAnswer: false,
      isPreSet: false,
      memberTagInfo: [],
      isUsed: false,
      usedIds: [],
      answers: [],
      isExport: false
    }
  },
  computed: {
    isEdit() {
      return !this.item.save
    },
    hasImage() {
      return (
        [...(this.item.examResourceBOList || [])].filter(
          v => v.resourceType === 1
        ).length > 0
      )
    },
    hasVideo() {
      return (
        [...(this.item.examResourceBOList || [])].filter(
          v => v.resourceType === 2
        ).length > 0
      )
    },
    videoUrl() {
      const ot =
        [...(this.item.examResourceBOList || [])]
          .filter(v => v.resourceType === 2)
          .map(k => k.resource)[0] || {}
      return ot.url || ''
    },
    videoId() {
      const ot =
        [...(this.item.examResourceBOList || [])]
          .filter(v => v.resourceType === 2)
          .map(k => k.resource)[0] || {}
      return ot.videoId || ''
    },
    imageList() {
      return [...(this.item.examResourceBOList || [])]
        .filter(v => v.resourceType === 1)
        .map(k => k.resource.middle)
    },
    disabledIds() {
      if (!this.isEdit) {
        const arr = []
        this.memberTagInfo?.map(v => {
          arr.push(v.tagId)
          v.knowledgePointInfos?.map(k => {
            arr.push(k.knowledgePointId)
          })
        })
        return arr
      } else {
        return this.isExport ? [] : this.usedIds
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.title = this.item.title || ''
      this.examExpand = this.item.examExpand || ''
      this.analysis = this.item.analysis || ''
      this.examAnswerSettingBO = this.item.examAnswerSettingVO || {
        isInOrder: false,
        isIgnoreCase: true
      }
      this.isNoOrder = !this.examAnswerSettingBO.isInOrder
      this.categoryId = this.item.category
        ? this.item.category.categoryId
        : null
      this.memberTagInfo = this.item.tagInfos || []
      this.usedIds = this.item.tagInfos?.map(v => v.tagId)
      this.isUsed = !this.item.isRealCanDel
      this.isSetCorrectAnswer = this.item.isSetCorrectAnswer || false
      this.isPreSet = this.item.isSetCorrectAnswer
      this.examRichTextContent = this.item.examRichTextContent || ''
      this.showRichText = !!this.item.examRichTextContent
      this.isExport = this.item.isExport || false
      if (this.item.answers.length) {
        this.item.answers.map(v => {
          this.keyWord.push({
            value: v.title.split(','),
            isEditor: false,
            answerId: v.answerId
          })
        })
      } else {
        this.keyWord = [{ value: [], isEditor: false }]
      }
    },
    onSubmitTag(selectedList) {
      this.knowledgeDialog = false
      this.memberTagInfo = [...selectedList]
    },
    onTagChange(selectedList) {
      this.memberTagInfo = [...selectedList]
    },
    handleClose(v, tag) {
      v.value.splice(v.value.indexOf(tag), 1)
    },
    showInput(v, index) {
      v.isEditor = true
      // eslint-disable-next-line no-unused-vars
      this.$nextTick(_ => {
        const ind = `saveTagInput${index}`
        console.log('this.$refs[ind]', this.$refs[ind])
        this.$refs[ind][0].$refs.input.focus()
      })
    },
    handleInputConfirm(v) {
      const inputValue = this.inputValue
      if (inputValue) {
        v.value.push(inputValue)
      }
      v.isEditor = false
      this.inputValue = ''
    },
    onAddAnswer(currentIndex) {
      if (this.keyWord.length > 9 || (this.isUsed && this.isPreSet)) return
      this.keyWord.splice(currentIndex + 1, 0, { value: [], isEditor: false })
    },
    onDelAnswer(currentIndex) {
      if (this.keyWord.length < 2 || (this.isUsed && this.isPreSet)) return
      this.keyWord = [...this.keyWord].filter((v, i) => i !== currentIndex)
    },
    onOpenPreview() {
      this.$emit('onVideoPreview', this.videoUrl, this.videoId)
    },
    onOpenImagePreview(item) {
      console.log(item, '查看图片')
    },
    onAddExam(type) {
      this.showPop = false
      this.$emit('onAddExam', type)
    },
    onJump() {
      this.$emit('onJump')
    },
    onMouseOver() {
      this.hasHover = true
    },
    onMouseOut() {
      this.hasHover = false
    },
    onShow() {
      this.showPop = true
    },
    onHide() {
      this.showPop = false
    },
    showMessage(msg) {
      this.$message.error(msg)
    },
    chooseKnowledge() {
      this.knowledgeDialog = true
    },
    onBatchBind(value) {
      this.knowledgeId = value.id
      this.knowledgePointName = value.fullName
    },
    onBlur() {
      if (!this.title) {
        return
      }
      this.$emit('onTigger', { ...this.item, title: this.title })
    },
    onSave() {
      this.answers = []
      this.keyWord.map(v => {
        if (v.value.length) {
          this.answers.push({ title: v.value.join(','), answerId: v.answerId })
        }
      })
      if (!this.title) {
        this.showMessage('请输入问题题目')
        return
      }
      if (
        this.examExpand ||
        this.examAnswerSettingBO.keywordCount ||
        this.answers.length
      ) {
        if (!this.examExpand) {
          this.showMessage('请输入问题正确答案')
          return
        }
        if (!this.examAnswerSettingBO.keywordCount) {
          this.showMessage('请完善答题设置')
          return
        }
        if (!this.answers.length) {
          this.showMessage('请设置关键词')
          return
        }
        if (this.examAnswerSettingBO.keywordCount > this.answers.length) {
          this.showMessage('关键词个数设置有误')
          return
        }
        this.isSetCorrectAnswer = true
      }
      if (this.memberTagInfo.length === 0) {
        this.showMessage('请选择标签/关键信息')
        return false
      }
      this.examRichTextContent = this.showRichText
        ? this.$refs.editor.getValue()
        : ''
      this.examAnswerSettingBO.isInOrder = !this.isNoOrder
      this.$emit('onSave', {
        ...this.item,
        title: this.title,
        examExpand: this.examExpand,
        examAnswerSettingBO: this.examAnswerSettingBO,
        suitableType: this.suitableType,
        answers: this.answers,
        analysis: this.analysis,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        examRichTextContent: this.showRichText
          ? this.$refs.editor.getValue()
          : '',
        isSetCorrectAnswer: this.isSetCorrectAnswer,
        save: true
      })
    },
    onDel() {
      if (this.item.hasSet) {
        this.$emit(
          'onValid',
          res => {
            if (res) {
              this.$emit('onDel')
            }
          },
          this.item.examId
        )
        return
      }
      this.$emit('onDel')
    },
    onEdit() {
      if (this.item.hasSet) {
        this.$emit(
          'onValid',
          () => {
            this.$emit('onSave', {
              ...this.item,
              title: this.title,
              examExpand: this.examExpand,
              examAnswerSettingBO: this.examAnswerSettingBO,
              suitableType: this.suitableType,
              answers: this.answers,
              analysis: this.analysis,
              memberTagInfo: this.memberTagInfo,
              categoryId: this.categoryId,
              examRichTextContent: this.showRichText
                ? this.$refs.editor.getValue()
                : '',
              isSetCorrectAnswer: this.isSetCorrectAnswer,
              save: false
            })
          },
          this.item.examId
        )
        return
      }
      this.$emit('onSave', {
        ...this.item,
        title: this.title,
        examExpand: this.examExpand,
        examAnswerSettingBO: this.examAnswerSettingBO,
        suitableType: this.suitableType,
        answers: this.keyWord,
        analysis: this.analysis,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        examRichTextContent: this.showRichText
          ? this.$refs.editor.getValue()
          : '',
        isSetCorrectAnswer: this.isSetCorrectAnswer,
        save: false
      })
      this.$refs.editor.onSetContent()
    },
    onMoveUp() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveUp')
        })
        return
      }
      this.$emit('onMoveUp')
    },
    onMoveDown() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveDown')
        })
        return
      }
      this.$emit('onMoveDown')
    },
    onChangeRichText() {
      this.examRichTextContent = this.$refs.editor.clearValue()
      this.showRichText = !this.showRichText
    }
  }
}
</script>
<style lang="scss" scoped>
.exam-wrapper ::v-deep {
  position: relative;
  padding-top: 20px;
  padding-bottom: 75px;
  .input-sze {
    padding-bottom: 6px;
    .el-input__inner {
      height: 50px;
    }
  }
}
.exam-fill-wrapper {
  margin-bottom: 10px;
  height: 50px;
  width: 100%;
  border: 1px solid #e9eaeb;
  background: #fff;
}
.tips-w {
  padding-top: 5px;
  color: #bbb;
}
.opt-wrapper {
  position: absolute;
  height: 40px;
  bottom: 20px;
  left: 0;
  right: 0;
}
.alert-message {
  z-index: 1000;
  margin-bottom: 10px;
}
.active-btn {
  color: rgba(25, 140, 255, 1);
  text-decoration: underline;
  position: relative;
  .image-preview {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
.add-rich-text {
  text-decoration: underline;
  margin-bottom: 5px;
  &.active {
    color: #e8312f;
    margin-top: 5px;
  }
}
.editor {
  width: 100%;
  line-height: initial;
}
.little-title {
  color: #5a5a5a;
  width: 110px;
  text-align: right;
  flex: none;
}
.button-default ::v-deep {
  margin-top: 10px;
  margin-bottom: 5px;
  .el-button--default {
    border: 1px solid #dcdfe6;
    color: #606266;
  }
}
.choose-again {
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
  color: #3d61e3;
}
.rich-content {
  word-break: break-all;
  ::v-deep {
    img {
      width: 300px;
    }
  }
}
.exam-answer-wrapper,
.input-sze {
  padding-bottom: 6px;
  padding-top: 6px;
  position: relative;
  .answer-opt-wrapper {
    margin-left: 5px;
  }
  .opt-label {
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    color: $color-primary;
  }
}
.exam-answer-wrapper ::v-deep .disable {
  color: #c0c4cc;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  height: 24px;
  line-height: 24px;
  margin-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  color: #5a5a5a;
  border-color: #e1e1e1;
  background: #fbfbfb;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.word-border {
  border: 1px solid #e1e1e1;
  padding: 5px 15px;
  width: 100%;
}
.setting {
  font-size: 12px;
  color: #5a5a5a;
  margin-right: 15px;
}
.tips {
  border-top: 1px solid #e1e1e1;
  margin-top: 10px;
  padding-top: 10px;
  font-size: 12px;
  color: #5a5a5a;
}
.el-checkbox ::v-deep .el-checkbox__label {
  font-size: 12px;
  color: #5a5a5a;
}
</style>
